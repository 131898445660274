/* eslint-disable */
import moment from 'moment'
import React from 'react'
import { useTranslation } from "react-i18next";
export default function NotifyCard({ btn, border, name, onclick, data }) {
    const { t } = useTranslation();
    return (
        <section style={{ color: '#434943' }} className={`notify-card ${border ? "b-grey" : "b-grey"} p-2 py-3 my-2 row gx-0 mx-0 fs-14 ms-500`} onClick={() => onclick()}>
            <div className="col-lg-1 col-md-2 col-4 all-center mb-2">
                <button className={`btn ${btn == "New" ? "btn-new-notify" : btn == "Read" ? "btn-read" : btn == "Unread" ? "btn-unread" : ""} w-100 fs-16 py-1 ms-700 tx-lt-black`}>{t(`notification.${name}`)}</button>
            </div>
            <div className="col-lg-2 col-md-10 col-8 px-2 mb-2">
                <p className="ms-900 lh-15 text-capitalize">{data && data.type}</p>
                <p className="ms-500 lh-15">{t('notification.notification')}</p>
            </div>
            <div className="col-lg-5 col-md-6 col-8 mb-2">
                <p className="f-fred lh-15">{
                    data && data.description.toString().length > 30 ?
                        <span>{data && data.description.toString().substr(0, 30)}...</span> :
                        data && data.description.toString()
                }</p>
                <p className="lh-15">{t('notification.subject')}</p>
            </div>
            <div className="col-lg-2 col-md-2 col-4 mb-2 pr-2 d-flex flex-column align-items-end">
                <p className="ms-900 lh-15">{moment(data && data.created_at).format('DD MMM YYYY')}</p>
                <p className="lh-15">{t('notification.date')}</p>
            </div>
            <div className="col-lg-1 col-md-2 col-6 mb-2 d-lg-flex d-md-flex d-none flex-column align-items-lg-end pr-2">
                <p className="ms-900 lh-15">{moment(data && data.created_at).format('hh : mm A')}</p>
                <p className=" lh-15">{t('notification.time')}</p>
            </div>
            <div className="col-lg-1 col-md-2 d-lg-flex d-md-flex d-none  col-6 px-1 justify-content-center align-items-center">
                <button className="btn btn-detail w-100 py-1 ms-900  fs-14" onClick={() => onclick()}>{t('notification.details')}</button>
            </div>

        </section>
    )
}
