import React from 'react'
import { Link} from 'react-router-dom'
export default function PageNotFound() {
    return (
        <div className="all-center flex-column" style={{ height: '100vh' }}>
            <div className="border-radius-2 ">
                <h4 className='tx-lt-grey my-3 f-fred text-center'>PAGE NOT FOUND</h4>
                <button className="btn btn-login mx-auto d-block  f-fred upper"><Link to="/">Go back to SAYDHAD</Link></button>
            </div>
        </div>
    )
}
