/* eslint-disable */
import React, { useState, useEffect } from 'react'
import user from '../controller/user'
import helper from '../helper/index'
import Attendance from './Attendance'
import certificate from '../static/images/certi.png'
import jsPDF from 'jspdf';
import sign from '../static/images/sign.png'
import logo from '../static/images/parrot.png'
import border from '../static/images/border.png'
import moment from 'moment'
import OpenCertificate from '../component/OpenCertificate'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

export default function MyCourses() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const [tab, setTab] = useState(1)
    const [state, setState] = useState({
        data: [],
        pageload: true,
        openDetails: false,
        id: 0,
        openCert  :false
    })

    const [progress, setProgress] = useState({
        todayStreak: 0,
        totalTutorsMeet: 0,
        totalEnrollCourses: 0,
        total_minutes_consumed: 0
    })

    useEffect(() => {
        getMyProgress()
        return () => { }
    }, [])


    const getMyProgress = async () => {
        await user.getProgressAnalytics(helper.getid()).then((res) => {
            if (res.data.status) {
                setProgress({ ...progress, ...res.data.result })
        
            }
          
        })
    }


    const print = (name, hours, date) => {

        const doc = new jsPDF("landscape");
     
        let pageWidth = doc.internal.pageSize.getWidth();



        doc.setDrawColor(0);
        doc.setFillColor(255, 165, 0);
        doc.rect(0, 0, 300, 215, "F");

        //border color orange
        doc.setDrawColor(0);
        doc.setFillColor(245, 222, 179);
        doc.rect(3, 3, 290, 205, "F");

        //border image
        doc.addImage(border, "PNG", 5, 5, 285, 203);
        doc.setFont(undefined, 'bold');

        doc.setFontSize(42);
        doc.text("SAY DHAD", pageWidth / 2, 32, 'center');

        doc.setFontSize(28);
        doc.setTextColor(90, 88, 88)
        doc.text("CERTIFICATE", pageWidth / 2, 45, 'center');
        doc.setFont(undefined, 'normal')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(16);
        doc.text(`We certify that ${name} has successfully completed ${hours} minutes of conversation`, pageWidth / 2, 60, "center");
        doc.text("based arabic classes with native-speaking tutors on SayDhad", pageWidth / 2, 68, "center");

        doc.text(
            "SayDhad is an online platform based in Dubai, UAE that connects native Arabic speaking tutors \n " +
            "with students from more than 130 countries for live-conversation based tutoring sessions..", pageWidth / 2, 80, "center");
        doc.setFontSize(18);
        doc.text(
            `Dubai, UAE - ${date}`, pageWidth / 2, 110, "center");
        //Add sign
        doc.addImage(sign, "PNG", 120, 125, 40, 20);
        //Add parrot logo
        doc.addImage(logo, "PNG", 190, 120, 20, 25);


        doc.text("Mr Ali | President", pageWidth / 2, 160, "center");
        doc.setFontSize(14);
        doc.text("As Sahafah, Olaya St. 6531, 3059 Riyadh 13321", pageWidth / 2, 170, "center");
        doc.text("Saudi Arabia", pageWidth / 2, 178, "center");
        doc.text("www.saydhad.com", pageWidth / 2, 188, "center");

        doc.setFontSize(14);
        // doc.text(`Verification URL: www.dhadsay.com/certificate/verify/${helper.getid()}`, pageWidth / 2, 200, "center");

        //Save certificate
        doc.save("certificate.pdf");


    }

    return (
        <>
            <div className="my-progress">
                <section className='my-progress-body row py-4 my-3'>
                    <div className="col-lg-3 col-md-3 col-6 mb-2" style={{ borderRight: '2px solid #434943' }}>
                        <div className='all-center flex-column'>
                            <h4 className='fs-40 ms-500'>{progress.todayStreak}</h4>
                            <p className='ms-500'>{t('general.attendance.todayStreak')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6 mb-2">
                        <div className='all-center flex-column'>
                            <h4 className='fs-40 ms-500'>{progress.totalTutorsMeet}</h4>
                            <p className='ms-500'>{t('general.attendance.tutorMet')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6 mb-2">
                        <div className='all-center flex-column'>
                            <h4 className='fs-40 ms-500'>{progress.totalEnrollCourses}</h4>
                            <p className='ms-500'>{t('general.attendance.totalCourses')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6 mb-2">
                        <div className='all-center flex-column'>
                            <h4 className='fs-40 ms-500'>{progress.total_minutes_consumed}</h4>
                            <p className='ms-500'>{t('general.attendance.minConsume')}</p>
                        </div>
                    </div>
                </section >

                <section className='row my-3'>
                    <div className="col-lg-3 col-md-4 col-12  ms-700 my-progress-body h-100 mb-2">
                        <div className='progress-left p-3'>
                          
                            <button className={`d-block btn btn-cert ${tab === 1 && "btn-cert-out"} text-upper my-2 w-100 py-2`} onClick={() => setTab(1)}>{t('general.attendance.attendance')} <i className="fas fa-mitten ml-2"></i></button>
                            <button className={`d-block btn btn-cert ${tab === 0 && "btn-cert-out"} my-2 w-100 py-2`} onClick={() => setTab(0)}>{t('general.attendance.certificate')} <i className="fas fa-certificate ml-2"></i></button>

                        </div>
                    </div>
                    <div className={`col-lg-9 col-md-8 col-12 ${reduxState.language === 'ar' && "text-right"}`}>
                        {tab == 0 ?
                            <div className='my-progress-body mx-1 px-3 py-3'>
                                <h4 className='f-fred fs-24 mt-2 mb-3'>Certificate</h4>
                                <div className='ms-500'>
                                    <p className='text-justify'>
                                        This certificate is the recognition of his/her work and dedication in completing the SayDhad Course. It helps in individual personality development and unlocks many opportunities for those who are interested to work with the native Arabic Language.
                                        SayDhad Certification Courses also help an individual to showcase his competency, commitment for the profession, build expertise in his professional subject area and help with job advancement.
                                    </p>
                                    <div className='my-4'>
                                       <img style={{maxWidth:'100%'}} width="400px" className='br-1 pointer b-grey d-block mx-auto' onClick={()=>{setState({...state,openCert  :true})}} src={certificate} alt="certificate" />
                                    </div>
                                    {
                                      moment.duration(progress.total_minutes_consumed, "HH:mm:ss").asMinutes().toFixed()  >= 60 &&
                                        <section className='my-2'>
                                            <p className='ms-500 text-center'>Download your certificate</p>
                                            <div className='all-center'>
                                                <button className='btn bg-sky my-2  text-white py-1 mr-2 px-2 fs-14 ms-700' onClick={() => { print(helper.getName(), progress.total_minutes_consumed, moment().format('MMM DD, YYYY')) }}>DOWNLOAD</button>
                                            </div>
                                        </section>
                                    }
                                </div>
                            </div>
                            :
                            tab == 1 ?
                                <Attendance /> :
                                ''
                        }
                    </div>
                </section>


            </div>
            {state.openCert && <OpenCertificate certificate={certificate} close={()=>setState({...state,openCert  :false})} /> }
            {/* {state.pageload && <Backdrop />} */}

        </>
    )
}
