/* eslint-disable */
import React, { useEffect } from 'react'
import helper from '../helper'
import WebHeader from './WebHeader'
import block from '../static/images/home/Path 4437.svg'
import Google from '../static/images/home/Google.svg'
import Apple from '../static/images/home/Apple.svg'
import Girl1Img from '../static/images/home/Mask Group 48.png'
import Shape from '../static/images/home/Shape.svg'
import WebFooter from './WebFooter'
import banner from "../static/images/banner.png";
import classImg from "../static/images/home/class.png";
import { Link } from 'react-router-dom'
import TutorSubscribe from './TutorSubscribe'
import Mask from '../static/images/home/Mask.png'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { constant } from '../config/Contant'

export default function TutorKnowledge() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    useEffect(() => {
        helper.scroll()
    }, [])
    return (
        <>
            {/* For SEO */}
            <Helmet>
                <title>Tutor Knowledge - Effective Arabic Tutoring Methods - SayDhad</title>
                <meta name="description" content='SayDhad makes learning Arabic easier. The tutors follow effective tutoring methods to engage with the language actively. We make learning fun and engaging with tailored lesson plans and interactive activities.  Visit our website and register today' />
                <link rel="canonical" href=" https://saydhad.com/tutor-knowlegde/" />
            </Helmet>
            <div className={`web-tutor-knowledge ${reduxState.language === 'ar' && "text-right"}`}>
                <section className='row banner-section'>
                    <div className="col-lg-11 col-12 mx-auto">
                        <WebHeader headerFor={'Tutor'} />
                        <section className='banner-body'>
                            <div className="row">
                                <div className="col-lg-6 mb-2">
                                    <div className='banner-img'><img className='mw-100 tutor-img' style={{ borderRadius: '75px 300px 80px 80px', height: '98%' }} src={banner} alt="banner" /> </div>
                                </div>
                                <div className="col-lg-1"></div>
                                <div className="col-lg-5 mb-2 v-center ">
                                    <div className={`${reduxState.language === 'ar' && "text-right"}`}>
                                        <img src={block} alt="block" className='mt-2' />
                                        <h1 className='web-heading'>{t('tutorHome.hero.heading')}</h1>
                                        <p className='web-sub-heading' style={{ opacity: 0.7 }}>{t('tutorHome.hero.title')} <span style={{ opacity: 1 }} className="ms-700">{t('tutorHome.hero.shine')}</span> .</p>
                                        <Link to="/tutor/signup"><button className='btn find-tutor-btn mt-4'>{t('tutorHome.hero.signup')}</button></Link>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                </section>

                <section className='section-4 row'>
                    <div className='col-lg-11 mx-auto'>
                        <div className="row">
                            <div className="col-lg-5 px-3">
                                <div className='all-center'>
                                    <div className='girl-img-box'>
                                        <img src={Girl1Img} className="girl1-img px-3" alt="girl" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-6 v-center ">
                                <img src={Shape} alt="shape" className="sec4-quote-img" />
                                <p className={`web-sub-heading ${reduxState.language === 'ar' && "text-right"}`} style={{ opacity: 0.7 }}>{t('tutorHome.sec2.heading')}</p>
                                <div className='testimonial-view '>
                                    <div >
                                        <img src={Mask} alt="mask" />
                                    </div>
                                    <div className={`w-100 mx-2 ${reduxState.language === 'ar' && "text-right"}`}>
                                        <p className='testimonial-view-text'>{t('tutorHome.sec2.reviewHeading')}</p>
                                        <p className='testimonial-view-author'>{t('tutorHome.sec2.reviewName')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='web-tutor-section-3'>
                    <div className="row web-tutor-section-3-body w-100">
                        <div className='col-lg-12 mx-auto'>
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-4 v-center px-2">
                                    <div className={`${reduxState.language === 'ar' && "text-right"}`}>
                                        <p className='web-heading'>{t('tutorHome.sec3.heading1')}</p>
                                        <p className='web-heading mb-2'>{t('tutorHome.sec3.heading2')}</p>
                                        <ul >
                                            <li>{t('tutorHome.sec3.bullet1')}</li>
                                            <li>{t('tutorHome.sec3.bullet2')}</li>
                                            <li>{t('tutorHome.sec3.bullet3')}</li>
                                            <li>{t('tutorHome.sec3.bullet4')}</li>
                                            <li>{t('tutorHome.sec3.bullet5')}</li>
                                            <li>{t('tutorHome.sec3.bullet6')}</li>
                                        </ul>
                                    </div>
                                    <div className={`mt-4 justify-content-end ${reduxState.language === 'ar' && "text-right"}`}>
                                        <a rel="noopener noreferrer" href={constant.ANDROID_APP} target={"_blank"}><img src={Google} className="mx-2" alt="google" /></a>
                                        <a rel="noopener noreferrer" href={constant.IOS_APP} target={"_blank"}><img src={Apple} alt="apple" /></a>
                                    </div>
                                </div>
                                <div className="col-lg-7 pl-3">
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <div >
                                            <img className='tutor-img my-5 mw-100' style={{ borderRadius: '20px 0 0 20px' }} src={classImg} alt="class" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='section-5 row'>
                    <div className='col-lg-10 mx-auto section-5-box'>
                        <TutorSubscribe />
                    </div>
                </section>

                <section className='row footer-section '>
                    <div className="col-lg-10 col-12 mx-auto">
                        <WebFooter />
                    </div>
                </section>
            </div>
        </>
    )
}
