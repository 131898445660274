import React, { useEffect } from 'react'
import helper from '../helper'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import RefundPolicy from '../legalpages/RefundPolicy'
import { Helmet } from 'react-helmet';

export default function WebRefundPolicy() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation()
    useEffect(() => {
        helper.scroll()
    }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title>Refund Policy - SayDhad</title>
                <meta name="description" content='We at SayDhad follow a simple refund policy. If you feel you are charged mistakenly, request a refund at contact@Say-Dhad.com. Make sure to raise a refund request within 30 days of the charge. Visit our refund policy page to learn more about our refund policy.' />
                <link rel="canonical" href=" https://saydhad.com/web/refund-policy/" />
            </Helmet>
            <div>
                <section className='row banner-section '>
                    <div className="col-lg-11 col-12 mx-auto">
                        <WebHeader />
                        <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
                            <div className="row ms-500">
                                <div className="col-lg-10 col-12 mx-auto  py-2">
                                    <h2 className='mb-3 tx-lt-grey f-fred'>{t('legalPages.refundPolicy.heading')}</h2>
                                </div>
                            </div>
                            <RefundPolicy />
                        </section>
                        <WebFooter />
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}

