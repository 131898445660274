/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Loader from '../component/Loader';
import user from '../controller/user';
import helper from '../helper/index';
import Backdrop from '../component/Backdrop';
import { useHistory } from 'react-router';
import avatar from '../static/images/avatar.png'
import ViewClassInfo from '../usercalendar/ViewClassInfo';
import FindTutorByCalendar from '../usercalendar/FindTutorByCalendar';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
function UserCalendar() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [loader, setLoader] = useState(false)
    const [resData, setResData] = useState([])
    const [todayClass, setTodayClass] = useState([])
    const [selectDate, setSelectDate] = useState()
    const lastDate =  helper.getPlanDetails()
    const [state, setState] = useState({
        load: true,
        data: [],
        openBookingInfo: false,
        bookingData: null,
        findTutor: false,
        date: null
    })

    useEffect(() => {
        // getBookedSlot(moment().format('YYYY-MM-DD'))
        getMonthlyClasses(moment(new Date()).format('YYYY-MM-DD'))
        setSelectDate(moment().format('DD MMMM YYYY'))
        return () => { }
    }, [])

    const onChangeCalendar = (day) => {
        let list = []
        let find = false
        state.data.map((data) => {
            if (data.date == moment(day).format('YYYY-MM-DD')) {
                list.push(data)
                find = true
            }
        })
        if (find) {
            setState({ ...state, data: state.data, openBookingInfo: true, bookingData: list })
            return
        }
        if (helper.getPlanDetails().isPurchase) {
            setState({ ...state, data: state.data, findTutor: true, date: day })
        }
        else {
            history.push('/user/dashboard/subscription')
        }

    }

    const getBookedSlot = async (date) => {
        setPageload(true)
        await user.getBookedClasses(helper.getid(), date)
            .then((res) => {
                if (res.data.status) {

                    // let WeekClasslist = [], todayClassList = [];
                    //  res.data.result.map((data) => {
                    //     if (moment(data.date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                    //         WeekClasslist.push(data)
                    //     } else {
                    //         todayClassList.push(data)
                    //     }
                    // })
                    // console.log("all classes ---> ", res.data.result)
                    // setResData(WeekClasslist)
                    // setTodayClass(todayClassList)
                }
                setLoader(false)
                setPageload(false)

            })
    }

    const getMonthlyClasses = async (date) => {
        await user.getMonthlyBookedClasses(helper.getid(), date)
            .then((res) => {
                if (res.data.status) {
                    try {
                        let WeekClasslist = [], todayClassList = [];
                        res.data.result.map((data) => {
                            if (moment(data.date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                                if (moment(data.date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
                                    WeekClasslist.push(data)
                                }
                            } else {
                                todayClassList.push(data)
                            }
                        })
                        
                        setResData(WeekClasslist)
                        setTodayClass(todayClassList)
                        setLoader(false)
                        setPageload(false)
                    } catch (err) {
                        setLoader(false)
                        setPageload(false)
                    }

                    setState({ ...state, data: res.data.result, load: false, openBookingInfo: false })
                }


            })
    }

    return (
        <>
            <div className="tutor-calendar row gx-0 mx-0">
                <div className="col-lg-10  mx-auto cpx-4">
                    <div className="calendar-body b-grey pt-3">

                        <div className={`${reduxState.language === 'ar' && "text-right pr-3"} `}>
                            <p className={`f-fred pl-3 fs-24`}>{t('general.calendar.scheduleReservation')}</p>
                            <p className="tx-sky fs-20 ms-900 my-2 pl-3 "><i className="fas fa-calendar-alt fs-26"></i> {selectDate}</p>
                        </div>
                        <Calendar
                            onChange={(day) => { onChangeCalendar(day) }}
                            tileClassName="tilename"
                            className="w-100 cl-body br-1 mt-3 ms-700 selected"
                            navigationAriaLabel="go up"
                            onClickMonth={(date) => { getMonthlyClasses(moment(date).format('YYYY-MM-DD')) }}
                            maxDate={new Date( lastDate ? lastDate.data.renewOn : moment().endOf('month'))}
                            // maxDate={new Date(new Date().setDate(new Date().getDate() + 30))}
                            maxDetail='month'
                            tileDisabled={({ date }) => {
                                let minDate = moment(new Date()).format('YYYY-MM-DD')
                                let formatedDate = moment(date).format('YYYY-MM-DD')
                                // if (formatedDate < minDate) {
                                //     return true
                                // }
                                // else {
                                //     return false
                                // }


                            }}
                            tileContent={({ date }) => {
                                let find = false
                                state.data.map((data) => {
                                    if (data.date == moment(date).format('YYYY-MM-DD')) {
                                        find = true
                                    }
                                })
                                if (find) {
                                    return <div className='all-center mt-2 p-rel' >
                                        <p className='p-rel' style={{ borderBottom: '3px solid green', borderRadius: '10px', width: '20px', opacity: '0.8' }}>
                                            <i className='fas fa-lock  fs-12 p-abs tx-green' style={{ bottom: '0px', right: '-10px' }}></i>
                                        </p>
                                    </div>
                                }
                                // let maxDate = moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD')
                                let maxDate = moment(new Date(moment().endOf('month'))).format('YYYY-MM-DD')
                                let minDate = moment(new Date()).format('YYYY-MM-DD')
                                let formatedDate = moment(date).format('YYYY-MM-DD')
                                if (formatedDate >= minDate && formatedDate <= maxDate) {
                                    return <div className='all-center mt-2 p-rel' >
                                        <p className='p-rel' style={{ borderBottom: '3px solid rgb(90, 88, 88)', borderRadius: '10px', width: '20px', opacity: '0.3' }}>
                                            <i className='fas fa-plus  fs-12 p-abs' style={{ bottom: '0px', right: '-10px', color: 'rgb(90, 88, 88)' }}></i>
                                        </p>
                                    </div>
                                }
                            }}
                        />

                    </div>

                    <section className={`my-3 `}>
                        <div className="b-grey h-100">
                            <div className='row gx-0 mx-0'>
                                <div className={`col-lg-6 col-12 px-1 ${reduxState.language === 'ar' && "text-right pr-3"}`}>
                                    <h4 className="f-fred mt-3 mb-2 ml-2 cpx-4">{t('general.calendar.upcomingClasses')}</h4>
                                    <div className="row gx-0 mx-0">
                                        {
                                            resData && resData.map((result, index) => {

                                                return (
                                                    <div key={`weekClass${index}`} className="col-12 cpx-4">
                                                        <section className="mx-1 b-grey px-2 py-2 ms-500 my-2 pointer">
                                                            <div className="row gx-0 mx-0 ">
                                                                <div className="col-lg-8"><p className="fs-12 ms-700 lh-15">{t('tutorDash.calendar.scheduleOn')}</p>
                                                                    <p className="ms-700 fs-18">{moment(result.date).format('DD MMM YYYY')}, {moment(result.start_time, 'HH:mm').format("hh : mm A")}</p>
                                                                </div>
                                                                <div className="col-lg-4 d-flex justify-content-end align-items-center ">

                                                                    {helper.isUserCanJoinClass({ classDate: result.date, startTime: result.start_time, endTime: result.end_time }) ?
                                                                        <span className="px-1 py-1 fs-10 returnCall-btn f-fred " onClick={() => history.push(`/user/class-room/${result.channel_name}`)}>{t('button.joinClass')}</span> :
                                                                        <span className="px-1 py-1 fs-10 returnCall-btn f-fred ">{result.status}</span>}


                                                                </div>
                                                            </div>

                                                            <div className="d-flex  mt-3" >
                                                                <div className=" d-flex align-items-center">
                                                                    <img style={{ height: '40px', width: '40px' }} className="rounded-circle mr-2" src={result.profilePicture ? result.profilePicture : avatar} alt="avatar" />
                                                                </div>
                                                                <div className="px-1 d-flex flex-column justify-content-center">
                                                                    <p className="f-fred lh-18 fs-14">{result.name}</p>
                                                                    <p className="tx-lt-black ms-700 fs-12">From <span className="text-upper">{result.from}</span></p>
                                                                </div>
                                                            </div>

                                                        </section>
                                                    </div>
                                                )


                                            })
                                        }
                                        <div className="col-12">
                                            {
                                                resData && resData.length === 0 &&
                                                <section>
                                                       <p className='text-center my-2'>{t('messages.noUpcomingClass')}</p>
                                                </section>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-lg-6 col-12 px-1 ${reduxState.language === 'ar' && "text-right pr-3"}`}>
                                    <h4 className="f-fred mt-3 mb-2 ml-2 cpx-4">{t('general.calendar.todayClasses')}</h4>
                                    <div className="row gx-0 mx-0">
                                        {
                                            todayClass && todayClass.map((result, index) => {
                                                return (
                                                    <div key={`todayClass${index}`} className="col-12 cpx-4" >
                                                        <section className="mx-1 b-grey px-2 py-2 ms-500 my-2 pointer text-white " style={{ backgroundColor: '#041D39' }}>
                                                            <div className="row gx-0 mx-0 ">
                                                                <div className="col-lg-8 "><p className="fs-12 ms-700 lh-15">{t('tutorDash.calendar.scheduleOn')}</p>
                                                                    <p className=" ms-700 fs-18">{moment(result.date).format('DD MMM YYYY')}, {moment(result.start_time, 'HH:mm').format("hh : mm A")}</p>
                                                                </div>
                                                                <div className="col-lg-4 d-flex justify-content-end align-items-center ">
                                                                    {
                                                                        helper.isUserCanJoinClass({ classDate: result.date, startTime: result.start_time, endTime: result.end_time }) ?
                                                                            <span className="px-1 py-1 fs-10 returnCall-btn f-fred " onClick={() => history.push(`/user/class-room/${result.channel_name}`)}>{t('button.joinClass')}</span>
                                                                            :
                                                                            <span className="px-1 py-1 fs-10 returnCall-btn f-fred ">{result.status}</span>

                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="d-flex  mt-3" >
                                                                <div className=" d-flex align-items-center">
                                                                    <img style={{ height: '40px', width: '40px' }} className="rounded-circle mr-2" src={result.profilePicture ? result.profilePicture : avatar} alt="avatar" />
                                                                </div>
                                                                <div className="px-1 d-flex flex-column justify-content-center">
                                                                    <p className="f-fred lh-18 fs-14">{result.name}</p>
                                                                    <p className="ms-700 fs-12">From <span className="text-upper">{result.from}</span></p>
                                                                </div>
                                                            </div>

                                                        </section>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="col-12">
                                            {
                                                todayClass && todayClass.length === 0 &&
                                                <section>
                                                      <p className='text-center my-2'>{t('messages.noTodayClass')}</p>
                                                </section>
                                            }
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </section>

                </div>



            </div >
            {
                pageload && <Backdrop />
            }

            <Loader open={loader} />
            {/* open booking inforamtion  */}
            <ViewClassInfo
                open={state.openBookingInfo}
                cb={() => { setState({ ...state, openBookingInfo: false, data: state.data }); getMonthlyClasses(moment(new Date()).format('YYYY-MM-DD')) }}
                onClose={() => { setState({ ...state, openBookingInfo: false, data: state.data }) }}
                data={state.bookingData}
            />
            {/* open popup for finding tutor  */}
            <FindTutorByCalendar
                open={state.findTutor}
                onClose={() => { setState({ ...state, findTutor: false, data: state.data }) }}
                date={state.date}
            />
        </>
    )
}

export default UserCalendar
