import React from 'react'
import { useTranslation } from "react-i18next";

export default function CancelPolicy() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="ms-1000">
                <div className="row ms-500">
                    <div className="col-lg-10 col-12 mx-auto  py-2">
                        <p>{t('legalPages.cancelPolicy.para1')}</p>
                        <p>{t('legalPages.cancelPolicy.para2')}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
