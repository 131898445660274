/* eslint-disable */
import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast'
import ChatNotification from "../userDash/ChatNotification";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import AppState from '../redux/AppState';
import i18n from '../i18n';
import LanguageTranslator from './LanguageTranslator';
import { useLocation } from 'react-router-dom';

let lng = "";
try {
  lng = localStorage.getItem("lang")
} catch (err) {
}

export default function GlobalComponent() {
  const reduxState = useSelector(state => state)
  const location = useLocation()

  useEffect(() => {
    if (lng) {
      AppState.setLng(lng);
      i18n.changeLanguage(lng)
    } else {
      localStorage.setItem("lang", "en")
      AppState.setLng(reduxState.language);
      i18n.changeLanguage(reduxState.language)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      sayDhadTextReplace()
    }, 2000)
  }, [reduxState.language, location,reduxState.loadWindow])


  const sayDhadTextReplace = () => {
    try {
      let pTag = document.getElementsByTagName("p")
      let enReg = /(SayDhad|Say-Dhad|SAYDHAD|Saydhad)/i
      let arReg = /(قُلْ ضَادْ|قل ضاد|ضَاد-قٌلْ|قُلْ ضَا)/

      switch (reduxState.language) {
        case "ar":
          for (let i = 0; i < pTag.length; i++) {
            pTag[i].innerHTML = pTag[i].innerHTML.replace(arReg, "<span class='tx-green'> قُلْ </span> <span class='tx-yellow'> ضَادْ </span>");
          }
          if (reduxState.language === 'ar') {
            // for (let i = 0; i < pTag.length; i++) {
            //   pTag[i].innerHTML = pTag[i].innerHTML.replace(enReg, "<span class='tx-green'> قُلْ madan mohan</span> <span class='tx-yellow'> ضَادْ </span>");
            // }
          }
          break;
        default:
          for (let i = 0; i < pTag.length; i++) {
            pTag[i].innerHTML = pTag[i].innerHTML.replace(enReg, "<span class='tx-green'>Say</span><span class='tx-yellow'>Dhad</span>");
          }
          break;
      }
    } catch (err) { }
  }

  return (
    <>
      {/* Global toaster */}
      <Toaster
        position="top-left"
        reverseOrder={false}
        containerClassName="ms-700"
        toastOptions={{
          className: 'toast px-2 py-2',
        }}
      />

      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ChatNotification />
      <LanguageTranslator className="d-none" />
      <Helmet htmlAttributes={{
        lang: reduxState.language,
        dir: reduxState.language === 'ar' ? 'rtl' : 'ltr'
      }} />
    </>
  )
}
