/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL

const getVideoToken = async (channelName, role, uid, userRole) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/access-token?channel_name=${channelName}&uid=${uid}&role=${role}&user_role=${userRole}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getScreenShareToken = async (channelName, role, uid, userRole) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/screen-share-access-token?channel_name=${channelName}&uid=${uid}&role=${role}&user_role=${userRole}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getRTMToken = async (uid) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/rtm-token?uid=${uid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const getInfoBeforeClass = async (sid, channelName) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/join-class-info?student_id=${sid}&channel_name=${channelName}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const joinClass = async (cid, sid, channelName) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/student/join-class`,
            data: {
                course_id: cid,
                student_id: sid,
                channel_name: channelName
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const getStudentInfo = async (channelName) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/student-profile-details-during-class?channel_name=${channelName}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const getStudentEnrolledCourses = async (channelName) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/enrolled-course-list-by-channel-name?channel_name=${channelName}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const enrollStudentInCourse = async (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/student/enroll-courses`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const markSlideAsStudy = async (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/student-syllabus-slide-complete`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }

}

const markStudentCourseSyllabus = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/student-learn-course-syllabus`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const classEndAtBothEnd = ({ channelName, role, reason }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/web-socket/user/class-end-notification?channel_name=${channelName}&role=${role}&reason=${reason}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getIntroCourseList = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course/intro-course-list`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const markIntroCourseAsStudy = async (channelName) => {
    await getIntroCourseList(helper.getid())
        .then(async (res) => {
            if (res.data.status) {
                let list = []
                res.data.result.forEach((data) => {
                    data.course.forEach((element) => {
                        list.push(element)
                    })
                })
                if (list.length > 0 && list[0].syllabusList.length > 0) {
                    await markStudentCourseSyllabus({
                        channel_name: channelName,
                        tutor_id: helper.getid(),
                        course_id: list[0].id,
                        syllabus_id: list[0].syllabusList[0].syllabus_id
                    }).then((res) => {}).catch((err) => {  })
                }
            }
        }).catch(() => {})
}

export default {
    getVideoToken,
    getScreenShareToken,
    getRTMToken,
    getInfoBeforeClass,
    joinClass,
    getStudentInfo,
    getStudentEnrolledCourses,
    enrollStudentInCourse,
    markSlideAsStudy,
    markStudentCourseSyllabus,
    classEndAtBothEnd,
    getIntroCourseList,
    markIntroCourseAsStudy
}