import React from 'react';
import happy from '../static/images/logo.png'
import parrot from '../static/images/parrot.png'
import store from '../redux/AppState'
import { useSelector } from 'react-redux';
import Zoom from 'react-reveal/Zoom';
import audio from '../static/notification.mp3'
import useSound from 'use-sound';
import ReactStars from "react-rating-stars-component";
import avatar from '../static/images/avatar.png'
import moment from 'moment';
import { useTranslation } from "react-i18next";
export default function AlertClassPop() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const [playActive] = useSound(
        audio,
        { volume: 0.50 }
    );
   
    const getSound = () => {
        playActive()
    }

    const getMinutes = ()=>{
        const durTime1 = moment(new Date(), "hh:mm").format("HH:mm");
        const durTime2 = moment(storeData.alertClassPopup.data.start_time,'hh:mm').format("HH:mm");
        const start = moment.duration(durTime1, "HH:mm");
        const end = moment.duration(durTime2, "HH:mm");
        const diff = end.subtract(start);
        const hrs = diff.minutes();
        return hrs
    }
    return (
        <>
            {storeData.alertClassPopup.open &&
                <div className="popup-modal h-100 all-center">
                    <Zoom center>
                        <div className='p-rel'>
                            <div className="popup-modal-body w-100 sm" onLoad={() => getSound()}>
                                <div className='my-3'>
                                    <img className='d-block mx-auto' style={{ width: '200px' }} src={happy} alt="parrot" />
                                </div>
                                <p className='f-fred fs-24 text-center'>You have a call in</p>
                                <p className='ms-700 fs-20 text-center'>{getMinutes()} Min</p>

                                <section className='px-4'>
                                    <div className={`d-flex tutor-big-card b-grey bg-white mb-2 px-2 py-2 mt-2 p-sticky tp-0`} style={{ zIndex: '3' }}>
                                        <div className="all-center my-2">
                                            <div className='p-rel mr-2'>
                                                <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={storeData.alertClassPopup.data.profilePicture ? storeData.alertClassPopup.data.profilePicture : avatar} alt="avatar" />
                                            </div>
                                        </div>
                                        <div className='w-100 d-flex justify-content-between'>
                                            <div className="my-2 pl-1 d-flex mr-5 flex-column justify-content-center">
                                                <p className="text-capitalize fs-16 f-fred">{storeData.alertClassPopup.data.name}</p>
                                                {storeData.alertClassPopup.type !== "tutor" ?
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-inline-block py-0 my-0 mr-1">
                                                            <ReactStars
                                                                edit={false}
                                                                value={parseFloat(storeData?.alertClassPopup?.data?.ratings).toFixed(1)}
                                                                count={5}
                                                                size={15}
                                                                isHalf={true}
                                                                emptyIcon={<i className="far fa-star"></i>}
                                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                fullIcon={<i className="fa fa-star"></i>}
                                                                classNames="my-0 py-0"
                                                                activeColor="#ffcb00"
                                                               
                                                            />
                                                        </div>
                                                        <span className="tx-black ms-700 fs-14">{parseFloat(storeData?.alertClassPopup?.data?.ratings).toFixed(1)}</span>
                                                    </div>
                                                    :
                                                    <p className='ms-500 fs-14'>{storeData.alertClassPopup.data.country}</p>
                                                }
                                            </div>

                                            <div className="d-flex flex-column align-items-end justify-content-center p-rel">
                                                <p className='ms-500 fs-14'>Schedule at</p>
                                                <p className='ms-900 fs-18'>Today, {moment(storeData.alertClassPopup.data.start_time,'hh:mm').format('hh : mm A')}</p>
                                            </div>
                                        </div>
                                    </div>

                                </section>

                                <p className='ms-500 fs-14 text-center px-3'>If your have any issues logging in before the call</p>
                                <p className='ms-500 fs-14 text-center px-3'> Please contact our <span className='ms-700 tx-green'>tutor support</span></p>
                                <section className='d-flex my-2'>
                                    <button className='btn btn-login ms-700 mx-2 text-white py-2 fs-14 mb-3 mx-auto d-block' style={{ width: '150px' }}
                                        onClick={() => store.setAlertClassPop({ data: "Ds", open: false })}>{t('button.okay')}</button>

                                </section>

                            </div>

                            <div className='p-abs' style={{ bottom: '-120px', right: '-120px' }}>
                                <img src={parrot} width={'150px'} alt="parrot" />
                            </div>
                        </div>
                    </Zoom>

                </div >
            }
        </>
    );
}
