import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import logo from '../static/images/Logo.svg'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import quoteIcon from '../static/images/quote.svg'
import ToggleHidePassword from '../component/ToggleHidePassword'
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom'
import { verifyUserEmail } from '../controller/auth'
import { Helmet } from 'react-helmet'
export default function UserSignupReferral() {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('initial')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { referralcode } = useParams()

    const submit = async () => {
        let resp;
        resp = helper.validate("email", email)
        if (!resp.isValid) { setError(resp.msg); return; }
        resp = helper.validate("password", password)
        if (!resp.isValid) { setError(resp.msg); return; }
        if (error === '') {
            setOpen(true)
            await verifyUserEmail({ email: email ? email.toLowerCase() : email }).then((res) => {
                if (res.data.status) {
                    const decEmail = helper.encrypt(email ? email.toLowerCase() : email)
                    const decPass = helper.encrypt(password)
                    setOpen(false)
                    history.push(`/user/little-more?tk1=${decEmail}&&tk2=${decPass}&referral=${referralcode}`)
                } else {
                    setOpen(false)
                    helper.showMessage(res.data.error, "info")
                }
            })
        }
    }

    const checkError = (field, value) => {
        const res = helper.validate(field, value)
        res.isValid ? setError('') : setError(res.msg)
    }

    return (
        <div className="tutor-login-home lg-bg-image" >
            <section className="row gx-0 mx-0 h-100 py-5 cpx-4">
                <div className="col-lg-7 col-md-7 cpx-4 d-lg-flex d-md-flex d-none  align-items-start flex-column f-fred text-white">
                    <div className="overlay-bg"></div>
                    <div className="fs-61 overlay-text">
                        <h1>
                            <img src={quoteIcon} alt="quote" />
                        </h1>
                        <p className="text-white" style={{ lineHeight: '66px' }}>{t('auth.tutorQuote1')}</p>
                        <p style={{ lineHeight: '66px' }}>{t('auth.tutorQuote2')}</p>
                        <p className="fs-31 mt-3">~{t('general.quoteAuthor')}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 my-3  bg-white login-home-box px-3 py-3  all-center" >
                    <div className="row gx-0 mx-0 w-100">
                        <div className="col-lg-10 mx-auto d-flex flex-column justify-content-between">
                            <div className="upper">
                                <div className="all-center px-2">
                                    <img width="230px" src={logo} alt="logo" />
                                </div>
                                <div className="all-center flex-column">
                                    <p className="f-fred text-center fs-24 heading-y-margin mb-1 mt-3">{t('auth.create-a')} <span className="tx-green">{t('auth.learning')}</span> {t('auth.profile')}</p>
                                    <div className="mt-1 w-100">
                                        <div className="input-box mb-2">
                                            <input type="email" placeholder={t('auth.forgot.email')} className="px-2 py-1 ms-700 box-height" onChange={(e) => { setEmail(e.target.value); checkError("email", e.target.value) }} />
                                        </div>
                                        <ToggleHidePassword onChange={(e) => { setPassword(e.target.value); checkError("password", e.target.value) }} />
                                    </div>
                                    <p className="my-0 fs-12 tx-error ms-500">{error !== "initial" && error}</p>
                                    <button className="btn heading-y-margin btn-tutor-profile mt-3 px-2 box-height w-100" onClick={() => submit()}>{t('auth.proceed')}</button>
                                </div>
                            </div>
                            <div className="all-center flex-column">
                                <button className="btn btn-login  w-100 mt-5 box-height" onClick={() => history.push('/login')}>{t('auth.loginExistAccount')}</button>
                                <p className="my-2 mt-4 ms-500 text-center login-footer-text" style={{ width: '80%' }}>{t('auth.policy.text1')} <span className="tx-lt-black ms-900">{t('auth.saydhad')}</span>. {t('auth.policy.text2')} <span className="ms-900 tx-lt-black"> <NavLink to="/web/terms-condition">{t('auth.policy.terms')} </NavLink></span> {t('auth.policy.and')} <span className="ms-900 tx-lt-black"><NavLink to="/web/privacy-policy">{t('auth.policy.policy')}</NavLink></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {open && <Backdrop />}
            <Helmet htmlAttributes={{ dir: "ltr" }} />
        </div>
    )
}
