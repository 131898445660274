import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Backdrop from '../component/Backdrop'
import NotifyCard from '../component/NotifyCard'
import UserNotifyPopup from '../component/UserNotifyPopup'
import tutor from '../controller/tutor'
import helper from '../helper/index'

export default function TutorNotify() {
    const [pageload, setPageload] = useState(true)
    const [openNotification, setOpenNotification] = useState({
        open: false, data: ''
    })
    const [resData, setResData] = useState([])
    useEffect(() => {
        getNotification()
        return () => { }
    }, [])

    const getNotification = async () => {
        await tutor.getTutorNotifications(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    list = res.data.result.sort((a, b) => a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0)
                    setResData(list)
                }
                setPageload(false)
            })
    }

    const markRead = async (notifyId, status, index) => {
        if (status) {
            return
        }
        await tutor.markAsRead(helper.getid(), notifyId)
            .then((res) => {
                if (res.data.status) {
                    let temp = [...resData]
                    temp[index].read_as = true
                    setResData(temp)
                }
            })
    }

    const checkNew = (date, status) => {
        const today = new Date()
        if (moment(today).format('YYYY MM DD') === moment(date).format('YYYY MM DD') && !status) {
            return "New"
        }
        else if (status) {
            return "Read"
        }
        else {
            return "Unread"
        }
    }


    return (
        <>
            {
                !pageload ?
                    <div className="tutor-notify ">
                        {
                            resData.map((result, index) => <NotifyCard
                                key={result.id}
                                btn={checkNew(result.created_at, result.read_as) === "New" ? "New" : checkNew(result.created_at, result.read_as)}
                                border={checkNew(result.created_at, result.read_as) === "New" && "orange"}
                                name={checkNew(result.created_at, result.read_as) === "New" ? "New" : checkNew(result.created_at, result.read_as)}
                                onclick={() => { markRead(result.id, result.read_as, index); setOpenNotification({ ...openNotification, open: true, data: result }) }}
                                data={result}
                            />)
                        }

                    </div>
                    :
                    <Backdrop />
            }
           
            {openNotification.open && <UserNotifyPopup data={openNotification.data} NotifyClose={() => setOpenNotification({ ...openNotification, open: false })} />}
        </>
    )
}
