import React from 'react'
import happy from '../static/images/happyParrot.png'
import fail from '../static/images/wrongParrot.png'
import { useTranslation } from "react-i18next";
export default function AlertPopUp({ open,msg,type,onClose}) {
    const { t } = useTranslation();
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center">
                    <div className="popup-modal-body sm">
                        <div className='my-3'>
                            <img className='d-block mx-auto' style={{width:'180px',height:'180px'}} src={type === 'ok' ? happy : fail} alt="parrot" />
                        </div>
                        <p className='ms-500 text-center my-4 px-3'>{msg}</p>
                        <button className='btn btn-login ms-700 fs-14 mb-3 mx-auto d-block' style={{width:'200px'}} onClick={onClose}>{t('button.okay')}</button>
                    </div>
                </div>
            }
        </>
    )
}
