import React from 'react'
import { useTranslation } from "react-i18next";

export default function TermsCondition() {
    const { t } = useTranslation();
    return (
       <React.Fragment>
        <div className="ms-1000">
            <div className="row ms-500">
                <div className="col-lg-10 col-12 mx-auto  py-2">
                    <p>{t('legalPages.terms&Condition.para1')}</p>
                    <p>{t('legalPages.terms&Condition.para2')}</p>
                    <p>{t('legalPages.terms&Condition.para3')}</p>
                    <p>{t('legalPages.terms&Condition.para4')}</p>
                    <p>{t('legalPages.terms&Condition.para5')}</p>
                    <p>{t('legalPages.terms&Condition.para6')}</p>
                    <p>{t('legalPages.terms&Condition.para7')}</p>
                    <p>{t('legalPages.terms&Condition.para8')}</p>
                    <p>{t('legalPages.terms&Condition.para9')}</p>
                    <p>{t('legalPages.terms&Condition.para10')}</p>
                    <p>{t('legalPages.terms&Condition.para11')}</p>
                    <p>{t('legalPages.terms&Condition.para12')}</p>
                    <p>{t('legalPages.terms&Condition.para13')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point1')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point2')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point3')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point4')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point5')}</p>
                    <p>{t('legalPages.terms&Condition.amazonPay.point6')}</p>
                </div>
            </div>
        </div>
       </React.Fragment>
    )
}
