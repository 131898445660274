import React, { useState, useEffect, useRef } from 'react'
import tutorHelper from '../helper/tutor'
import moment from 'moment';
import './calendar.css'
import parrot from '../static/images/Logo.svg'
import Backdrop from '../component/Backdrop'
import calendar from '../controller/calendar';
import helper from '../helper/index';
import { useTranslation } from "react-i18next";
export default function EditAvailibilty({ open, data, close, cb, day }) {
    const { t } = useTranslation();
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [slot, setSlot] = useState(data)
    const [newSlotList, setNewSlotList] = useState([])
    const slotForm = useRef()
    const [pageload, setPageload] = useState(false)
    const [state, setState] = useState({
        openPopup: false,
        type: 'ok',
        msg: '',
        load: false
    })
    useEffect(() => {
        setStartTime()
        setEndTime()
    }, [open])

    const addSlot = () => {
        if (!startTime || !endTime) {
            helper.showMessage(t('messages.calendar.plzSelectSlot'), "warning")
            return
        }
        let list = [...slot]
        const st = moment(startTime, 'hh:mm').format('HH:mm')
        const et = moment(endTime, 'hh:mm').format('HH:mm')
        if (st >= et) {
            helper.showMessage(t('messages.calendar.timeInvalid'), "warning")
            return
        }
        let find = false;
        list.some((data) => {
            let existStartTime = moment(data.start_time, 'hh:mm').format('HH:mm')
            let existEndTime = moment(data.end_time, 'hh:mm').format('HH:mm')
            if (!((st <= existStartTime && et <= existStartTime) || (st >= existEndTime && et >= existEndTime))) {
                find = true
                return true
            } else {
                return false
            }
        })
        if (find) {
            helper.showMessage(t('messages.calendar.slotExist'), "info")
            return
        }

        list.push({
            start_time: moment(startTime, 'hh:mm').format('HH:mm'),
            end_time: moment(endTime, 'hh:mm').format('HH:mm'),
            status: "Available"
        })
        let newList = newSlotList
        newList.push({
            start_time: moment(startTime, 'hh:mm').format('HH:mm'),
            end_time: moment(endTime, 'hh:mm').format('HH:mm'),
            status: "Available"
        })
        slotForm.current.reset()
        setNewSlotList(newList)
        setSlot(list)
    }

    const removeSlot = async (index, id, availabilityId) => {
        let list = [...slot]
        list.splice(index, 1)
        setSlot(list)
        const req = {
            "id": id,
            "availability_id": availabilityId
        }
        setPageload(true)
        await calendar.removeAvailibilty(req).then((res) => {
            cb()
            setPageload(false)
        })
    }

    const saveSlot = async () => {
        let req = {
            tutor_id: helper.getid(),
            availability_time: newSlotList,
            dates: [{ date: moment(day).format('YYYY-MM-DD') }]
        }
        if (newSlotList.length === 0) {
           return helper.showMessage(t('messages.calendar.plzSelectSlot'), "warning")
        }
        setState({ ...state, load: true })
        await calendar.addAvailibilty(req).then((res) => {
            if (res.data.status) {
                helper.showMessage(t('messages.calendar.availabilityAdded'), "success")
                close()
                cb()
            }
            else {
                helper.showMessage(res.data.error, "error")
            }
            setState({ ...state, load: false })
        }).catch((err) => {
            helper.showMessage(err, "error")
            setState({ ...state, load: false })
        })
    }
    return (
        <>
            <div className="popup-modal h-100 all-center ltr">
                <div className="popup-modal-body sm p-2 pb-3  px-3">
                    <span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span>
                    <div className='clear my-0'></div>
                    <div className='mb-3 mt-2'>
                        <img className='mx-auto d-block' height={'70px'} src={parrot} alt="parrot" />
                    </div>
                    <h4 className='f-fred tx-lt-grey  text-center py-2 text-lower'>{t('tutorDash.calendar.updateAvailabilty')}</h4>
                    {/* <h4 className='f-fred tx-lt-grey text-center py-2'>Update availability</h4> */}
                    <p className='text-center ms-500 mt-2'><span className='tag-box py-2 px-2'>{day}</span></p>
                    <form ref={slotForm} onSubmit={(e) => e.preventDefault()}>
                        <div className="row gx-0 fs-16 mx-0 ms-700 mt-4">
                            <div className="col-5 px-2">
                                <select className="mr-2 icon-border w-100 fs-14 px-2 f-fred tx-lt-grey py-2"
                                    onChange={(e) => { setStartTime(e.target.value); }}
                                >
                                    <option className="fs-16 ms-900 tx-lt-black" value="">{t('tutorDash.calendar.selectStartTime')}</option>
                                    {
                                        tutorHelper.getSlots2().map((data, index) =>

                                            // (moment().format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) ?
                                            //     moment(data, 'HH:mm').format('HH:mm') >= moment().format('HH:mm') &&
                                            //     <option className="fs-16 ms-700 " key={`stTime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option> :
                                            //     <option className="fs-16 ms-700 " key={`stTime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                            <option className="fs-16 ms-700 " key={`time${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>

                                            // <option className="fs-16 ms-700 "    key={`st${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                        )}
                                </select>
                            </div>
                            <div className="col-5 px-2">
                                <select className="mr-2 icon-border w-100 fs-14 px-2 f-fred tx-lt-grey py-2"
                                    onChange={(e) => { setEndTime(e.target.value); }}
                                >
                                    <option className="fs-16 ms-900 tx-lt-black" value="">{t('tutorDash.calendar.selectEndTime')}</option>
                                    {
                                        tutorHelper.getSlots2().map((data, index) =>
                                            // (moment().format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) ?
                                            //     moment(data, 'HH:mm').format('HH:mm') >= moment().format('HH:mm') &&
                                            //     <option className="fs-16 ms-700 " key={`etTime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option> :
                                            //     <option className="fs-16 ms-700 " key={`etTime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                            <option className="fs-16 ms-700 " key={`etime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>

                                            // <option key={`th${index}`} className="fs-16 ms-700" value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                        )}
                                </select>
                            </div>
                            <div className="col-2 px-2 d-flex justify-content-end align-items-center">
                                <i className="fas fs-24 pointer fa-plus-square touch-hover tx-yellow" onClick={addSlot}></i>
                            </div>
                            <div className="col-12 px-2 mt-3">

                                {
                                    slot.map((slotData, index) => {
                                        return (
                                            <div key={`slot${index}`}>
                                                <div className='d-flex ms-500 justify-content-between my-2'>
                                                    <p className={`${slotData.status === "Booked" && "ms-700 tx-green"}`}>{moment(slotData.start_time, 'hh:mm').format('hh : mm A')} <span className='ms-900 mx-3'>To</span> {moment(slotData.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                    {slotData.status === "Booked" ?
                                                        <span className='ms-700 tx-green'>Booked</span> :
                                                        <i className="far pointer tx-error fs-20 fa-trash-alt touch-hover" onClick={() => { removeSlot(index, slotData.id, slotData.availability_id) }}></i>
                                                    }
                                                </div>
                                                <hr className='my-2' />
                                            </div>
                                        )
                                    })
                                }


                            </div>

                            <div className="col-12 px-2 mt-3">
                                <button type='button' className='btn mx-auto d-block btn-login  fs-14' style={{ width: '200px' }} onClick={saveSlot}>{t('general.submit')}</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <div>

            </div>
            {(state.load || pageload) && <Backdrop />}
        </>
    )
}
