import React, { useState, useEffect } from 'react'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import WebFooter from './WebFooter';
import tutor from '../controller/tutor';
import FaqAccordion from '../component/FaqAccordion';
import helper from '../helper';
import { Helmet } from 'react-helmet';

export default function WebTutorFaqs() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation()
    const [state, setState] = useState({ faqs: [], categories: [] })

    useEffect(() => {
        fetchFaqs()
        helper.scroll()
        return () => { }
    }, [])

    const fetchFaqs = async () => {
        await tutor.getFaqs(3)
            .then((res) => {
                let list = []
                if (res.data.result) {
                    for (let i = 0; i < res.data.result.length; i++) {
                        if (!list.includes(res.data.result[i].category)) {
                            list.push(res.data.result[i].category)
                        }
                    }
                }
                setState({ categories: list, faqs: res.data.result })
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Tutor FAQs - Help for Tutors - SayDhad</title>
                <meta name="description" content="SayDhad values the time of tutors. So, if you face any technical glitch during an online class, our tutor help centre will help you resolve it. Whether your camera is not working or you can't see your student on screen, get answers to them on our tutor FAQs page."
                />
                <link rel="canonical" href=" https://saydhad.com/faqs/tutor/" />
            </Helmet>
            <section className='row banner-section help-center'>
                <div className="col-lg-11 col-12 mx-auto">
                    <WebHeader />
                    <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
                        <div className="row ms-500">
                            <div className="col-lg-10 col-12 mx-auto  py-2">
                                <h2 className='mb-1 tx-lt-grey f-fred'>{t("home.faq.Tutor help center")}</h2>
                            </div>
                            <div className="col-lg-10 col-12 mx-auto  py-2">
                                {
                                    state.categories.map((cat) => {
                                        return (
                                            <>
                                                <p key={cat.id} className="f-fred mb-2 mt-3 text-capitalize fs-20" style={{ color: '#434943' }}>{cat}</p>
                                                {
                                                    state.faqs.map((faqs) => {
                                                        if (faqs.category === cat) {
                                                            return (
                                                                <FaqAccordion key={`faq${cat.id}`} data={faqs} category={cat} />
                                                            )
                                                        } else {
                                                            return <></>
                                                        }
                                                    })}
                                            </>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <div>

                        </div>
                    </section>
                    <WebFooter />
                </div>
            </section>
        </React.Fragment>
    )
}
