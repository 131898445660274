import React, { useEffect } from 'react'
import helper from '../helper'
import WebHeader from './WebHeader'
import '../static/css/aboutus.css'
import About1 from '../static/images/home/about-1.png'
import About2 from '../static/images/home/about-2.png'
import About3 from '../static/images/home/about-3.png'
import About4 from '../static/images/home/about-4.png'
import WebFooter from './WebFooter'
import topImg from '../static/images/home/Mask Group 46.svg'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
export default function Aboutus() {
  const reduxState = useSelector(state => state)
  const { t } = useTranslation()
  useEffect(() => {
    helper.scroll()
  }, [])
  return (
    <>
      <Helmet>
        <title>About US - SayDhad</title>
        <meta name="description" content='SayDhad is an online Arabic language institute that offers high-quality Arabic learning. Our certified tutors have decades of experience creating a practical and engaging learning experience. Visit our website and learn more about our immersive teaching methods' />
        <link rel="canonical" href=" https://saydhad.com/about-us/" />
      </Helmet>
      <div className='aboutus'>
        <section className='row footer-section '>
          <div className="col-lg-11 col-12 mx-auto">
            <section className=''>
              <img src={topImg} className="mx-auto d-block mw-100" alt='top-carpet' />
            </section>
            <WebHeader headerFor={'Student'} />
          </div>
        </section>
        <section className='row'>
          <div className="col-lg-10 col-12 mx-auto">
            <section className='about-body my-5 aboutus-pad'>
              <h2 className='about-heading text-center my-3'>{t('legalPages.aboutUs.heading')}</h2>
            </section>


            <section className='aboutus-pad'>
              <img src={About1} alt="about" className='mw-100' style={{ minHeight: '200px' }} />
            </section>

            <section className="row my-5 aboutus-pad">
              <div className={`col-lg-4 col-md-5 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                {/* <p className='ms-500 mb-2'>{t('legalPages.aboutUs.text1')}</p> */}
                <h2 className='about-heading'>{t('legalPages.aboutUs.vision')}</h2>
              </div>
              <div className={`col-lg-8 col-md-7 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                <p className='about-text-2 mb-3'>{t('legalPages.aboutUs.para1')}</p>
              </div>
            </section>

            <section className="row my-5 d-flex aboutus-pad">
              <div className={`col-lg-7 order-lg-1 order-2 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                {/* <p className='ms-500 mb-2'>{t('legalPages.aboutUs.text1')}</p> */}
                <h2 className='about-heading mb-3'>{t('legalPages.aboutUs.title1')}</h2>
                <p className='about-text-2 mb-3'>{t('legalPages.aboutUs.para2')}</p>
                <p className='about-text mb-3'>{t('legalPages.aboutUs.para3')}</p>
              </div>
              <div className="col-lg-1 order-lg-2 "></div>
              <div className="col-lg-4 order-lg-3 order-1 mb-2">
                <div>
                  <img src={About4} className="mw-100" alt="about" />
                </div>
              </div>
            </section>

            <section className="row my-5 aboutus-pad">
              <div className="col-lg-4 mb-2">
                <div>
                  <img src={About3} className="mw-100" alt="about" />
                </div>
              </div>
              <div className="col-lg-1 "></div>
              <div className={`col-lg-7 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                {/* <p className='ms-500 mb-2'>{t('legalPages.aboutUs.text1')}</p> */}
                <h2 className='about-heading mb-3'>{t('legalPages.aboutUs.title2')}</h2>
                <p className='about-text-2 mb-3'>{t('legalPages.aboutUs.para4')}</p>
                <p className='about-text mb-3'>{t('legalPages.aboutUs.para5')} </p>
                <ul className='about-text'>
                  <li>{t('legalPages.aboutUs.bullet1')}</li>
                  <li>{t('legalPages.aboutUs.bullet2')}</li>
                  <li>{t('legalPages.aboutUs.bullet3')}</li>
                  <li>{t('legalPages.aboutUs.bullet4')}</li>
                  <li>{t('legalPages.aboutUs.bullet5')}</li>
                  <li>{t('legalPages.aboutUs.bullet6')}</li>
                </ul>
              </div>
            </section>

            <section className="row my-5 d-flex aboutus-pad">
              <div className={`col-lg-7 order-lg-1 order-2 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                {/* <p className='ms-500 mb-2'>{t('legalPages.aboutUs.text1')}</p> */}
                <h2 className='about-heading mb-3'>{t('legalPages.aboutUs.title3')}</h2>
                <p className='about-text-2 mb-3'>{t('legalPages.aboutUs.subtitle')}</p>
                <ul className='about-text'>
                  <li>{t('legalPages.aboutUs.bullet7')}</li>
                  <li>{t('legalPages.aboutUs.bullet8')}</li>
                  <li>{t('legalPages.aboutUs.bullet9')}</li>
                  <li>{t('legalPages.aboutUs.bullet10')}</li>
                  <li>{t('legalPages.aboutUs.bullet11')}</li>
                  <li>{t('legalPages.aboutUs.bullet12')}</li>
                  <li>{t('legalPages.aboutUs.bullet13')}</li>
                  <li>{t('legalPages.aboutUs.bullet14')}</li>
                  <li>{t('legalPages.aboutUs.bullet15')}</li>
                  <li>{t('legalPages.aboutUs.bullet16')}</li>

                </ul>
              </div>
              <div className="col-lg-1 order-lg-2"></div>
              <div className="col-lg-4 order-lg-3 order-1 mb-2">
                <div>
                  <img src={About2} className="mw-100" alt="about" />
                </div>
              </div>
            </section>


          </div>
        </section>

      </div>
      <section className='row footer-section '>
        <div className="col-lg-10 col-12 mx-auto">
          <WebFooter />
        </div>
      </section>
    </>
  )
}
