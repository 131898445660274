/* eslint-disable */
import React, { useRef, useState } from 'react'
import eye from '../static/images/eye.svg'
import { FaEyeSlash } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
export default function ToggleHidePassword({ onChange, placeholder, className }) {
    const passRef = useRef()
    const { t } = useTranslation();
    const [show, setshow] = useState(false)
    const toggle = () => {
        if (passRef.current.type == "password") {
            setshow(true)
            passRef.current.type = "text"
        } else {
            setshow(false)
            passRef.current.type = "password"
        }
    }
    return (
        <div className={`input-box  p-rel d-flex w-100 align-items-center mb-1 ${className}`}>
            <input
                ref={passRef}
                type="password"
                placeholder={placeholder || t('auth.forgot.password')}
                style={{ height: '49px' }}
                className="px-2 py-1 ms-700"
                onChange={(e) => { onChange(e) }}
                maxLength={30}
            />
            {
                show ?
                    <img className='p-abs rt-0 mr-2 mb-2 pointer' onClick={toggle} src={eye} alt="eye" />
                    : <FaEyeSlash className='p-abs rt-0 mb-2 mr-2 pointer' style={{ color: '#159D18' }} onClick={toggle} />}
        </div>
    )
}
