import React, { useState, useEffect } from 'react'
import helper from '../helper/index';
import tutor from '../controller/tutor';
import Backdrop from '../component/Backdrop';
import { useHistory } from 'react-router-dom';
import FaqAccordion from '../component/FaqAccordion';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function Faqs() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [pageLoad, setPageload] = useState(true)
    const [resData, setResData] = useState([])
    const [faqCatlist, setFaqCatList] = useState([])

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await tutor.getFaqs()
            .then((res) => {
                let list = []
                if (res.data.result) {
                    for (let i = 0; i < res.data.result.length; i++) {
                        if (!list.includes(res.data.result[i].category)) {
                            list.push(res.data.result[i].category)
                        }
                    }
                }
                setResData(res.data.result)
                setFaqCatList(list)
                setPageload(false)
            })
    }

    return (
        <div className={`faqs ${reduxState.language === 'ar' && "text-right"}`}>
            <div className="row mb-2">
                <div className="col-lg-12 d-flex align-items-center">
                    <button className="btn back-btn py-1 fs-14 f-fred ls px-3 mr-2" onClick={() => history.goBack()}>{t('general.back')}</button>
                    <div className='mx-2'>
                        <p className="lh-18 fs-18 f-fred">{t('general.support.faqs')} <span className="tx-green">{t('general.support.categories')}</span></p>
                        <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)}  {t('general.support.supportText')}</p>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                {!pageLoad &&
                    <div className="col-lg-9 col-md-10 col-12  mx-auto">
                        {
                            faqCatlist.map((cat) => {
                                return (
                                    <>
                                        <p key={cat.id} className="f-fred mb-2 mt-3 text-capitalize fs-20" style={{ color: '#434943' }}>{cat}</p>
                                        {
                                            resData.map((faqs) => {
                                                if (faqs.category === cat) {
                                                    return (
                                                        <FaqAccordion key={`faq${cat.id}`} data={faqs} category={cat} />
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                    </>
                                )
                            })
                        }

                    </div>
                }
                {pageLoad && <Backdrop />}
            </div>
        </div>
    )
}
