import React from 'react'
import { DualRing } from 'react-awesome-spinners'
export default function Loader({ open }) {
    return (
        <>
            {open && <div className="popup-modal all-center" style={{backgroundColor: 'rgba(59, 59, 58, 0.233)'}}>
                 <DualRing size={60} color="#20bef8" />
            </div>}
        </>
    )
}
