/* eslint-disable */
import React, { useState, useEffect } from 'react'
import CourseCard from '../component/CourseCard'
import tutor from '../controller/tutor'
import Backdrop from '../component/Backdrop'
import helper from '../helper'
import Message from '../component/Message'
import { useHistory } from 'react-router'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function TutorLibrary() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState([])
    const [recentCourse, setRecentCourse] = useState([])
    const [newCourse, setNewCourse] = useState([])
    const [openMsg, setOpenMsg] = useState({ open: false, msg: "", type: 'success' })
    const [qry, setQry] = useState()
    const [state, setState] = useState({
        searchData: {
            open: false,
            data: []
        },
        openFilter: false,
        filterData: null
    })
    useEffect(() => {
        recentlyAddedCourse()
        getCourses()
        return () => { }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCourses = async () => {
        await tutor.getCourseListing(helper.getid())
            .then((res) => {
                const list = [], newList = []
                if (res.data.status) {
                    res.data.result.forEach((data) => {
                        list.push(data.courseCategory)

                    })
                    setResData(res.data.result)
                    setState({
                        ...state, searchData: {
                            open: false,
                            data: res.data.result
                        }
                    })
                    res.data.result.forEach((data) => {
                        data.course.forEach((course) => {
                            if (helper.getDateDifference(course.createdOn) < 30) {
                                newList.push(course)
                            }
                        })
                    })
                    setNewCourse(newList)
                }

                setPageload(false)
            }).catch((err) => {
                setPageload(false)
            })
    }

    const recentlyAddedCourse = async () => {
        await tutor.getCourseFromLibrary(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setRecentCourse(res.data.result)
                }
            })
    }

    const removeLibrary = async (id) => {
        setPageload(true)
        await tutor.removeCourseFromLibrary({ tutorId: helper.getid(), courseId: id })
            .then((res) => {
                setPageload(false)
                if (res.data.result) {
                    recentlyAddedCourse()
                    getCourses()
                    setOpenMsg({ ...openMsg, open: true, msg: res.data.result, type: 'success' })
                } else {
                    setOpenMsg({ ...openMsg, open: true, msg: res.data.error, type: 'error' })
                }
                setTimeout(() => {
                    setOpenMsg({ ...openMsg, open: false })
                }, 1500);
            })
    }

    const search = () => {
        if (qry) {
            let regEx = new RegExp(qry, 'si')
            let list = []
            resData.forEach((result) => {
                let course = []
                result.course.forEach((data) => {
                    if (data.title && (data.title.match(regEx) || result.courseCategory.match(regEx))) {
                        course.push(data)
                    }
                })
                if (course.length !== 0) {
                    list.push({
                        course: course,
                        courseCategory: result.courseCategory
                    })
                }
            })
            setState({
                ...state, searchData: {
                    open: true,
                    data: list
                }
            })
        }
        else {
            setState({
                ...state, searchData: {
                    open: false,
                    data: resData
                }
            })
        }
    }


    return (
        <>
            <div className={`${reduxState.language === 'ar' && "text-right"}`}>
                <form onSubmit={(e) => { e.preventDefault(); search() }}>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                            <div className='p-rel h-100 d-flex align-items-center w-100'>
                                <input maxLength={25} type="text" placeholder={t('tutorDash.library.enterCourse')} value={qry} className="w-100  search-box ms-700 pl-2 pr-5 py-2 fs-14 " 
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            const reg = new RegExp(/^[a-zA-Z ,.-]*$/gi)
                                            const res = reg.test(e.target.value.replace(/\n/g, " "));
                                            if (res) {
                                                setQry(e.target.value)
                                            }
                                        } else {
                                            setQry("")
                                        }
                                    }}
                                 />
                                {qry && <FaTimes size={22} style={{ right: '5px' }} className='mx-2 inactive-icon-color p-abs   pointer pointer ' onClick={() => { setState({ ...state, searchData: { open: false, data: resData } }); setQry('') }} />}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                            <button type="submit" className="btn btn-login w-100 h-100  wrap">{t('tutorDash.library.searchCourse')}</button>
                        </div>
                        <div className="col-lg-5 d-lg-block d-none"></div>
                        <div className="col-lg-2 col-6 mb-2">
                            <button type="button" className="btn btn-tutor-profile w-100 h-100 wrap" onClick={()=>history.push('/')}>{t('tutorDash.library.browseDhad')}</button>
                        </div>
                    </div>
                </form>
                {!pageload &&
                    <>
                        {
                            recentCourse.length !== 0 && !state.searchData.open &&
                            <>
                                <p className="f-fred my-3 px-1 fs-24  tx-lt-grey">{t('tutorDash.library.myCourse')}</p>
                                <section className="row gx-0 mx-0">
                                    {
                                        recentCourse.map((courses, index) => {
                                            return (
                                                <>
                                                    {
                                                        courses.course.map((courses, index1) => {
                                                            return (
                                                                <div key={`course-card${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                                    <CourseCard
                                                                        name={courses.title}
                                                                        level={courses.experienceLevel}
                                                                        subTitle={courses.subTitle}
                                                                        lesson={courses.totalLessons}
                                                                        slides={courses.totalSlide}
                                                                        isRemove={true}
                                                                        img={courses.imgName}
                                                                        onRemove={() => removeLibrary(courses.id)}
                                                                        onChange={() => { history.push(`/tutor/dashboard/library/${courses.id}`) }}
                                                                    />
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </section>
                                <div className="border-dot my-2"></div>
                            </>
                        }




                        {/* recently added course  */}
                        {newCourse.length !== 0 && !state.searchData.open && !state.searchData.open && <section className="row gx-0 mx-0">
                            {

                                <>
                                    <div className="col-12">
                                        <p className="f-fred mb-2 mt-3 px-1 text-capitalize fs-24  tx-lt-grey">{t('tutorDash.library.recentlyAddedCourse')}</p> </div>
                                    {
                                        newCourse.map((courses, index1) => {

                                            return (
                                                <div key={`recent-courses${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                    <CourseCard
                                                        name={courses.title}
                                                        level={courses.experienceLevel}
                                                        subTitle={courses.subTitle}
                                                        lesson={courses.totalLessons}
                                                        slides={courses.totalSlide}
                                                        isRemove={courses.enrolled_courses}
                                                        onRemove={() => removeLibrary(courses.id)}
                                                        img={courses.imgName}
                                                        onChange={() => { history.push(`/tutor/dashboard/library/${courses.id}`) }}
                                                    />
                                                </div>
                                            )


                                        })

                                    }
                                </>
                            }
                            <div className="col-12"><div className="border-dot my-2"></div></div>
                        </section>
                        }

                        <section className="row gx-0 mx-0">
                            {
                                !pageload && state.searchData.data.map((cat) => {
                                    return (
                                        <>
                                            <div key={cat.id} className="col-12"><p className="f-fred mb-2 mt-3 fs-24  tx-lt-grey px-1 text-capitalize">{cat.courseCategory}</p> </div>
                                            {
                                                cat.course.map((courses, index1) => {
                                                    if (helper.getDateDifference(courses.createdOn) >= 1) {
                                                        return (
                                                            <div key={`course+${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                                <CourseCard
                                                                    name={courses.title}
                                                                    level={courses.experienceLevel}
                                                                    subTitle={courses.subTitle}
                                                                    lesson={courses.totalLessons}
                                                                    slides={courses.totalSlide}
                                                                    isRemove={courses.enrolled_courses}
                                                                    onRemove={() => removeLibrary(courses.id)}
                                                                    img={courses.imgName}
                                                                    onChange={() => { history.push(`/tutor/dashboard/library/${courses.id}`) }}
                                                                />
                                                            </div>
                                                        )
                                                    }else
                                                    return <></>

                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                            {state.searchData.data.length === 0 &&
                                <div className='col-12'>
                                    <h5 className='tx-lt-grey text-center my-3 f-fred'>No Result found.</h5>
                                </div>
                            }

                        </section>

                    </>
                }
            </div>


            {pageload && <Backdrop />}

            {openMsg.open && <Message message={openMsg.msg} type={openMsg.type} />}

        </>

    )
}
