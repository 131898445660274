import React, { useState } from 'react';
import happy from '../static/images/logo.png'
import store from '../redux/AppState'
import { useSelector } from 'react-redux';
import socket from '../controller/socket';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Backdrop from '../component/Backdrop';
import Zoom from 'react-reveal/Zoom';
import audio from '../static/notification.mp3'
import avatar from '../static/images/avatar.png'
import StudentProfile from './StudentProfile';
import { useTranslation } from "react-i18next";

export default function ClassNotification() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [pageload, setPageload] = useState(false)
    const [state, setState] = useState({
        openProfile: false,
        id: 0
    })

    // Responding to class
    const responseToClass = async (status) => {
        setPageload(true)
        await socket.acceptRejectClassReq({ status: status, startTime: moment().format('HH:mm'), channel: storeData.classNotification.data.channel_Name }).then((res) => {
            if (res.data.status) {
                if (status === 'Accepted') {
                    history.push(`/instant-online-class/${res.data.result.channel_name}`)
                }
            }
            store.setNewNotification(false)
            setPageload(false)
            store.setClassNotification({
                msg: '',
                title: '',
                channelName: '',
                open: false
            })
        })
    }
    return (
        <>
            {storeData.classNotification.open &&
                <div className="popup-modal h-100 all-center">
                    <audio src={audio} autoPlay></audio>
                    <Zoom center>
                        <div className="popup-modal-body sm">
                            <div className='my-3'>
                                <img className='d-block mx-auto' style={{ width: '200px' }} src={happy} alt="parrot" />
                            </div>
                            <p className='f-fred fs-24 text-center'>{t('messages.class.youHave')} {storeData.classNotification.data && storeData.classNotification.data.slot_minutes} <span className='fs-20'>{t('messages.class.min')}</span> {t('messages.class.callRequest')}</p>
                            <p className='ms-700 fs-20 text-center'>{storeData.classNotification.data && storeData.classNotification.data.courseName}</p>

                            <section className='px-4'>
                                <div className={`d-flex tutor-big-card pointer mb-3 b-grey bg-white mb-2 px-2 py-2 mt-2  tp-0`} onClick={() => { setState({ ...state, openProfile: true, id: storeData.classNotification.data.stdDetails.student_id }) }}>
                                    <div className="all-center my-2">
                                        <div className='p-rel mr-2'>
                                            <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={storeData.classNotification.data && storeData.classNotification.data.stdDetails.profile ? storeData.classNotification.data.stdDetails.profile : avatar} alt="tutor" />
                                        </div>
                                    </div>

                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className="my-2 pl-1 d-flex mr-5 flex-column justify-content-center">
                                            <p className="text-capitalize fs-16 f-fred">{storeData.classNotification.data && storeData.classNotification.data.stdDetails.name}</p>
                                            <p className='ms-500 fs-14'>{storeData.classNotification.data && storeData.classNotification.data.stdDetails.country}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </section>
                            <section className='all-center '>
                                <button className='btn btn-login mx-2 text-white py-2  mb-3' style={{ width: '150px' }} onClick={() => {
                                    responseToClass('Accepted')
                                }}>{t('button.joinClass')}</button>
                                <button className='btn cancel-subscription f-fred  mx-2 text-white py-2 fs-14 mb-3' style={{ width: '150px' }} onClick={() => {
                                    responseToClass('Rejected')
                                }}>{t('button.cancel')}</button>
                            </section>

                            {pageload && <Backdrop />}
                        </div>
                    </Zoom>

                    {/* Student profile */}
                    {state.openProfile && <StudentProfile id={state.id} onClose={() => setState({ ...state, openProfile: false })} />}
                </div >
            }
        </>
    );
}
