import * as React from "react";

const SvgDashboard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Dashboard_svg__a">
        <path
          data-name="Rectangle 432"
          transform="translate(9272 3992)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 41"
      transform="translate(-9272 -3992)"
      clipPath="url(#Dashboard_svg__a)"
    >
      <g data-name="Iconly/Bold/Chart">
        <path
          data-name="Path 4385"
          d="M9292.6 3995c4.081 0 6.385 2.315 6.4 6.4v11.2c0 4.08-2.316 6.4-6.4 6.4h-11.2c-4.085 0-6.4-2.316-6.4-6.4v-11.2c0-4.085 2.315-6.4 6.4-6.4h11.2Zm-5 4.956a1.008 1.008 0 0 0-1.537.948v12.228a1.011 1.011 0 0 0 1 .9.992.992 0 0 0 1-.9V4000.9a.979.979 0 0 0-.463-.944Zm-5.6 3.936a.988.988 0 0 0-1.056 0 1.009 1.009 0 0 0-.468.948v8.292a1 1 0 0 0 1.991 0v-8.292a1.01 1.01 0 0 0-.467-.948Zm11.111 4.356a1.006 1.006 0 0 0-1.067 0 .967.967 0 0 0-.456.948v3.936a.992.992 0 0 0 1 .9 1.011 1.011 0 0 0 1-.9v-3.932a1 1 0 0 0-.481-.952Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgDashboard;
