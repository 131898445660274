/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import helper from '../helper/index';
import tutor from '../controller/tutor';
import Backdrop from '../component/Backdrop';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { getAllSupportCategory } from '../controller/master';

export default function SupportTicketPopup({ close }) {
    const { t } = useTranslation();
    const outSide = useRef()
    const ticketForm = useRef();
    const history = useHistory()
    const [error, setError] = useState('')
    const [pageLoad, setPageload] = useState(false)
    const [category, setCategory] = useState()
    const [supportCategory, setSupportCategory] = useState([])
    const [image,setImage] = useState()

    useEffect(() => {
        async function getCategory() {
            let role = 2 // for student
            if (helper.getRoleType() === "tutor") {
                role = 3
            }
            await getAllSupportCategory(role).then((res) => {
                if (res.data.status) {
                    setSupportCategory(res.data.result)
                }
            })
        }
        getCategory()
    }, [])

    const submit = async () => {
        const form = new FormData(ticketForm.current)
        form.append("user_id", helper.getid())
        if (!form.get('category') || !form.get('sub_category') || !form.get('description')) {
            helper.showMessage(t('messages.allFields'))
            return
        }
        // let validateResp = helper.validate("name", form.get('description'))
        // if (!validateResp.isValid) {
        //     helper.showMessage("Please enter valid description")
        //     return
        // }
        supportCategory.forEach((data) => {
            if (data.category == form.get('category')) {
                form.append('priority_level', data.priority)
            }
        })

        setPageload(true)
        await tutor.createTicket(form)
            .then((res) => {
                setPageload(false)
                if (res.data.status) {
                    close()
                    history.push(`/${helper.getRoleType() === "user" ? "user" : "tutor"}/dashboard/support/ticket`)
                }
                else {
                    setError(res.data.error)
                }
            })
    }

    return (
        <div className="faded" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
            <div className="p-5 rightpop-up" >
                <div className="row gx-0 mx-0">
                    <div className="col-lg-8 col-10">
                        <h4 className="f-fred mb-0">{t('userSidebar.support.create')} <span className="tx-green">{t('userSidebar.support.ticket')}</span></h4>
                        <p className="sub-heading mb-3">{t('userSidebar.support.suppTicket')}</p>
                    </div>
                    <div className="col-lg-4 col-2 d-flex justify-content-end tx-grey pointer"><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></div>
                </div>

                <form ref={ticketForm} onSubmit={(e) => { e.preventDefault(); submit() }}>
                    <div className="row f-fred fs-12">
                        <div className="col-12 pr-1">
                            <select name="category" className="d-block px-2 w-100 input-box f-fred fs-14 tx-lt-grey text-capitalize mt-2" onChange={(e) => setCategory(e.target.value)}>
                                <option value="" className='tx-grey'>{t('userSidebar.support.category') + " *"}</option>
                                {supportCategory.map((data, index) => <option key={`cat${index}`} value={data.category_name}>{data.category_name}</option>)}
                            </select>
                        </div>

                        <div className="col-12 pr-1">
                            <select name="sub_category" className="d-block px-2 w-100 input-box mt-2 f-fred fs-14 tx-lt-grey text-capitalize" >
                                <option value="" className='tx-grey'>{t('userSidebar.support.subCat') + " *"}</option>
                                {supportCategory.map((data) =>
                                    data.category_name === category && data.sub_category.map((subCat, index) => <option key={`subcat${index}`} value={subCat.sub_category_name}>{subCat.sub_category_name}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-12">
                            <textarea maxLength={500} name="description" placeholder={t('userSidebar.support.issue') + " *"} className="w-100 p-2 input-box f-fred fs-14 tx-lt-black mt-2" style={{ height: 'initial' }} rows="8"></textarea>
                        </div>

                        <div className="col-12 mb-2">
                            <label htmlFor="file_name" className="btn btn-green fs-14 ms-500 px-2 py-2">{t('general.addAttachment')} <small>{t('general.img/max')}</small></label>
                            <p className='ms-500 fs-12'>{image && image.name}</p>
                            <input type="file" accept='image/*' className="d-none" name="file_name" id="file_name" onChange={(e)=> {
                                     /* Validation for image */
                                     if ((e.target.files[0].size / 1024) / 1024 > 1) {
                                        helper.showMessage(t('messages.fileSize1Mb'), "info")
                                    } else {
                                        setImage(e.target.files[0])
                                    }
                                }}/>
                        </div>
                        <div className="col-12">
                            <p className="ms-700 fs-14 tx-error text-center">{error}</p>
                        </div>
                        <div className="col-lg-6">
                            <button className="btn btn-login fs-14" type="submit">{t('userSidebar.support.newTicket')}</button>
                        </div>
                    </div>
                </form>
            </div>
            {pageLoad && <Backdrop />}
        </div>
    )
}
