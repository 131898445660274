import React, { useEffect, useState, useRef } from 'react'
import helper from '../helper'
import WebHeader from './WebHeader'
import '../static/css/contactus.css'
import topImg from '../static/images/home/Mask Group 46.svg'
import WebFooter from './WebFooter'
import user from '../controller/user'
import { useHistory } from 'react-router-dom'
import Backdrop from '../component/Backdrop'
import { getAllSupportCategory } from '../controller/master'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'
export default function Contactus() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const [category, setCategory] = useState()
    const email = useRef()
    const name = useRef()
    const subCategory = useRef()
    const description = useRef()
    const [pageload, setPageload] = useState(false)
    const [btnEnable, setBtnEnable] = useState(false)
    const [supportCategory, setSupportCategory] = useState([])

    const history = useHistory()
    useEffect(() => {
        helper.scroll()

        async function getCategory() {
            let role = 2 // for student
            await getAllSupportCategory(role).then((res) => {
                if (res.data.status) {
                    setSupportCategory(res.data.result)
                }
            })
        }
        getCategory()

    }, [])

    const submit = async () => {
        if (!name.current.value) {
            helper.showMessage("Enter valid name", "info")
            return
        }
        let resp = helper.validate("name", name.current.value)
        if (!resp.isValid) {
            helper.showMessage(resp.msg, "info")
            return
        }
        else if (!email.current.value) {
            helper.showMessage("Enter valid email", "info")
            return
        }
        resp = helper.validate("email", email.current.value)
        if (!resp.isValid) {
            helper.showMessage(resp.msg, "info")
            return
        }
        else if (!category) {
            helper.showMessage("Please select category", "info")
            return

        }
        else if (!subCategory.current.value) {
            helper.showMessage("Please select sub category", "info")

        }
        else if (!description.current.value) {
            helper.showMessage("Please enter description", "info")
            return
        }
        else {
            setPageload(true)
            const req = {
                "name": name.current.value,
                "email": email.current.value,
                "category": category,
                "subCategory": subCategory.current.value,
                "description": description.current.value,
                "studentId": 0
            }

            await user.contactUs(req).then((res) => {
                if (res.data.status) {
                    helper.showMessage("Your query successfully submitted", "success")
                    history.push('/')
                } else {
                    helper.showMessage("Something went wrong", "error")
                }
                setPageload(false)
            })

        }
    }

    const checkField = () => {

        if (!name.current.value || !email.current.value || !category || !subCategory.current.value || !description.current.value) {
            setBtnEnable(false)
            return
        }

        let resp = helper.validate("email", email.current.value)
        if (!resp.isValid) {
            setBtnEnable(false)
            return
        } else {
            setBtnEnable(true)
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Contact Us - SayDhad</title>
                <meta name="description" content='SayDhad is there to support you in learning your Arabic language. We are dedicated to providing excellent learning experiences with our qualified staff. For any assistance, you can contact us by phone or email. We are there for you Monday to Friday, 9:00 a.m. to 6:30 p.m.' />
                <link rel="canonical" href=" https://saydhad.com/support/" />
            </Helmet>
            <div className={`contactus ${reduxState.language === 'ar' && "text-right"}`}>
                <section className='row '>
                    <div className="col-lg-11 col-12 mx-auto">
                        <section className=''>
                            <img src={topImg} className="mx-auto d-block" alt='top' />
                        </section>
                        <WebHeader />
                        <section className='my-5 contactus-body'>
                            <h2 className='about-heading text-center my-3'>{t('legalPages.contactUs.contactText')}</h2>
                        </section>
                        <section className='my-5 contact-img'>
                            <div className='contactus-form'>
                                <div className='contactus-form-body'>
                                    <p className='contactus-form-heading'>SayDhad Support</p>
                                    <p className='fs-14 ms-700 tx-lt-grey mt-3'>{t('legalPages.contactUs.text')}</p>
                                </div>
                                <div className="inline-text my-4 all-center">
                                    <p ><span className="bold">OR</span></p>
                                </div>
                                <p className='fs-24 f-fred tx-lt-black'>{t('legalPages.contactUs.descYour')} <span className='tx-green'>{t('legalPages.contactUs.issue')}</span></p>

                                <div>
                                    <input maxLength={30} type="text" ref={name} placeholder={t('legalPages.contactUs.name')} onChange={checkField} />
                                    <input maxLength={30} type="text" ref={email} id="" placeholder={t('legalPages.contactUs.email')} onChange={checkField} />

                                    <select className="d-block px-2  input-box f-fred fs-14 tx-lt-grey text-capitalize mt-2" onChange={(e) => setCategory(e.target.value)}>
                                        <option value="" className='tx-grey'>{t('legalPages.contactUs.category')}</option>
                                        {supportCategory.map((data, index) => <option key={`cat${index}`} value={data.category_name}>{data.category_name}</option>)}
                                    </select>
                                    <select ref={subCategory} className="d-block px-2 input-box mt-2 f-fred fs-14 tx-lt-grey text-capitalize" onChange={checkField}>
                                        <option value="" className='tx-grey'>{t('legalPages.contactUs.sub-category')}</option>
                                        {supportCategory.map((data) =>
                                            data.category_name === category && data.sub_category.map((subCat, index) => <option key={`subcat${index}`} value={subCat.sub_category_name}>{subCat.sub_category_name}</option>
                                            ))}
                                    </select>


                                    <textarea maxLength={150} onChange={checkField} ref={description} cols="30" placeholder={t('legalPages.contactUs.desc')} rows="10" style={{ height: 'initial' }}></textarea>
                                </div>

                                {
                                    btnEnable ?
                                        <button className='contect-form-submit-enable btn' onClick={submit}>{t('legalPages.contactUs.button')}</button>
                                        : <button className='contect-form-submit-disable btn'>{t('legalPages.contactUs.button')}</button>

                                }

                            </div>
                        </section>

                        <section className='address'>
                            <h2 className='about-heading text-center my-3'>Contact Details</h2>
                            <section className='text-center'>
                                <p> Email </p>
                                <p><span>contactus@saydhad.com</span></p>
                                <p> Working hours </p>
                                <p><span>09:00 am to  06:30 pm KSA</span></p>
                            </section>
                        </section>
                        {pageload && <Backdrop />}
                        <WebFooter />
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}
