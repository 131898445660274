/* eslint-disable */
import i18n from '../i18n';
import React, { useEffect, useState } from 'react'
import MultiSelect from 'react-multiple-select-dropdown-lite';
import AppState from '../redux/AppState';
import helper from '../helper';

const lngMap = {
    "ar": "عربي ",
    "en": "English "
}

let language = [
    {
        label: lngMap.ar, value: "ar", classes: 'p-1 ms-500'
    },
    {
        label: lngMap["en"], value: "en", classes: 'p-1 ms-500'
    }
]

export default function LanguageTranslator({ className }) {
    const [defaultValue, setDefaultValue] = useState([{ label: lngMap.en, value: i18n.language, classes: 'p-1 ms-500' }])
    function changeLocale(l) {
        i18n.changeLanguage(l)
        helper.scroll()
        AppState.setLng(l)
        autoClose()
        localStorage.setItem("lang", l)
        window.location.reload(true);
    }

    useEffect(() => {
        let lng = "";
        try { lng = localStorage.getItem("lang") } catch (err) { }
        if (lng) {
            AppState.setLng(lng);
            localStorage.setItem("lang", lng)
            i18n.changeLanguage(lng)
            setDefaultValue([{ label: lng, value: lng, classes: 'p-1 ms-500' }])
        } else {
            AppState.setLng("en")
            i18n.changeLanguage("en")
            localStorage.setItem("lang", "en")
            setDefaultValue([{ label: lngMap.en, value: lngMap.en, classes: 'p-1 ms-500' }])
        }
    }, [])

    const autoClose = () => {
        const activeRef = document.querySelector(".msl.msl-active")
        const arrowRef = document.querySelector(".msl .msl-arrow-btn")
        if (activeRef) {
            arrowRef.style.transform = 'rotate(0deg)';
            activeRef.classList.remove("msl-active")
            activeRef.classList.add("false")
        }
    }

    return (
        <div className={`${className}`}>
            <MultiSelect
                className='text-left text-capitalize'
                placeholder="language"
                closeOnSelect={true}
                disableChip={true}
                clearable={false}
                defaultValue={defaultValue}
                singleSelect={true}
                chipAlternateText="language"
                options={language}
                style={{ padding: '3px', width: '150px' }}
                onChange={(data) => { changeLocale(data); }}
            />
        </div>
    )
}
