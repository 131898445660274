import React, { useEffect } from 'react'
import helper from '../helper'
import Privacypolicy from '../legalpages/Privacypolicy'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
export default function WebPrivacyPolicy() {
  const reduxState = useSelector(state => state)
  const { t } = useTranslation()
  useEffect(() => {
    helper.scroll()
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>Privacy Policy - SayDhad</title>
        <meta name="description" content='SayDhad follows transparency in every Privacy policy. We won’t share any details with third parties and will protect your information with utmost security. We use information only for the betterment of our service. If you have any concerns, get in touch with us. We clarify all your doubts.' />
        <link rel="canonical" href=" https://saydhad.com/web/privacy-policy/" />
      </Helmet>
      <div>
        <section className='row banner-section '>
          <div className="col-lg-11 col-12 mx-auto">
            <WebHeader />
            <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
              <div className="row ms-500">
                <div className="col-lg-10 col-12 mx-auto  py-2">
                  <h2 className='mb-3 tx-lt-grey f-fred'>{t('legalPages.privacyPolicy.heading')}</h2>
                </div>
              </div>
              <Privacypolicy />
            </section>
            <WebFooter />
          </div>
        </section>

      </div>
    </React.Fragment>
  )
}
