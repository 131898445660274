import * as React from "react";

const SvgStudent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Student_svg__a">
        <path
          data-name="Rectangle 433"
          transform="translate(9272 4076)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 40"
      transform="translate(-9272 -4076)"
      clipPath="url(#Student_svg__a)"
    >
      <g data-name="Iconly/Bold/Calendar">
        <path
          data-name="Path 4386"
          d="M9291.18 4079a.9.9 0 0 1 .913.922v.9a5.633 5.633 0 0 1 5.493 5.966l.014 10.112c0 3.766-2.361 6.083-6.154 6.089l-9.264.011c-3.769 0-6.164-2.368-6.169-6.145l-.013-9.992a5.627 5.627 0 0 1 5.407-6.027v-.9a.913.913 0 1 1 1.825 0v.84l7.038-.01v-.841a.9.9 0 0 1 .91-.925Zm.483 17.03h-.012a1 1 0 0 0-.97 1.022 1 1 0 1 0 .982-1.022Zm-9.763 0a1.024 1.024 0 0 0-.97 1.033.978.978 0 1 0 .97-1.033Zm4.881-.006a1.025 1.025 0 0 0-.97 1.033.978.978 0 1 0 .97-1.033Zm-4.887-4.31a1.023 1.023 0 0 0-.969 1.033.978.978 0 1 0 .969-1.033Zm4.883-.042a1.024 1.024 0 0 0-.97 1.033.978.978 0 1 0 .97-1.033Zm4.881.006a.993.993 0 0 0-.97 1.007v.015a.976.976 0 0 0 1.008.958.99.99 0 0 0-.038-1.979Zm-1.386-9.067-7.038.01v.97a.913.913 0 1 1-1.825 0v-.921c-2.309.23-3.588 1.589-3.584 4.191v.373l17.939-.024v-.42c-.051-2.579-1.345-3.932-3.667-4.133v.924a.913.913 0 1 1-1.825 0Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgStudent;
