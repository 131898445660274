import React, { useEffect, useState } from 'react'
import PayoutDetailCard from '../component/PayoutDetailCard'
import { useTranslation } from "react-i18next";
import { payoutDetails } from '../controller/tutorPayout';
import moment from 'moment';
import SupportTicketPopup from '../support/SupportTicketPopup';
export default function PayoutDetail({ cb, id,transactionID }) {
    const { t } = useTranslation();

    /* Maintain state for view the listing */
    const [state, setState] = useState({
        classessList: [],
        onlineCalls: 28,
        total_time: 0,
        total_amount: 0,
        start_date: new Date(),
        end_date: new Date(),
        pageload: true,
        error: true,
        supportPop: false
    })

    /* Get Date before render */
    useEffect(() => {
        async function getPayoutDetails() {
            setState({ ...state, pageload: true, error: null })
            await payoutDetails(id).then((res) => {
                if (res.data.status) {
                  
                    setState({ ...res.data.result, pageload: false, error: null })
                } else {
                    setState({ ...state, pageload: false, error: res.data.error })
                }
            }).catch((err) => { setState({ ...state, pageload: false, error: err }) })
        }
        getPayoutDetails()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div className="payout-detail">
            <div className="row mb-2">
                <div className="col-lg-12 d-flex align-items-center">
                    <button className="btn back-btn py-1 fs-12 px-3 mr-2" onClick={cb}>{t('general.back')}</button>
                    <div className='mx-2'>
                        <p className="lh-21 f-fred fs-20">{t('tutorDash.payout.payout')} <span className="tx-green">{t('tutorDash.payout.detail')}</span></p>
                        <p className="sub-heading">DH {transactionID}</p>
                    </div>
                </div>
            </div>

            {/* Call analytics */}
            <section className="row gx-0 mx-0">
                <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.payout.onlineCalls')}</p>
                            <p className="sub-heading">From {moment(state.start_date).format('DD MMM')}- {moment(state.end_date).format('DD MMM YYYY')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green">{state.onlineCalls}</p>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.payout.totalTime')}</p>
                            <p className="sub-heading">{t('tutorDash.payout.onlineCalls')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green">{state.total_time}</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.payout.totalAmt')}</p>
                            <p className="sub-heading">{t('tutorDash.payout.fee')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green">{state.total_amount}</p>
                    </div>
                </div>
            </section>

            <section className="online-call my-2 d-flex justify-content-between">
                <div>
                    <p className="fs-18 f-fred tx-lt-black">{t('tutorDash.payout.onlineCalls')}</p>
                    <p className="sub-heading">{t('tutorDash.payout.detail')}</p>
                </div>
                <button className="btn btn-tutor-profile fs-12" onClick={() => setState({ ...state, supportPop: true })}>{t('tutorDash.payout.contactSupport')}</button>
            </section>

            {/* View class list */}
            {state.classessList.map((object) => <PayoutDetailCard data={object} />)}

            {/* Handle support pop */}
           {state.supportPop && <SupportTicketPopup close={() => setState({ ...state, supportPop: false })} />}


        </div>
    )
}
