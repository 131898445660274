/* eslint-disable */
import React from 'react'
import sign from '../static/images/sign.png'
import logo from '../static/images/parrot.png'
import border from '../static/images/border.png'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';

export default function Certificate() {
    const print = () => {

        const input = document.getElementById('certificate');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const doc = new jsPDF("landscape");
                doc.addImage(imgData, 'JPEG', 0, 0);
                doc.addImage(border, "PNG", 5, 5, 285, 203);
                doc.save("certificate.pdf");
            })
            ;
    }
    return (
        <>
            {/* SEO */}
            <Helmet>
                <title>DHAD Certificate - SayDhad</title>
                <meta name="description" content="SayDhad is an online Arabic language learning platform that offers well-crafted courses and highly qualified professional tutors that can help you become a native speaker quickly. By learning with the right experts, you will get a DHAD Certificate that enables you to achieve a better future." />
                <link rel="canonical" href=" https://saydhad.com/certificate/" />
            </Helmet>
            <div className='all-center'>

                <section style={{ width: '1200px' }}>
                    <button className='btn bg-sky py-1 px-2 text-white fs-14 ms-700 float-right  my-2' onClick={print}>PRINT</button>
                    <div className="clear"></div>

                    <div id="certificate" className="mx-auto" style={{ backgroundColor: "wheat", height: "795px", border: "orange", borderWidth: "10px", borderStyle: "solid", position: "relative", width: '1122px' }} >
                        <div className="text-center br-1 w-100 h-100 px-5 py-4" style={{ backgroundPosition: 'cover', backgroundRepeat: 'none', backgroundSize: '100% 100%', backgroundImage: `url(https://www.pngall.com/wp-content/uploads/8/Square-Orange-Frame-PNG-Free-Image.png)` }}>
                            <h2 className='f-fred mt-5' style={{ fontSize: "52px" }} >DHAD</h2>
                            <h1 className=' ms-500 tx-lt-black' style={{ fontSize: "56px" }} >CERTIFICATE</h1>
                            <p className='pt-4 mt-4 fs-18 ms-500 mx-4'>We certify that <span className='fs-26 ' style={{ color: "#099d14", fontWeight: "400" }}> Aishwarya Singh </span>has successfully completed <span className='fs-26' style={{ color: "#099d14", fontWeight: "400" }}>10 hours </span> of conversation-based Arabic classes with native-speaking tutors on SayDhad.</p>
                            <p className='pt-4 fs-18 ms-500'> SayDhad is an online platform based in Dubai, UAE that connects native English speaking tutors with students from more than 130 countries for live-conversation based tutoring sessions.</p>

                            <p className='pt-4 mt-4 fs-18 ms-500'>Dubai, UAE - Jan 15, 2022</p>

                            <img src={sign} alt="signature" width="150px" className='pt-4  ' />
                            <p >...................................................</p>

                            <p className='fs-16 ms-500'>Mr Ali | President</p>
                            <p className='fs-12'>612 Howard St, Suite 300. San Francisco, California. 94105 </p>
                            <p className='fs-12'> United States of America</p>

                            <p className='pt-2 fs-14 ms-500'>www.saydhad.com</p>

                            <p className='pt-4 fs-15 ms-500' style={{ color: "#099d14" }}>Verification URL: www.saydhad.com/en/certificate/verify/d41d8cd9</p>

                            <img src={logo} alt="logo" style={{ position: "absolute", top: "400px", right: "200px" }} />
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}