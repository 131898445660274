import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import carpet from '../static/images/green-carpet.svg'
import { useTranslation } from "react-i18next";

export default function TutorVerifyOTP() {
    const { t } = useTranslation();
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [open, setOpen] = useState(false)
    const one = useRef()
    const two = useRef()
    const three = useRef()
    const four = useRef()
   
    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        let decEmail = ""
        try {
            decEmail = helper.decrypt(url.get("tk1").toString().replace(/ /g, "+"))
            setEmail(decEmail)
            if (!decEmail) {
                history.push('/tutor/signup')
            }
        }
        catch (err) {
            if (!decEmail) {
                history.push('/tutor/signup')
            }
        }
        return () => { }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submit = async () => {
        const otpStr = one.current.value + two.current.value + three.current.value + four.current.value;
        const val = helper.validate('otp', otpStr.toString())
        if (!val.isValid) {
            helper.showMessage(val.msg, "error")
            return;
        }
        const data = {
            email: email ? email.toLowerCase() : email,
            otp: otpStr
        }
        setOpen(true)
        await auth.tutorVerify(data).then((res) => {
            setOpen(false)
            if (res.data.status) {
                helper.saveCredential(
                    res.data.result.token,
                    "tutor",
                    res.data.result.id,
                    res.data.result.profileStatus,
                    res.data.result.email
                );
                const url = new URLSearchParams(window.location.search)
                history.push(`/tutor/welcome?tk1=${url.get("tk1").toString().replace(/ /g, "+")}`)
            }
            else {
                helper.showMessage(res.data.error, "error")
            }
        })
    }

    const changeOtp = (e) => {
        /* Number validation */
        if (e.target.value) {
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(e.target.value)
            if (!res) {
                let domObj = document.getElementById(e.target.id)
                domObj.value = ""
                return
            }
        }

        var target = e.srcElement || e.target;
        var myLength = target.value.length;
        if (myLength >= 1) {
            var next = target;
            if (next.nextElementSibling) {
                target.nextElementSibling.focus();
            }
        }
        else if (myLength === 0) {
            var previous = target;
            if (previous.previousElementSibling) {
                target.previousElementSibling.focus();
            }
        }


    }

    const handleResendOTP = async () => {
        const data = {
            email: email ? email.toLowerCase() : email,
            role: "tutor"
        }
        setOpen(true)
        await auth.resendOTP(data).then((res) => {
            setOpen(false)
            if (res.data.status) {
                helper.showMessage(res.data.result, "success")
            }
            else {
                helper.showMessage(res.data.error, "error")
            }
        })
    }

    return (
        <div className="tutor-login-home all-center flex-column">
            <div className="all-center w-100 mb-2">
                <div className="all-center mb-3">
                    <img width="80%" src={logo} alt="logo" />
                </div>
            </div>
            <p className="f-fred text-center fs-24 tx-lt-black">{t('auth.otp.heading')} {email}</p>
            <p className="tx-lt-grey ms-700 px-2 text-center">{t('auth.otp.note')}</p>
            <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                <div className="select-role otp d-flex flex-column align-items-center mt-2">
                    <div id="otp" className="input-box d-flex flex-row f-fred">
                        <input
                            className="m-2 text-center box-height form-control rounded"
                            type="tel"
                            id="first"
                            maxLength={1}
                            ref={one}
                            autoFocus={true}
                            onChange={(e) => changeOtp(e)}
                        />
                        <input
                            className="m-2  text-center box-height form-control rounded"
                            type="tel"
                            id="second"
                            maxlength="1"
                            ref={two}
                            onChange={(e) => changeOtp(e)}
                        />
                        <input
                            className="m-2 text-center box-height form-control rounded"
                            type="tel"
                            id="third"
                            maxlength="1"
                            ref={three}
                            onChange={(e) => changeOtp(e)}
                        />
                        <input
                            className="m-2 text-center box-height form-control rounded"
                            type="tel"
                            id="fourth"
                            maxlength="1"
                            ref={four}
                            onChange={(e) => changeOtp(e)}
                        />

                    </div>
                    <button type="submit" className="btn btn-login box-height  mt-3" style={{ width: '150px', zIndex: 2 }}>{t('auth.continue')}</button>
                    <p className='pointer link ms-700 mt-4' onClick={handleResendOTP}>Resend OTP</p>
                </div>
            </form>
            <div className="p-fix bt-0 w-100 ">
                <img src={carpet} alt="carpet" />
            </div>
            {open && <Backdrop />}
        </div>
    )
}