import React, { useState } from 'react'
const styles = {
    boxShadow: '0 0 10px 3px rgba(204, 202, 202, 0.575)',
    transition: '0.5s',
    zIndex: 2,
    width: '150px'
}
export default function ThreeDot({ data, onClick,isTutorEnrolled}) {
    const [showDropdown, setShowDropdown] = useState(false)
    return (
        <>
            <section className='p-rel d-inline-block' >
                <i className="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow" onClick={() => setShowDropdown(!showDropdown)}></i>
                {showDropdown &&
                    <div className={`p-abs dropdown-body p-2  fs-16  ms-700  rt-0 bg-white`} style={styles}>
                        <ul style={{ listStyle: 'none' }}>
                            {data.map((data,index) => <li key={`sdot${index}`} style={{ listStyle: 'none' }}><span className='pointer' onClick={()=>{
                                if(isTutorEnrolled){
                                    setShowDropdown(!showDropdown);onClick();
                                }else{
                                    alert("Please enroll yourself for teaching this course")
                                }
                            }}>{data}</span></li>)
                            }
                        </ul>
                    </div>
                }
            </section>
        </>
    )
}
