/* eslint-disable */
import React, { useState } from 'react'
import moment from 'moment'
import avatar from '../static/images/avatar.png'
import helper from '../helper/index'
import ReactStars from "react-rating-stars-component";
import CancelClass from './CancelClass';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function ViewClassInfo({ open, onClose, data, cb }) {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [state, setState] = useState({
        cancel: false,
        data: null
    })
    return (

        <>
            {
                open &&
                <div className="all-center popup-modal">
                    <div className={`left-popup p-fix tp-0 rt-0  p-5 sm ${reduxState.language === 'ar' && "text-right"}`}>
                        <h5 className="f-fred fs-24">Booking <span className="tx-green">Information</span><span className="float-right fs-24 tx-grey pointer" onClick={onClose}><i className="far hover-shadow fa-times-circle"></i></span></h5>
                        <p className="sub-heading">Class info</p>
                        <p className='fs-18 text-center ms-700 my-3'>{moment(data[0].date).format('MMMM DD, YYYY')}</p>

                        {!state.cancel ?
                            <section>

                                {
                                    data.map((data, index) =>

                                        <div key={`tutor${index}`} className={` tutor-big-card pointer b-grey bg-white mb-2 px-2`}>
                                            <section className='d-flex'>
                                                <div className="all-center my-2">
                                                    <div className='p-rel mr-4'>
                                                        <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="avatar" />
                                                        <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                                                    </div>
                                                </div>
                                                <div className='w-100 d-flex justify-content-between'>
                                                    <div className="my-2 pl-2">
                                                        <p className="text-capitalize fs-16 f-fred">{data.name}</p>
                                                        {/* <p className="lh-18 fs-14 ms-500">Tutor from <span className='ms-900 text-capitalize'>{tutor.data.from}</span></p> */}
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block py-0 my-0 mr-1">
                                                                <ReactStars
                                                                    edit={false}
                                                                    value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                                                    count={5}
                                                                    size={15}
                                                                    isHalf={true}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    classNames="my-0 py-0"
                                                                    activeColor="#ffcb00"
                                                                />
                                                            </div>
                                                            <span className="tx-black ms-700 fs-14">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-end justify-content-end p-rel">
                                                        {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 fs-18 ms-500">New</p>}
                                                        <span className="p-2">

                                                            <img width="25px" height="25px" className="rounded-circle" src={data.flag_uri}
                                                                alt="flag" />

                                                        </span>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className='mt-2 fs-14 py-2'>
                                                <p className='ms-700'>{t('tutorDash.calendar.scheduleOn')}</p>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ms-900'>{moment(data.date).format('DD MMMM, YYYY')} at {moment(data.start_time, 'hh:mm').format('hh : mm A')}</p>
                                                    <span className="px-1 py-1 fs-10 returnCall-btn f-fred ">{data.status}</span>
                                                </div>

                                                <div>
                                                    <hr className='my-1' />
                                                    {
                                                        <>
                                                            {helper.isUserCanJoinClass({ classDate: data.date, startTime: data.start_time, endTime: data.end_time }) &&
                                                                <span className="px-2 py-1 fs-14 text-white my-2 d-inline-block join-class-btn mr-3 ms-700" onClick={() => history.push(`/user/class-room/${data.channel_name}`)}>{t('button.joinClass')} CLASS</span>}

                                                            {(((moment(data.date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) && (
                                                                moment(data.start_time, 'HH:mm').format('HH:mm') > moment().format("HH:mm")
                                                            ))
                                                                || (moment(data.date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")))
                                                                &&
                                                                <span className="px-2 py-1 fs-14 text-white my-2 d-inline-block cancel-class-btn ms-700" onClick={() => setState({ ...state, cancel: true, data: data })}>CANCEL CLASS</span>}
                                                        </>
                                                    }
                                                </div>
                                            </section>


                                        </div>
                                    )
                                }

                            </section>
                            :
                            <section>
                                <CancelClass
                                    data={state.data}
                                    cb={() => { onClose(); cb(); setState({ ...state, cancel: false }) }}
                                    onClose={() => { setState({ ...state, cancel: false }) }}
                                />
                            </section>

                        }
                    </div>
                </div>
            }
        </>
    )
}
