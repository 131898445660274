import React from 'react'
import { Route, Redirect } from "react-router-dom";
import helper from '../helper/index';
export default function StudentPrivateRoute({ component: Component, restricted, ...rest }) {
    return (
        <Route {...rest} render={props => (
            helper.isLoggedIn().res && helper.isLoggedIn().role === "user" && restricted ?
                <Component {...props} />
                :
                <Redirect to='/' />
        )} />
    )
}


