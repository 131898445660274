/* eslint-disable */
import user from '../controller/user'
import { useState, useRef, useEffect } from 'react'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import AlertPopUp from '../component/AlertPopUp'
import ChangePasswordInput from '../component/ChangePasswordInput'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getCountryList } from '../controller/common'

const UploadProfilePic = ({ open, close, image, cb }) => {

    const outSide = useRef()
    const [state, setState] = useState({
        backdrop: false
    })
    const upload = async () => {
        setState({ ...state, backdrop: true })
        const form = new FormData()
        form.append('studentId', helper.getid())
        form.append('file', image)
        await user.changePic(form).then((res) => {
            if (res.data.status) {
                cb(res.data.result)
            }
            setState({ ...state, backdrop: false })
        })
    }
    return (
        <>
            {open &&
                <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                    <div className="px-3 popup-modal-body  py-3 sm" >
                        <h5 className="ms-900 mb-0 tx-lt-black">Change <span className="tx-green">Profile Picture</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h5>
                        <div className='mt-3'>
                            <img className='d-block mx-auto rounded-circle' height="150px" width='150px' src={image ? URL.createObjectURL(image) : null} alt="pic" />
                        </div>
                        <button className='btn btn-login mb-3 mx-auto d-block my-3' style={{ width: '200px' }} onClick={upload}>UPLOAD PROFILE PIC</button>
                    </div>
                </div>
            }
            {state.backdrop && <Backdrop />}
        </>
    )
}

const EditDetails = ({ open, close, cb, data }) => {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const outSide = useRef()
    const [state, setState] = useState({
        backdrop: false,
        countryList: [],
        timeZoneList: [],
        country: '',
        timeZone: ''

    })
    const [goalType, setGoalType] = useState(1)
    const [selfAccount, setSelfAccount] = useState(true)
    const [level, setLevel] = useState(1)
    const profileForm = useRef()


    useEffect(() => {
        getCountry('')
    }, [])

    const upload = async () => {

        const form = new FormData(profileForm.current)

        form.append('studentId', helper.getid())
        form.append('goalType', parseInt(goalType))
        form.append('levelType', parseInt(level))
        form.append('selfAccount', selfAccount)

        const reqData = {
            studentId: parseInt(helper.getid()),
            goalType: parseInt(goalType),
            levelType: parseInt(level),
            selfAccount: selfAccount,
            name: form.get('name'),
            country: state.country,
            email: form.get('email'),
            timeZone: form.get('timeZone1').split("#")[0],
            time_zone_city: form.get('timeZone1').split("#")[1],
        }

        if (!reqData.name) {
            return helper.showMessage("Please enter name", "error")
        }

        setState({ ...state, backdrop: true })
        await user.editProfile(reqData).then((res) => {
            if (res.data.status) {
                cb(res.data.result)
            }
            setState({ ...state, backdrop: false })
        })
    }

    const getCountry = async (countryName) => {
        let code = ""
        state.countryList.forEach((data) => {
            if (data.country_name === countryName) {
                code = data.country_code
                setState({ ...state, timeZoneList: data.timezone, country: countryName })
            }
        })
        if (!code) {
            await getCountryList(code).then((res) => {
                if (res.data.status) {
                    let timeZone = []
                    res.data.result.forEach((result) => {
                        if (data && result.country_name === data.country) {
                            timeZone = result.timezone
                        }
                    })
                    setState({ ...state, countryList: res.data.result, timeZoneList: timeZone })
                }
            })
        }
    }


    return (
        <>
            {open &&
                <div className={`all-center tutor-dash popup-modal`} ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                    <div className={`left-popup hide-scroll p-fix tp-0 rt-0 p-5 sm ${reduxState.language === 'ar' && "text-right"}`}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-8 col-10">
                                <h4 className="f-fred mb-0 tx-lt-black">{t('userSidebar.profile.edit')} <span className="tx-green">{t('userSidebar.profile.profile')}</span></h4>
                                <p className='mb-3 sub-heading'>{t('userSidebar.profile.editDet')}</p>
                            </div>
                            <div className="col-lg-4 col-2 d-flex justify-content-end tx-grey pointer"><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></div>
                        </div>
                        <form ref={profileForm} onSubmit={(e) => { e.preventDefault(); upload() }}>
                            <div className=" mb-2 ">
                                <input pattern='[a-zA-Z ,.-]*' title='Name only contains a-z and special symbol (,.-).' type="text" maxLength={30} name="name" onChange={(e) => setState({ ...state, name: e.target.value })} className="d-block ms-700 px-2 w-100 input-box" placeholder={t('userSidebar.profile.name')} defaultValue={data && data.name} />
                            </div>
                            <div className="mb-2 ">
                                {state.countryList.length > 0 && <select onChange={(e) => { getCountry(e.target.value) }} className="input-box w-100 text-upper ms-700" defaultValue={data && data.country} name="country" >
                                    {state.countryList.map((data) => <option key={data.country_name} value={data.country_name}>{data.country_name}</option>)}
                                </select>}
                            </div>
                            <div className='mb-2'>
                                {state.timeZoneList.length > 0 && <select onChange={(e) => setState({ ...state, timeZone: e.target.value })}  className='input-box w-100 text-upper ms-700' name="timeZone1">
                                    <option value="">{t('tutorSidebar.generalInfo.selectTimeZone')}</option>
                                    {state.timeZoneList.map((timezone) => <option selected={data.timeZone + "#" + data.time_zone_city === timezone.timeZone + "#" + timezone.city ? true  :false} value={timezone.timeZone + "#" + timezone.city}>{timezone.city + " (" + timezone.timeZone + " UTC)"}</option>)}
                                </select>
                                }
                            </div>

                            <div className="mb-2">
                                <input type="text" maxLength={40} name="email" readOnly onChange={(e) => setState({ ...state, email: e.target.value })} className="d-block px-2 ms-700 w-100 input-box" placeholder="Your email" defaultValue={data ? data.email : helper.getEmail()} />
                            </div>
                            <section className='mb-2'>
                                <select className="input-box w-100  ms-700" onChange={(e) => setLevel(e.target.value)} defaultValue={data && data.levelType}>
                                    <option value={1}>{t('general.level.basic')}</option>
                                    <option value={2}>{t('general.level.intermediate')}</option>
                                    <option value={3}>{t('general.level.advanced')}</option>
                                </select>
                            </section>
                            <section className="mb-2  ms-700">
                                <p className='mt-2 ms-900 tx-lt-grey'>{t('userSidebar.profile.goalType')}</p>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="professional" name="goal" className="mb-3 mr-2" defaultChecked={data && data.goalType === 1 && true} />
                                    <label htmlFor="professional" className="pointer fs-12" onClick={() => setGoalType(1)}><span className='mx-2'>{t('userOnBoard.littleMore.goal.pd')}</span></label>
                                </div>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="acadmic" name="goal" className="mb-3 mr-2" defaultChecked={data && data.goalType === 2 && true} />
                                    <label htmlFor="acadmic" className="pointer fs-12" onClick={() => setGoalType(2)}><span className='mx-2'>{t('userOnBoard.littleMore.goal.accad')}</span></label>
                                </div>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="travel" name="goal" className="mb-3 mr-2" defaultChecked={data && data.goalType === 3 && true} />
                                    <label htmlFor="travel" className="pointer fs-12" onClick={() => setGoalType(3)}><span className='mx-2'>{t('userOnBoard.littleMore.goal.travel')}</span></label>
                                </div>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="personal" name="goal" className="mb-3 mr-2" defaultChecked={data && data.goalType === 4 && true} />
                                    <label htmlFor="personal" className="pointer fs-12" onClick={() => setGoalType(4)}><span className='mx-2'>{t('userOnBoard.littleMore.goal.pg')}</span></label>
                                </div>
                            </section>

                            <section className="mb-2  ms-700">
                                <p className='mt-2 ms-900 tx-lt-grey'>{t('userSidebar.profile.account')}</p>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="acc-myself" name="accountFor" className="mb-3 mr-2" defaultChecked={data && data.selfAccount} />
                                    <label htmlFor="acc-myself" className="pointer fs-12" onClick={() => setSelfAccount(true)}><span className='mx-2'>{t('userOnBoard.littleMore.accountFor.myself')}</span></label>
                                </div>
                                <div className={`${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} mb-2 d-block`}>
                                    <input type="radio" id="acc-kid" name="accountFor" className="mb-3 mr-2" defaultChecked={data && !data.selfAccount} />
                                    <label htmlFor="acc-kid" className="pointer fs-12" onClick={() => setSelfAccount(false)}><span className='mx-2'>{t('userOnBoard.littleMore.accountFor.Kids')}</span></label>
                                </div>

                            </section>
                            <button type='submit' className='btn btn-login  mb-3 d-block my-3' style={{ width: '200px' }}>{t('userSidebar.profile.upload')}</button>
                        </form>
                    </div>
                </div >
            }
            {state.backdrop && <Backdrop />}
        </>
    )
}

const ChangePassword = ({ open, close, }) => {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const outSide = useRef()
    const [state, setState] = useState({
        backdrop: false,
        error: null,
        open: false,
        msg: '',
        type: 'ok'
    })

    const profileForm = useRef()


    const upload = async () => {
        const form = new FormData(profileForm.current)
        form.append('studentId', helper.getid())
        let res = helper.validate("password", form.get('oldPassword'))
        if (!res.isValid) {
            setState({ ...state, error: res.msg })
            return
        }
        res = helper.validate("password", form.get('newPassword'))
        if (!res.isValid) {
            setState({ ...state, error: res.msg })
            return
        }
        if (form.get('newPassword') !== form.get('confirmNewPassword')) {
            setState({ ...state, error: "password mismatch" })
            return
        }

        setState({ ...state, backdrop: true })
        await user.changePassword(form).then((res) => {
            if (res.data.status) {
                setState({ ...state, type: 'ok', open: true, msg: "Password successfully changed", backdrop: false })
            }
            else {
                setState({ ...state, type: 'fail', open: true, msg: res.data.error.message, backdrop: false })
            }
        })
    }
    return (
        <>
            {open &&
                <div className={`all-center tutor-dash popup-modal ${reduxState.language === 'ar' && "text-right"}`} ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                    <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-8 col-10">
                                <h4 className="f-fred mb-0">{t('userSidebar.password.change')} <span className="tx-green">{t('userSidebar.password.password')}</span></h4>
                                <p className='sub-heading mb-4'>{t('userSidebar.password.note')}</p>
                            </div>
                            <div className="col-lg-4 col-2 d-flex justify-content-end tx-grey pointer"><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></div>
                        </div>
                        <form ref={profileForm} onSubmit={(e) => { e.preventDefault(); upload() }}>
                            <div className=" mb-2 ">
                                <ChangePasswordInput placeholder={t('userSidebar.password.oldPass')} name="oldPassword" />
                            </div>

                            <div className="mb-2 ">
                                <ChangePasswordInput placeholder={t('userSidebar.password.newPass')} name="newPassword" />
                            </div>
                            <div className="mb-2 ">
                                <ChangePasswordInput placeholder={t('userSidebar.password.confPass')} name="confirmNewPassword" />
                            </div>
                            <p className='my-2 ms-500 tx-error text-center'>{state.error}</p>
                            <button type='submit' className='btn btn-login mb-3 d-block my-3' style={{ width: '250px' }}>{t('userSidebar.password.changePass')}</button>

                        </form>
                    </div>
                </div>
            }
            {state.backdrop && <Backdrop />}

            <AlertPopUp
                open={state.open}
                type={state.type}
                msg={state.msg}
                onClose={() => { setState({ ...state, open: false }); close() }}
            />
        </>
    )
}

const EditBankDetails = ({ open, close, cb, data }) => {
    const outSide = useRef()
    const [state, setState] = useState({
        backdrop: false,
        error: ''
    })
    const profileForm = useRef()
    const upload = async () => {
        const form = new FormData(profileForm.current)
        form.append('student_id', helper.getid())

        if (!form.get('name_on_card')) {
            setState({ ...state, error: 'please enter name' })
            return
        }
        else if (!form.get('card_number')) {
            setState({ ...state, error: 'please enter card number' })
            return
        }
        else if (form.get('card_number').length != 16) {
            setState({ ...state, error: 'card number should be 16 digit' })
            return
        }
        else if (!form.get('expiry_date')) {
            setState({ ...state, error: 'please enter expiry date' })
            return
        }
        setState({ ...state, backdrop: true })
        await user.editBankInfo(form).then((res) => {
            if (res.data.status) {

                cb(res.data.result)
            }
            setState({ ...state, backdrop: false })
        })
    }
    return (
        <>
            {open &&
                <div className="all-center tutor-dash popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                    <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                        <h4 className="f-fred fs-24 tx-lt-black">Edit <span className="tx-green">Bank Details</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                        <p className='sub-heading mb-3'>edit details</p>
                        <form ref={profileForm} onSubmit={(e) => { e.preventDefault(); upload() }}>
                            <div className=" mb-2">
                                <input maxLength={50} type="text" name="name_on_card" onChange={(e) => setState({ ...state, name: e.target.value })} className="d-block ms-700 px-2 w-100 input-box" placeholder="Your Name on card" defaultValue={data && data.name_on_card} />
                            </div>
                            <div className="mb-2 ">
                                <input maxLength={16} type="text" name="card_number" onChange={(e) => setState({ ...state, cardNo: e.target.value })} className="d-block px-2 ms-700 w-100 input-box" placeholder="Your card number" defaultValue={data && data.card_number} />
                            </div>
                            <div className="mb-2">
                                <input type="month" name="expiry_date" onChange={(e) => setState({ ...state, expDate: e.target.value })} className="d-block px-2 ms-700 w-100 input-box" placeholder="Expiry date" defaultValue={data && moment(data.expiry_date).format('YYYY-MM')} />
                            </div>
                            <p className='tx-error my-2 text-center ms-500'>{state.error}</p>
                            <button type='submit' className='btn btn-login mb-3  my-3' style={{ width: '200px' }}>UPLOAD BANK INFO</button>

                        </form>
                    </div>
                </div>
            }
            {state.backdrop && <Backdrop />}
        </>
    )
}

export default {
    UploadProfilePic, EditDetails, ChangePassword, EditBankDetails
}