/* eslint-disable */
import React, { useState, useEffect } from 'react'
import HowToVideosCard from '../component/HowToVideosCard'
import helper from '../helper'
import { useSelector } from 'react-redux'

export default function PlayVideos({ cb, data, id, category }) {
    const reduxState = useSelector(state => state)
    const [relativeVideo, setRelativeVideo] = useState([])
    const [video, setVideo] = useState({ title: '', link: '' })

    useEffect(() => {
        let list = []
        data.forEach(videoData => {
            if (videoData.id === id) {
                setVideo({ ...video, title: videoData.title, link: videoData.video_link })
            }
            if (videoData.category === category && videoData.id !== id) {
                list.push(videoData)
            }
        });
        setRelativeVideo(list)
        return () => { }
    }, [])

    const setNewVideo = (newId) => {
        let list = []
        data.forEach(videoData => {
            if (videoData.id === newId) {
                setVideo({ ...video, title: videoData.title, link: videoData.video_link })
            }
            if (videoData.category === category && videoData.id !== newId) {
                list.push(videoData)
            }
        });
        setRelativeVideo(list)
    }



    return (
        <div className={`how-to-videos tx-lt-grey ${reduxState.language === 'ar' && "text-right"}`}>
            <div className="row mb-2">
                <div className="col-lg-12 d-flex align-items-center">
                    <button className="btn back-btn py-1 fs-14 f-fred ls px-3 mr-2" onClick={() => cb()}>Back</button>
                    <div className='mx-2'>
                        <p className="lh-18 f-fred fs-18">{video.title} <span className="tx-green">Videos</span></p>
                        <p className="sub-heading">{helper.getRoleType() === "user" ? 'Student' : 'Tutor'} support</p>
                    </div>
                </div>
            </div>


            <section className="br-2 mx-auto my-4" style={{ width: '500px' }}>
                <video className="w-100 br-2" src={video.link} controls></video>
            </section>

            {relativeVideo.length !== 0 &&
                <section className="relative-video">
                    <h4 className="ms-900 my-3">Related videos</h4>

                    <div className="row gx-0 mx-0">
                        {
                            relativeVideo.map((relVideo, index) => {
                                return (

                                    <div key={`course+${index}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2"
                                        onClick={() => setNewVideo(relVideo.id)}
                                    >
                                        <HowToVideosCard
                                            img={relVideo.featured_image_link}
                                            title={relVideo.title}
                                        />
                                    </div>

                                )
                            })
                        }


                    </div>


                </section>
            }
        </div>
    )
}
