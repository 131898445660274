import React from 'react'

export default function TimeSlots({ time, status,bgClass }) {
    return (
        <div className={`${bgClass} all-center py-3 flex-column`}>
            <p className="fs-20 ms-900">{time}</p>
            <p className="fs-16 ms-500 text-capitalize">{status}</p>
        </div>
    )
}
