/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL
const getCouponDetails = (code) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/coupon-code-details?coupon_code=${code}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export default {
getCouponDetails
}