import React, { useEffect } from 'react'
import '../static/css/tutordash.css'
import MyProfile from './MyProfile'
import Support from './Support'
import TutorHome from './TutorHome'
import TutorLibrary from './TutorLibrary'
import TutorNotify from './TutorNotify'
import logo from '../static/images/Logo.svg'
import tutorLogo from '../static/images/tutor.svg'
import Payout from './Payout'
import MyStudents from './MyStudents'
import Mychat from './Mychat'
import helper from '../helper/index'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import About from '../legalpages/About'
import Privacypolicy from '../legalpages/Privacypolicy'
import TermsCondition from '../legalpages/TermsCondition'
import parot from '../static/images/logo-parot.svg'
import Header from './Header'
import MyCalendar from './MyCalendar'
import Attendance from './Attendance'
import OpenTickets from '../support/OpenTickets'
import Faqs from './Faqs'
import HowToVideo from '../support/HowToVideo'
import ArticleListing from '../support/ArticleListing'
import CourseDetails from './CourseDetails'
import SvgChat from '../Icons/Chat'
import SvgDashboard from '../Icons/Dashboard'
import SvgStudent from '../Icons/Student'
import SvgLibrary from '../Icons/Library'
import SvgSupport from '../Icons/Support'
import SvgLogout from '../Icons/Logout'
import SvgStudents from '../Icons/Students'
import SvgPayout from '../Icons/Payout'
import { FaPortrait } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
import { Tooltip } from '@mui/material'
let intervalResp;
export default function TutorDash() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const history = useHistory()
    const { page, id } = useParams()
    useEffect(() => {
        let status = helper.getProfileStatus();
        if (status === "Pending" || status === "Uploaded") {
            history.push(`/tutor/signupcheck`)
        }
        else {
            intervalResp = setInterval(() => {
                const resp = helper.checkSecurity("tutor")
                if (!resp.res) {
                    clearInterval(intervalResp)
                    history.push('/tutor/login')
                }
            }, 2000)
        }

        return () => { try { clearInterval(intervalResp) } catch { } }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="tutor-dash userdash">
            <input type="checkbox" className='d-none' id="web-sideMenu" />
            <table className='w-100 my-0 py-0' >
                <tr className='my-0 py-0'>
                    <td className='my-0 py-0  left-icon-sm-strip left-dash hide-scroll'>
                        <div className="hide-scroll">
                            <div className="all-center overlay-cover my-2 mx-2">
                                <img width="40px" className="pointer" onClick={() => history.push('/')} src={parot} alt="parrot" />
                                <div className="p-abs overlay-text px-2 py-1" >
                                    SayDhad
                                </div>
                            </div>
                            <ul className="mt-3 list-unstyled">
                                <li onClick={() => history.push('/tutor/dashboard/home')} className={`${page === "home" && "active-tab-sm"}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option1')} >
                                        <p className="f-fred text-upper">  <SvgDashboard className="active-icon-color" /> </p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/calendar')} className={`${page === "calendar" && "active-tab-sm"}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option2')} >
                                        <p className="f-fred text-upper"> <SvgStudent className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/library')} className={`${page === "library" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option3')} >
                                        <p className="f-fred text-upper"> <SvgLibrary className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/mystudent')} className={`${page === "mystudent" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option4')} >
                                        <p className="f-fred text-upper "><SvgStudents className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/mychat')} className={`${page === "mychat" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option5')} >
                                        <p className="f-fred text-upper"><SvgChat className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/payout')} className={`${page === "payout" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option6')} >
                                        <p className="f-fred text-upper">  <SvgPayout className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>
                                <li onClick={() => { history.push('/tutor/dashboard/attendance') }} className={`${page === "attendance" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option7')} >
                                        <p className="f-fred text-upper"> <FaPortrait size={24} className="active-icon-color" /></p>
                                    </Tooltip>

                                </li>
                                <li onClick={() => history.push('/tutor/dashboard/support')} className={`${page === "support" && "active-tab-sm "}`}>
                                    <Tooltip title={t('tutorDash.sidebar.option8')} >
                                        <p className="f-fred text-upper"> <SvgSupport className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>

                                <li onClick={() => { helper.logOut(); helper.showMessage(t('messages.auth.logout'), "success"); history.push('/tutor/login'); }}>
                                    <Tooltip title={t('tutorDash.sidebar.option9')} >
                                        <p className="f-fred text-upper">  <SvgLogout className="active-icon-color" /></p>
                                    </Tooltip>
                                </li>

                            </ul>

                        </div>
                    </td>
                    <td className='left-dash left-icon-lg-strip  hide-scroll'>
                        <div className='d-flex flex-column justify-content-between px-2 hide-scroll'>
                            <div>
                                <div className="all-center pb-4 pt-3 p-sticky bg-white tp-0" style={{ zIndex: '1' }}>
                                    <img width="190px" height={'68px'} onClick={() => history.push('/')} src={logo} alt="logo" />
                                    <img width="25px" className='p-abs rt-0' style={{ bottom: '-10px' }} onClick={() => history.push('/')} src={tutorLogo} alt="tutor" />
                                </div>
                                <ul className="mt-3">
                                    <li id="step-td-dash" onClick={() => { history.push('/tutor/dashboard/home'); }} className={`${page === "home" && "active-tab "}`}>
                                        <div className="f-fred text-upper  d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgDashboard className={`${page === "home" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p> {t('tutorDash.sidebar.option1')}</p>
                                        </div>
                                    </li>

                                    <li id="step-td-calendar" onClick={() => { history.push('/tutor/dashboard/calendar'); }} className={`${page === "calendar" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgStudent className={`${page === "calendar" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p> {t('tutorDash.sidebar.option2')}</p>
                                        </div>
                                    </li>
                                    <li id="step-td-course" onClick={() => { history.push('/tutor/dashboard/library'); }} className={`${page === "library" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgLibrary className={`${page === "library" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option3')}</p>
                                        </div>

                                    </li>
                                    <li id="step-td-student" onClick={() => { history.push('/tutor/dashboard/mystudent'); }} className={`${page === "mystudent" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgStudents className={`${page === "mystudent" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option4')}</p>
                                        </div>
                                    </li>
                                    <li id="step-td-chat" onClick={() => { history.push('/tutor/dashboard/mychat'); }} className={`${page === "mychat" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgChat className={`${page === "mychat" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option5')}</p>
                                        </div>
                                    </li>
                                    <li id="step-td-payout" onClick={() => { history.push('/tutor/dashboard/payout'); }} className={`${page === "payout" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgPayout className={`${page === "payout" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option6')}</p>
                                        </div>

                                    </li>
                                    <li id="step-td-attendance" onClick={() => { history.push('/tutor/dashboard/attendance') }} className={`${page === "attendance" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width'>
                                                <FaPortrait size={24} className={`${page === "attendance" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                {/* <i className={`fas fa-list-alt  fs-24 ${page === "attendance" ? "active-icon-color" : 'inactive-icon-color'}`}></i> */}
                                            </span>
                                            <p>{t('tutorDash.sidebar.option7')}</p>
                                        </div>

                                    </li>
                                    <li id="step-td-support" onClick={() => { history.push('/tutor/dashboard/support'); }} className={`${page === "support" && "active-tab "}`}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width'>
                                                <SvgSupport className={`${page === "support" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option8')}</p>
                                        </div>
                                    </li>

                                    <li onClick={() => { helper.logOut(); helper.showMessage(t('messages.auth.logout'), "success"); history.push('/tutor/login'); }}>
                                        <div className="f-fred text-upper d-flex align-items-center">
                                            <span className='icon-width d-flex align-items-center'>
                                                <SvgLogout className={`inactive-icon-color`} />
                                            </span>
                                            <p>{t('tutorDash.sidebar.option9')}</p>
                                        </div> </li>

                                </ul>
                            </div>

                            <div className={`ms-700 fs-14 ml-3 mt-5 ${storeData.language === 'ar' && "text-right"}`}>
                                <Link to="/about-us" className="d-block"><span className='link-hover'>{t('tutorDash.footer.about')}</span></Link>
                                <Link to="/tutor/dashboard/privacy-policy" className="d-block"><span className='link-hover'>{t('tutorDash.footer.privacy')}</span></Link>
                                <Link to="/tutor/dashboard/terms-condition" className="d-block"><span className='link-hover'>{t('tutorDash.footer.terms')}</span></Link>
                                <p className="mt-3 fs-12 mb-2">{t('tutorDash.footer.rights')}</p>
                            </div>
                        </div>
                    </td>

                    <td className='w-100 my-0 py-0' >
                        <div className="right-dash bg-white hide-scroll pb-3 px-2 mx-0">
                            <Header />
                            {
                                page === "home" ? <TutorHome />
                                    : page === "calendar" ? <MyCalendar />
                                        : page === "library" && id ? <CourseDetails />
                                            : page === "library" ? <TutorLibrary />
                                                : page === "mystudent" ? <MyStudents />
                                                    : page === "mychat" ? <Mychat />
                                                        : page === "payout" ? <Payout />
                                                            : page === "support" && id === "ticket" ? <OpenTickets /> :
                                                                page === "support" && id === "faq" ? <Faqs /> :
                                                                    page === "support" && id === "how-to-videos" ? <HowToVideo /> :
                                                                        page === "support" && id === "articles" ? <ArticleListing /> :
                                                                            page === "support" ? <Support /> :

                                                                                page === "notification" ? <TutorNotify />
                                                                                    : page === "profile" ? <MyProfile />
                                                                                        : page === "about" ? <About />
                                                                                            : page === "privacy-policy" ? <Privacypolicy />
                                                                                                : page === "terms-condition" ? <TermsCondition />
                                                                                                    : page === "attendance" ? <Attendance />
                                                                                                        : ""
                            }

                        </div>
                    </td>
                </tr>
            </table>

        </div>
    )
}
