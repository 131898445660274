/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import '../static/css/subscription.css'
import calendar from '../static/images/calendar.png'
import timesquare from '../static/images/time-square.png'
import user from '../controller/user'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import parot from '../static/images/parrot-1.png'
import AlertPopUp from '../component/AlertPopUp'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import payment from '../controller/payment'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
const plans = {
    "Month": 1,
    "Quarter": 3,
    "Semester": 6,
    "Annual": 12
}

export default function Subscription() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const [resData, setResData] = useState([])
    const [tab, setTab] = useState(true)
    const [load, setLoad] = useState(true)
    const [state, setState] = useState({
        days_per_week_list: [],
        minutes_per_day_list: [],
    })
    const [calc, setCalc] = useState({
        "isSelectedPlanValid": false,
        "isUpgrade": false,
        "isAllowToUpgrade": true,
        "upgradeInfo": {
            "currentPlanPrice": 0,
            "currentPlanUtilizedPrice": 0,
            "currentPlanRemainingPrice": 0,
            "currencyPlanPrice": 0,
            "currencyUtilizedPrice": 0,
            "currencyRemainingPrice": 0
        },
        "netAmountToPay": 0, /* User country */
        "netAmountToPayInSAR": 0,
        "netAmount": 0 /* USD */
    })

    const [selectedPlan, setSelectedPlan] = useState({
        amount: 0, /* USD Amount */
        usdToSarAmount: 0, /* Amount to go for payment gatway */
        currencyAmount: 0, /* Amount to show user according */
        currency: "",
        type: "Month",
        id: 0,
        index: 0,
    })

    const [coupon, setCoupon] = useState({
        code: '',
        discount: 0,
        error: ''
    })
    const min = useRef()
    const week = useRef()

    useEffect(() => {
        getSubscriptionOffer()
    }, [])


    const getSubscriptionOffer = async () => {
        setLoad(true)
        await user.getSubscriptionOffers().then(async (res) => {
            if (res.data.status) {
                setState({ ...state, ...res.data.result })
                getPlanDetails(res.data.result)
            }
        })
    }

    const getPlanDetails = async (offerData) => {
        setLoad(true)
        setResData([])
        setSelectedPlan({
            ...selectedPlan,
            amount: 0,
            type: "Month",
            id: 0,
            index: 0
        })
        await user.getSubscription(
            min?.current?.value || offerData.days_per_week_list[0].days_per_week,
            week?.current?.value || offerData.minutes_per_day_list[0].minutes_per_day
        ).then(async (res) => {
            setLoad(false)
            if (res.data.status) {
                const sortedPlan = res.data.result.sort((a, b) => a.commitment_level === "Annual" ? 1 : a.commitment_level > b.commitment_level ? 1 : a.commitment_level < b.commitment_level ? -1 : 0)
                setResData(sortedPlan)
                setSelectedPlan({ ...selectedPlan, type: sortedPlan[0].commitment_level, id: sortedPlan[0].id, index: 0, amount: sortedPlan[0].amount, currency: sortedPlan[0].currency, currencyAmount: sortedPlan[0].currency_amount })
                getPlanCalc(sortedPlan[0].id)
            }
            setCoupon({ ...coupon, code: '', discount: 0 })
        }).catch((err) => {
            setLoad(false)
        })
    }

    const getPlanCalc = async (subscription_plan_id, couponCode = "") => {
        setLoad(true)
        await user.getSubscriptionCalc({
            subscription_plan_id,
            couponCode: couponCode
        }).then(async (res) => {
            setLoad(false)
            if (res.data.status) {
                if (!res?.data?.result?.isAllowToUpgrade) {
                    helper.showMessage("Please change your country to purchase the plan.")
                }
                setCalc({ ...res.data.result })
            }
        }).catch((err) => {
            setLoad(false)
        })
    }

    const getCouponDetail = async () => {
        if (!coupon.code) {
            return helper.showMessage("Please enter coupon code")
        }
        setLoad(true)
        await payment.getCouponDetails(coupon.code).then((res) => {
            console.log("coupon ,", res)
            setLoad(false)
            if (res.data.status) {
                getPlanCalc(selectedPlan.id, coupon.code)
                setCoupon({ ...coupon, code: coupon.code, discount: res.data.result.discount })
            } else {
                setCoupon({ ...coupon, code: coupon.code, discount: 0 })
                return helper.showMessage(res.data.error)
            }
        })
    }

    const getOriginalPrice = () => {
        const res = {
            perMonthAmount: selectedPlan.type === "Month" ? resData && resData[selectedPlan.index].amount : selectedPlan.type === "Quarter" ? resData && resData[selectedPlan.index].amount : resData && resData[selectedPlan.index].amount,
            totalAmount: selectedPlan.type === "Month" ? resData && resData[selectedPlan.index]?.currency_amount : selectedPlan.type === "Quarter" ? resData && resData[selectedPlan.index]?.currency_amount * 3 : selectedPlan.type === "Semester" ? resData && resData[selectedPlan.index]?.currency_amount * 6 : resData && resData[selectedPlan.index]?.currency_amount * 12,
            perMonthAmountInSAR: selectedPlan.type === "Month" ? resData && resData[selectedPlan.index].usd_to_sar_amount : selectedPlan.type === "Quarter" ? resData && resData[selectedPlan.index].usd_to_sar_amount : resData && resData[selectedPlan.index].usd_to_sar_amount,
            totalAmountInSAR: selectedPlan.type === "Month" ? resData && resData[selectedPlan.index].usd_to_sar_amount : selectedPlan.type === "Quarter" ? resData && resData[selectedPlan.index].usd_to_sar_amount * 3 : selectedPlan.type === "Semester" ? resData && resData[selectedPlan.index].usd_to_sar_amount * 6 : resData && resData[selectedPlan.index].usd_to_sar_amount * 12
        }
        return {
            perMonthAmount: parseFloat(res.perMonthAmount).toFixed(2),
            totalAmount: parseFloat(res.totalAmount).toFixed(2),
            perMonthAmountInSAR: parseFloat(res.perMonthAmountInSAR).toFixed(2),
            totalAmountInSAR: parseFloat(res.totalAmountInSAR).toFixed(2),
        }
    }

    return (
        <div className={`subscription ${reduxState.language === 'ar' && "text-right"}`} style={{ backgroundColor: 'whitesmoke' }}>
            <div className="row gx-0 mx-0">
                <div className="col-lg-10 mx-auto">
                    {tab ?
                        <div className='subscription-body row py-4 my-4'>
                            <div className="col-lg-6">
                                <h5 className='text-center my-4 ms-700'>{t('userDash.subscription.weeklyAgenda')}</h5>
                                <div className='d-flex align-items-center  px-4 mb-4'>
                                    <div>
                                        <img width={'45px'} src={calendar} className='mx-3' alt="calendar" />
                                    </div>
                                    <div className='br-1 w-100'>
                                        <select ref={min} className={`w-100  fs-20 ms-500 px-2`} onChange={getPlanDetails}>
                                            {state.minutes_per_day_list.map((data) => <option value={data.minutes_per_day}>{data.minutes_per_day} {t('userDash.subscription.minPerDay')}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center px-4 mb-4'>
                                    <div>
                                        <img width={'45px'} src={timesquare} className='mx-3' alt="calendar" />
                                    </div>
                                    <div className='br-1 w-100'>
                                        <select ref={week} className='w-100 fs-20 ms-500 px-2' onChange={getPlanDetails}>
                                            {state.days_per_week_list.map((data) => <option value={data.days_per_week}>{data.days_per_week} {t('userDash.subscription.dayPerWeek')}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4">
                                <h5 className='text-center my-4 ms-700'>{t('userDash.subscription.pickPlan')}</h5>
                                {resData.length > 0 &&
                                    <FormControl style={{ width: '100%', margin: '0 20px' }} component="fieldset">
                                        <RadioGroup
                                            style={{ width: '100%' }}
                                            name="plans"
                                            defaultValue={resData.length > 0 ? resData[0].commitment_level : "Month"}
                                        >
                                            {
                                                resData.map((plan, index) =>
                                                    <div
                                                        key={plan.id}
                                                        onClick={() => {
                                                            setSelectedPlan({
                                                                ...selectedPlan,
                                                                type: plan.commitment_level,
                                                                id: plan.id, index: index,
                                                                currencyAmount: plan.currency_amount,
                                                                amount: plan.amount
                                                            });
                                                            setCoupon({ ...coupon, code: '', discount: 0 })
                                                            getPlanCalc(plan.id)
                                                        }}
                                                        className={`subscription-plan p-rel w-100 ${selectedPlan.type === plan.commitment_level && "active-plan"}`}>
                                                        <FormControlLabel
                                                            value={plan.commitment_level}
                                                            control={<Radio color='success' />}
                                                            sx={{ width: '100%', display: "flex", justifyContent: "space-bedtween" }}
                                                            label={
                                                                <div className='mx-2 ms-700 my-2'>
                                                                    <p>{plan.commitment_level}</p>
                                                                    <p className='tx-grey fs-14'>{t('userDash.subscription.renewEvery')} {plan.renew_level} {t('userDash.subscription.month')}</p>
                                                                </div>
                                                            } />

                                                        <p className='tx-green ms-900 ltr'>{selectedPlan?.currency || "USD"} <span className='fs-30'>{parseFloat(plan?.currency_amount).toFixed(2)}</span>/mo.</p>
                                                    </div>)}
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </div>
                            {
                                resData.length > 0 &&
                                <section className="col-lg-8 col-11 mx-auto text-left">
                                    <h5 className='text-center ms-700 my-3'>{t('userDash.subscription.planSummary')}</h5>

                                    <p className='text-center ms-500 mb-3'>{min.current && min.current.value} {t('userDash.subscription.minPerDay')}, {week.current && week.current.value} {t('userDash.subscription.dayPerWeek')} | <span className="text-capitalize">{selectedPlan.type}</span></p>

                                    <div className='ms-700'>
                                        <div className='tx-lt-black'>
                                            {t('userDash.subscription.originalPrice')} ( * {plans[selectedPlan.type]} )
                                            <span className='float-right'>{selectedPlan?.currency || "USD"} {getOriginalPrice().totalAmount}</span>
                                        </div>
                                        <div className='clear'></div>

                                        {calc.isUpgrade && calc.isAllowToUpgrade && parseInt(calc?.upgradeInfo?.currencyPlanPrice) !== 0 &&
                                            <>
                                                <hr className='my-1' />
                                                <p className='tx-lt-black'>
                                                    {t('userDash.subscription.existPlanPrice')}
                                                    <span className='float-right'>{selectedPlan?.currency || "USD"} {parseFloat(calc?.upgradeInfo?.currencyPlanPrice).toFixed(2)}</span>
                                                </p>
                                                <p className='tx-lt-black'>
                                                    {t('userDash.subscription.utilizedPrice')}
                                                    <span className='float-right ltr'>- {selectedPlan?.currency || "USD"} {parseFloat(calc?.upgradeInfo?.currencyUtilizedPrice)?.toFixed(2)}</span>
                                                </p>
                                                <p className='tx-lt-black'>
                                                    {t('userDash.subscription.remainingPrice')}
                                                    <span className='float-right'>{selectedPlan?.currency || "USD"} {parseFloat(calc?.upgradeInfo?.currencyRemainingPrice)?.toFixed(2)}</span>
                                                </p>
                                            </>
                                        }
                                        <div className='clear'></div>

                                        {coupon.discount != 0 && <p className='tx-lt-black'>
                                            {t('userDash.subscription.discount')} {coupon.discount}%
                                            <span className='float-right'>- {selectedPlan?.currency || "USD"} {(getOriginalPrice().totalAmount * coupon.discount) / 100}</span>
                                        </p>}
                                        <div className='clear'></div>

                                        <hr className='my-1' />
                                        <div className='ms-700 my-2'>{t('userDash.subscription.totalCharges')}
                                            <span className='float-right'>{selectedPlan?.currency || "USD"} {parseFloat(calc.netAmountToPay).toFixed(2)}</span>
                                        </div>
                                    </div>

                                    <div className="alert fs-14 ms-500 py-2 my-3 px-2 d-flex align-items-center">
                                        <img width="40px" src={parot} alt="parrot" />
                                        <span className="ms-500 mx-2">
                                            <span className="ms-900">{t('messages.cancelAnyTime')}</span> {t('messages.noAskPolicy')}
                                        </span>
                                    </div>

                                    <section className=' my-3 all-center flex-column' >
                                        <div className='all-center promo-code' style={{ width: '300px' }}>
                                            <input type="text" onChange={(e) => setCoupon({ ...coupon, code: e.target.value })} className='d-inline-block mx-auto w-100 ms-700 p-2' placeholder={t('userDash.subscription.promoCode')} />
                                            <div className='bg-sky h-100 br-1 all-center'>
                                                <i className="fas pointer text-white fa-arrow-alt-circle-right fs-20 p-2 px-3" onClick={getCouponDetail}></i>
                                            </div>
                                        </div>
                                        {coupon.discount != 0 && <p className='ms-700 tx-lt-grey my-2'> {t('userDash.subscription.congrats')} {coupon.discount}% {t('userDash.subscription.off')}</p>}

                                    </section>

                                    <div >
                                        {calc.netAmountToPay > 0 && calc.isAllowToUpgrade ?
                                            <button className='btn btn-select-plan ms-700 d-block mx-auto' style={{ width: '300px', maxWidth: '100%' }} onClick={() => {
                                                setTab(false)
                                            }}>{t('button.selectPlan')}</button> :
                                            <button className='btn btn-disabled-select-plan pointer-none ms-700 d-block mx-auto' style={{ width: '300px', maxWidth: '100%' }} onClick={() => {
                                                alert("Please select another plan")
                                            }}>{t('button.selectPlan')}</button>
                                        }

                                    </div>
                                </section>
                            }

                        </div>
                        : <PlanDetail
                            week={week.current && week.current.value}
                            min={min.current && min.current.value}
                            currency={selectedPlan?.currency}

                            amount={parseFloat(selectedPlan.amount).toFixed(2)} /* Per month amount: USD */
                            originalPrice={parseFloat(calc.netAmount).toFixed(2)} /* Payable amount in user country : USD */

                            amountInSar={parseFloat(calc.netAmountToPayInSAR).toFixed(2)}
                            // originalPriceInSar={calc.netAmountToPayInSAR}
                            sar_to_currency_amount={parseFloat(calc.netAmountToPay).toFixed(2)}

                            type={selectedPlan.type}
                            id={selectedPlan.id}
                            promoCode={coupon.discount != 0 ? coupon.code : ''}
                            back={() => { setTab(true); getSubscriptionOffer() }}
                        />
                    }
                </div>
            </div>
            {load && <Backdrop />}
        </div>

    )
}

const PlanDetail = ({ type, min, week, back, id, promoCode, currency, amount, originalPrice, amountInSar, originalPriceInSar, sar_to_currency_amount }) => {
    const { t } = useTranslation();
    const [load, setLoad] = useState(false)
    const history = useHistory()
    const [state, setState] = useState({
        index: 1,
        type: 'ok',
        msg: '',
        open: false
    })

    const purchase = async () => {
        const reqData = {
            "user_id": helper.getid(),
            "subscription_date": moment().format('YYYY-MM-DD'),
            "subscription_plan_id": id,
            "subscription": "SayDhad Pro",
            "minutes_per_day": min,
            "days_per_week": week,
            "commitment_level": type,
            "amount": amount/* amount */, /* Per month amount: USD */
            "payment_channel_id": 1,
            "payable_type": "amazon",
            "payable_id": 1,
            "transection_id": helper.genCode(),
            "payee_name": helper.getName(),
            "payee_email": helper.getEmail(),
            "payee_mobile": "7818845501",
            "total_amount": originalPrice,/* originalPrice *//* Price need to pay amount: IN SAR*/
            "currency": "",
            "channel_invoice_id": "",
            "channel_order_id": "",
            "payment_link": "",
            "status": "",
            "message": "",
            "response": "",
            "coupon_code": promoCode,
            sar_to_currency_amount: sar_to_currency_amount /* sar_to_currency_amount */,
            conversion_currency: currency /* currency */
        }
        setLoad(true)
        await user.purchasePlan(reqData).then((res) => {
            if (res.data.status) {
                const { access_code, amount, language, merchant_identifier, merchant_reference, signature, return_url } = res.data.result
                const query = `currency=${currency}&amountInSar=${amountInSar}&amount=${amount}&access_code=${access_code}&language=${language}&merchant_identifier=${merchant_identifier}&merchant_reference=${merchant_reference}&signature=${signature}&return_url=${return_url}`

                const encodedQuery = helper.encrypt(query)
                history.push(`/checkout?q=${encodedQuery}`)
            }
            else {
                setState({ ...state, open: true, msg: res.data.error, type: 'fail' })
            }
            setLoad(false)
        })
    }
    return (
        <>
            <div className='subscription-body row py-4 my-4'>
                <div className="col-12 px-4 my-2 mb-4">
                    <button className='btn btn-login' style={{ width: '150px' }} onClick={back} >{t('button.backToPlan')}</button>
                </div>
                <div className="col-12 px-4">
                    <p className='ms-700 mb-2 fs-20'>{t('userDash.subscription.planSummary')}</p>
                    <p className='ms-700 mb-2'>{min} {t('userDash.subscription.min/day')}, {week} {t('userDash.subscription.day/week')} | <span className='text-capitalize'>{type}</span></p>
                    <p className='ms-900 my-3 fs-18'>{currency || "USD"} {sar_to_currency_amount}</p>
                </div>
                {/* <div className="col-12 px-4">
                    <h5 className='ms-700 my-4'>Select payment method</h5>
                </div> */}
                <div className="col-lg-6 col-md-6 col-12 mb-2 px-4">
                    <div className={`payment-gateway pointer py-3 px-3 ${state.index === 1 && "payment-gateway-active "} `} onClick={() => setState({ ...state, index: 1 })}>
                        <p className='ms-900 fs-18'>{t('userDash.subscription.creditCard')}</p>
                        <p className='ms-700 tx-grey'>{t('userDash.subscription.paymentEnabled')}</p>
                    </div>
                </div>
                {/* <div className="col-lg-6 col-md-6 col-12 mb-2 px-4">
                    <div className={`payment-gateway pointer py-3 px-3 ${state.index === 2 && "payment-gateway-active "}`} onClick={() => setState({ ...state, index: 2 })}>
                        <p className='ms-900 fs-18'>All debit/credit card</p>
                        <p className='ms-700 tx-grey'>Net banking,UPI,wallets,EMI,etc</p>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className='my-4'>
                        <button id="purchase-step" className={`btn btn-select-plan ms-700 d-block mx-auto`} style={{ width: '300px' }} onClick={purchase}>{t('button.purchasePlan')}</button>
                    </div>
                </div>
            </div>

            <AlertPopUp
                open={state.open}
                msg={state.msg}
                type={state.type}
                onClose={() => { setState({ ...state, open: false }); history.push('/user/dashboard/profile') }}
            />
            {load && <Backdrop />}
        </>
    )
}
