/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import fb from '../config/firebaseAuth';
import helper from '../helper/index';
import { ref, set, push, onChildAdded, increment } from "firebase/database";
import Slide from 'react-reveal/Slide';
import moment from 'moment';
import { FaTelegramPlane, FaArrowLeft } from 'react-icons/fa';
import notification from '../controller/notification';
import { useTranslation } from "react-i18next";

const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let unsubscribe = ''
const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-body-mobile")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}
const addRemoteDiv = (message) => {
    try {
        const remoteDiv = document.getElementById("chat-body-mobile")
        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}
const enable = () => {
    unsubscribe = onChildAdded(ref(db, `userChat/${rootRoomid}/messages`), (data, index) => {
        const val = data.val()
        if (val.createdBy == helper.getid()) {

            adduserDiv(val.message, val.createdAt)
        }
        else {
            addRemoteDiv(val.message)
        }
    })
    if (helper.getRoleType() == "user") {
        set(ref(db, `userChat/${rootRoomid}/count`), {
            user: 0,
            tutor: increment(0)
        })
    } else {
        set(ref(db, `userChat/${rootRoomid}/count`), {
            user: increment(0),
            tutor: 0
        })
    }

}


export default function ChatOnMobile({ myId, roomId, userName, cb, remoteUserId }) {
    const { t } = useTranslation();
    let msg = useRef()
    useEffect(() => {
        try {
            const userDiv = document.getElementById("chat-body-mobile")
            userDiv.innerHTML = ""
            rootRoomid = roomId
        }
        catch { }
        enable()
        return () => {
            try {
                unsubscribe()
            }
            catch { }
        }
    }, [roomId])


    const pushMessages = async () => {
        const date = new Date()
        if (msg.current.value) {
            push(ref(db, `userChat/${roomId}/messages`), {
                message: msg.current.value,
                createdBy: myId,
                createdAt: moment(date).format('YYYY-MM-DD hh:mm:ss A')
            });
            msg.current.value = ""
            if (helper.getRoleType() == "user") {
                set(ref(db, `userChat/${roomId}/count`), {
                    user: 0,
                    tutor: increment(1)
                })
            } else {
                set(ref(db, `userChat/${roomId}/count`), {
                    user: increment(1),
                    tutor: 0
                })
            }
            await notification.pushChatNotification({ senderId: myId, receiverId: remoteUserId })

        }
    }


    return (
        <div className="chat-box p-fix tp-0 lt-0 h-100 mx-auto px-0 w-100 d-lg-none d-md-none" style={{ zIndex: '4' }}>
            <Slide left>
                <div className='d-flex justify-content-between flex-column' style={{ height: '100vh' }}>
                    <div className="chat-header  py-3 px-2 br-1">
                        <div className="row gx-0 mx-0">
                            <div className="col-1">
                                <FaArrowLeft size={24} onClick={cb} className='inactive-icon-color' />
                            </div>

                            <div className="col-10">
                                <p className="ms-900 fs-18 tx-lt-black">{userName ? userName : "Anonymous"}</p>

                            </div>
                        </div>

                    </div>

                    <div id="chat-body-mobile" className="chat-room-body px-2  chat-body h-100">

                    </div>
                    <section className='chat-footer-container'>
                        < form onSubmit={(e) => { e.preventDefault(); pushMessages() }}>
                            <div className="chat-footer  py-2 px-2 d-flex mx-2 align-items-center">
                                <input type="text" ref={msg} placeholder={t('tutorDash.chat.typeMsg')} className="chat-input-box w-100 h-100 ms-700" />
                                <button type="submit" className="btn chat-send pointer br-1 all-center"><FaTelegramPlane className='fs-30 text-white' /></button>
                            </div>
                        </form >
                        <section className='w-100' style={{ height: '60px' }}>

                        </section>
                    </section>

                </div>
            </Slide>
        </div>
    )
}
