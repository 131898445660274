/* eslint-disable */
import React, { useRef, useState } from 'react'
import eye from '../static/images/eye.svg'
import { FaEyeSlash } from 'react-icons/fa'
export default function ChangePasswordInput({placeholder,name}) {
   
    const passRef = useRef()
    const [show, setshow] = useState(false)
    const toggle = () => {
        if (passRef.current.type == "password") {
            setshow(true)
            passRef.current.type = "text"
        } else {
            setshow(false)
            passRef.current.type = "password"
        }
    }
    return (
        <div className="input-box p-rel d-flex w-100 align-items-center mb-1">
            <input maxLength={30} ref={passRef} type="password" name={name} placeholder={placeholder} className="px-2 input-box w-100 py-1 ms-700" />
            {
                show ?
                    <img className='p-abs rt-0 mr-2 pointer' onClick={toggle} src={eye} alt="eye" />
                    : <FaEyeSlash className='p-abs rt-0 mr-2  pointer' style={{color:'#159D18'}} onClick={toggle} />}
        </div>
    )
}
