import React, { useState } from 'react'
export default function ReadMore({ text, initialNoOfWords }) {
    const [show, setShow] = useState(false)
    return (
       <>
        <p className="fs-14 ms-500 text-justify mr-3">
            {!show && text && text.split(' ').length > initialNoOfWords ? text.split(' ').slice(0,initialNoOfWords).join(" ") + " ..." : text}
        </p>
         {text.split(' ').length > initialNoOfWords  && <p className="fs-12 pointer ms-700 tx-red" onClick={()=>setShow(!show)}>{show ? "Show less" : "Read more"}</p> }
       </>
    )
}
