import React from 'react';
import happy from '../static/images/Logo.svg'
import Slide from 'react-reveal/Slide';
import { FaComment } from 'react-icons/fa';
export default function Instruction({ open, onClose }) {
    return (
        <>
            {open &&
                <div className="popup-modal ltr instruction h-100 all-center">
                    <Slide bottom>
                        <div className="popup-modal-body sm">
                            <div className='mt-3 mb-2'>
                                <img className='pb-2 d-block mx-auto' style={{ width: '200px' }} src={happy} alt="parrot" />
                            </div>
                            <p className='ms-900 text-center fs-20 my-2 tx-green'>Instruction</p>
                            <div className="row gx mx-0 px-4">
                                <div className="col-6">
                                    <div className='my-2 d-flex  align-items-center'>
                                        <i className='fas fs-20 fa-microphone video-icon'></i>
                                        <p className='ms-900'>Mic on</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='my-2 d-flex  align-items-center'>
                                        <i className='fas  fa-microphone-slash video-icon'></i>
                                        <p className='ms-900'>Mute</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='my-2 d-flex  align-items-center'>
                                        <i className='fas fs-20 fa-expand-arrows-alt video-icon'></i>
                                        <p className='ms-900'>FullScreen</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='my-2 d-flex align-items-center'>
                                        <i className='fas fs-20 fa-phone-square-alt video-icon'></i>
                                        <p className='ms-900'>End Call</p>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className=' my-2 d-flex  align-items-center'>
                                        <i className='fas fs-20 fa-video video-icon'></i>
                                        <p className='ms-900'>Video on</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className=' my-2 d-flex align-items-center'>
                                        <i className='fas fs-20 fa-video-slash video-icon'></i>
                                        <p className='ms-900'>Video off</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className=' my-2 d-flex  align-items-center'>
                                        <i className='fas fs-20 fa-share-square video-icon'></i>
                                        <p className='ms-900'>Share screen</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className=' my-2 d-flex align-items-center'>
                                        <span className="fs-20 video-icon">
                                            <FaComment size={20} />
                                        </span>
                                        <p className='ms-900'>Chat messages</p>
                                    </div>
                                </div>
                                <div className="col-12 my-2 ms-500 px-3">
                                    Note* :
                                    <p>1. Click on the video to zoom out.</p>
                                    <p>2. Drag your video to move in/out.</p>
                                </div>

                            </div>

                            <button className='btn mt-3 btn-login ms-700 fs-14 mb-3 mx-auto d-block' style={{ width: '200px' }} onClick={onClose}>CLOSE</button>
                        </div>

                    </Slide>
                </div>}
        </>);
}
