import React, { useState, useEffect } from 'react'
import calendar from '../controller/calendar';
import helper from '../helper/index';
import moment from 'moment';
import Calendar from 'react-calendar';
import SetAvailibilty from '../calendar/SetAvailibilty';
import Draggable from "react-draggable";
import EditAvailibilty from '../calendar/EditAvailabilty';
import { useTranslation } from "react-i18next";
let monthDate = ""
export default function TutorAvalability({ close }) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openSlot: false,
        slotDay: null,
        data: [],
        selectedDate: [],
        pageload: true,
    })
    const [edit, setEdit] = useState({
        open: false,
        data: [],
        day: null
    })
    const [slotdata, setSlotdata] = useState([])
    
    const onChangeCalendar = (day) => {
        if (moment(monthDate).format("YYYY-MM") !== moment(day).format("YYYY-MM")) {
            monthDate = moment(day).format("YYYY-MM-DD")
            getAvailability(monthDate)
        }

        let formatedDate = moment(day).format('YYYY-MM-DD')
        let list = [...state.selectedDate]
        if (!list.includes(formatedDate)) {
            let find = false
            let showData = ''
            slotdata.forEach((data) => {
                if (data.date.toString() === moment(day).format('YYYY-MM-DD')) {
                    find = true
                    if (find) {
                        showData = data.slot
                    }
                }
            })
            if (!find) {
                list.push(formatedDate)
            } else {
                setEdit({ ...edit, open: true, data: showData, day: moment(day).format('MMMM DD, YYYY') })
            }
        } else {
            let index = list.indexOf(formatedDate)
            list.splice(index, 1)
        }
        setState({ ...state, selectedDate: list })
    }

    useEffect(() => {
        monthDate = moment().format("YYYY-MM-DD")
        getAvailability(monthDate)
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAvailability = async (date) => {
        await calendar.getAvailibilty(helper.getid(),date)
            .then((res) => {
                if (res.data.status) {
                    let sort = res.data.result.sort((a, b) => {
                        return new Date(a.date) - new Date(b.date)
                    })

                    setSlotdata(sort)
                    setState({ ...state, pageload: false, openSlot: false, selectedDate: [] })
                } else {
                }

            })
    }

    return (
        <>
            <div className='tutor-avalibilty'>
                <div className="row gx-0 mx-0 mt-2">
                    <div className="col-lg-10 mx-auto">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-tutor-profile fs-12 mr-2" onClick={close} style={{ width: '120px' }}>{t('general.back')}</button>

                                <div className="my-progress-body p-2 px-3 my-4">
                                    <h4 className='tx-sky ms-700 my-3'><i className="fas fa-calendar-alt fs-26 mx-2"> </i>{t('tutorDash.calendar.setAvailabilty')}</h4>
                                    {true && <Calendar
                                        onChange={(day) => { onChangeCalendar(day) }}
                                        onClickMonth={(day) => { onChangeCalendar(day) }}
                                        tileClassName="tilename"
                                        className="w-100 cl-body br-1 mt-3 ms-700 selected"
                                        navigationAriaLabel="go up"

                                        next2Label={false}
                                        nextLabel = {false}
                                        prevLabel={false}
                                        prev2Label={false}

                                        minDate={new Date()}
                                        maxDate={new Date(moment().add(3, 'month'))}
                                        maxDetail='month'
                                        tileDisabled={({ date }) => {
                                            // let maxDate = moment(new Date(moment().endOf('month'))).format('YYYY-MM-DD')
                                            let minDate = moment(new Date()).format('YYYY-MM-DD')
                                            let formatedDate = moment(date).format('YYYY-MM-DD')
                                            if (formatedDate < minDate) {
                                                return true
                                            }
                                            else {
                                                return false
                                            }
                                        }}

                                        tileContent={({ date }) => {
                                            let find = false
                                            slotdata.forEach((data) => {
                                                if (data.date.toString() === moment(date).format('YYYY-MM-DD')) {
                                                    find = true
                                                }
                                            })
                                            if (find) {
                                                return <div className='all-center mt-2 p-rel' >

                                                    <p className='p-rel' style={{ borderBottom: '3px solid green', borderRadius: '10px', width: '20px' }}>
                                                        <i className='fas fa-clock  fs-12 p-abs tx-green' style={{ bottom: '0px', right: '-10px' }}></i>
                                                    </p>


                                                </div>

                                            }
                                            state.selectedDate.some((data) => {
                                                if (moment(data).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                                                    find = true
                                                    return true
                                                }else{
                                                    return false
                                                }
                                            })
                                            if (find) {
                                                return <div className='all-center mt-2 p-rel'>
                                                    <p className='p-rel' style={{ borderBottom: '3px solid #ffcb00', borderRadius: '10px', width: '20px' }}> <i className='fas fa-check-circle fs-12 p-abs tx-yellow' style={{ bottom: '0px', right: '-10px' }}></i>
                                                    </p>
                                                </div>

                                            }

                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* open popup for slot  */}
                <SetAvailibilty
                    open={state.openSlot}
                    day={state.selectedDate}
                    close={() => setState({ ...state, selectedDate: [], openSlot: false })}
                    cb={() => { getAvailability(monthDate); }}
                />

                {edit.open &&
                    <EditAvailibilty
                        day={edit.day}
                        data={edit.data}
                        cb={() => { getAvailability(monthDate) }}
                        close={() => setEdit({ ...edit, open: false })}
                    />}

            </div>

            {state.selectedDate.length !== 0 &&
                <Draggable
                    handle=".drag"
                >
                    <div className='p-fix bt-0 rt-0 b-grey bg-white mr-5 mb-3 pb-2 br-1'>
                        <div className='bg-yellow drag d-flex justify-content-between p-2 py-3 br-1 ms-700 text-white' style={{ cursor: 'move' }}>
                            <p>{t('tutorDash.calendar.selectedDate')}</p>
                            <button className='btn edit-info px-2 py-1' onClick={() => { setState({ ...state, selectedDate: [] }) }}>{t('tutorDash.calendar.clear')}</button>
                        </div>

                        <div className='px-3 h-100 hide-scroll' style={{ maxHeight: '300px', width: '300px', overflowY: 'auto' }} >
                            {
                                state.selectedDate.map((data, index) => {
                                    return (
                                        <div key={`schedule${index}`} className='d-flex align-items-center justify-content-between'>
                                            <p className='ms-500 my-3 mr-3'><span className='tag-box py-2 px-2'>{moment(data).format('MMMM DD, YYYY')}</span></p>
                                            <i className="far pointer fs-20 fa-trash-alt touch-hover px-1" onClick={() => onChangeCalendar(data)}></i>
                                        </div>
                                    )
                                })
                            }
                            <button className='btn btn-login py-1 w-100 mb-2' onClick={() => {
                                setState({ ...state, openSlot: true, selectedDate: state.selectedDate })
                            }}>{t('tutorDash.calendar.selectSlots')}</button>
                        </div>

                    </div>
                </Draggable>
            }


        </>
    )
}
