/* eslint-disable */
import React, { useState, useEffect } from 'react'
import '../static/css/tutorcheck.css'
import BasicInfo from './BasicInfo'
import ConnectionTest from './ConnectionTest'
import DigInDeep from './DigInDeep'
import logo from '../static/images/Logo.svg'
import { useHistory } from 'react-router'
import helper from '../helper/index'
import SvgLogout from '../Icons/Logout'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function TutorSignUpCheck() {
    const { t } = useTranslation();
    const history = useHistory()
    useEffect(() => {
        const status = helper.getProfileStatus();
        if (status === "Approved") {
            localStorage.clear()
            history.push(`/tutor/login`)
        }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [tab, setTab] = useState(0)
    return (
        <div className="tutor-check px-2 ltr">
            <table className='w-100 h-100'>
                <tr>
                    <td>
                        <div className="left-dash h-100 py-2 px-2 d-lg-block tx-lt-black d-md-block d-none" style={{ width: '260px' }}>
                            <div className="all-center mb-3 mt-4">
                                <Link to="/"><img width="150" src={logo} alt="logo" /></Link>
                            </div>
                            <p className="f-fred fs-24  text-center" style={{ color: '#C3C3C3' }}>{t('tutorOnBoard.sideBar.heading')}</p>
                            <p className="ms-900 tx-lt-black text-center">{["Uploaded", "Approved"].includes(helper.getProfileStatus()) ? 3 : tab + 1}/3 {t('tutorOnBoard.sideBar.completed')}</p>

                            <ul className="mt-3 px-1">
                                <li className={`${tab === 0 && "active-tab "} d-flex align-items-center`}>
                                    {tab >= 1 || ["Uploaded", "Approved"].includes(helper.getProfileStatus()) ?
                                        <div className='check-out all-center mr-2'>
                                            <i className="fas fa-check check-tik"></i>
                                        </div> :
                                        <div className='check-incomplete-out all-center mr-2'>

                                        </div>
                                    }
                                    <div>
                                        <p className="f-fred text-upper f-fred">{t('tutorOnBoard.sideBar.tutorProfile')}</p>
                                        <p className="ms-500 tx-lt-grey fs-12">{t('tutorOnBoard.sideBar.note1')}</p>
                                    </div>
                                </li>
                                <li className={`${tab === 1 && "active-tab "} d-flex align-items-center`}>
                                    {tab >= 2 || ["Uploaded", "Approved"].includes(helper.getProfileStatus()) ?
                                        <div className='check-out all-center mr-2'>
                                            <i className="fas fa-check check-tik"></i>
                                        </div> :
                                        <div className='check-incomplete-out all-center mr-2'>

                                        </div>
                                    }
                                    <div>
                                        <p className="f-fred text-upper">{t('tutorOnBoard.sideBar.digindeep')}</p>
                                        <p className="ms-500 tx-lt-grey fs-12">{t('tutorOnBoard.sideBar.note2')}</p>
                                    </div>
                                </li>
                                <li className={`${tab === 2 || tab === 3 && "active-tab "} d-flex align-items-center`}>
                                    {tab >= 3 || ["Uploaded", "Approved"].includes(helper.getProfileStatus()) ?
                                        <div className='check-out all-center mr-2'>
                                            <i className="fas fa-check check-tik"></i>
                                        </div> :
                                        <div className='check-incomplete-out all-center mr-2'>

                                        </div>
                                    }
                                    <div>
                                        <p className="f-fred text-upper">{t('tutorOnBoard.sideBar.connection')}</p>
                                        <p className="ms-500 tx-lt-grey fs-12">{t('tutorOnBoard.sideBar.connection')}</p>
                                    </div>
                                </li>
                                <li className='d-flex align-items-center pointer logout'>
                                    <SvgLogout className="inactive-icon-color mr-2" />
                                    <p className='f-fred tx-lt-grey pointer ' onClick={() => { helper.logOut(); history.push('/') }}>{t('tutorOnBoard.sideBar.logout')}</p>
                                </li>
                            </ul>
                        </div>
                    </td>
                    <td className='w-100 h-100'>
                        <div className="right-dash h-100 tx-lt-black bg-white br-2 pb-3 px-2">
                            {
                                tab === 0 ? <BasicInfo next={() => setTab(1)} /> : tab === 1 ? <DigInDeep prev={() => setTab(0)} next={() => setTab(2)} /> : <ConnectionTest onSubmit={()=>setTab(3)} prev={() => setTab(1)} />
                            }
                        </div>
                    </td>
                </tr>
            </table>
            <Helmet htmlAttributes={{ dir: "ltr"}} />
        </div>
    )
}
