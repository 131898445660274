/* eslint-disable */
import React, { useState, useEffect } from 'react'
import user from '../controller/user'
import helper from '../helper';
import { useParams,useHistory } from 'react-router-dom';
export default function VerifyCertificate() {
    const { id } = useParams()
    const history = useHistory()
    const [state, setState] = useState({
        load: true,
        error: '',
        resp : ''
    })
    useEffect(() => {

        const resp = helper.isLoggedIn()
        const role = helper.getRoleType()
        if (resp.res && role == "user") {
            getCert()
        }
        else {
            setState({ ...state, load: false, error: 'Unauthorized access' })
        }
         //  eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getCert = async () => {
        await user.getCertificate({ studentId: helper.getid(), courseId: id }).then((res) => {
            if (res.status === 200) {
                try {

                    var file = URL.createObjectURL(res.data)
                    var a = document.createElement("a");
                    a.href = file;
                    a.download = `certificate`;
                    document.body.appendChild(a);
                    a.click();
                    if(!file){
                        setState({ ...state, load: false, error: 'Invalid certificate' }) 
                    }
                    history.goBack()
                    setState({ ...state, load: false, error: '',resp:'Thanks for downloading your certificate' })
                }
                catch (err) {
                    setState({ ...state, load: false, error: 'Invalid certificate' })
                }
            } else {
                setState({ ...state, load: false, error: 'Invalid certificate' })
            }
        }).catch((err)=>{
            setState({ ...state, load: false, error: 'Invalid certificate' })
        })
    }

    return (
        <div className="w-100 h-100 all-center">
            {state.load && <h2 className='text-center px-3 mt-5 f-fred tx-lt-grey'>Please wait we are verifying your  certificate ...</h2>}
            {!state.load && state.resp && <h2 className='text-center px-3 mt-5 f-fred tx-sky'>{state.resp}</h2>}
            {!state.load && state.error && <h2 className='text-center px-3'>{state.error}</h2>}
        </div>
    )
}




