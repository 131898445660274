import React from 'react'
import happy from '../static/images/happyParrot.png'
import { useTranslation } from "react-i18next";
export default function ConfirmPopup({ open, msg, onClose, onOk }) {
    const { t } = useTranslation();
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center">
                    <div className="popup-modal-body sm">
                        <div className='my-3'>
                            <img className='d-block mx-auto' style={{ width: '150px', height: '150px' }} src={happy} alt="parrot" />
                        </div>
                        <p className='ms-500 text-center my-4 px-'>{msg}</p>
                        <div className='all-center'>
                            <div>
                                <button className='btn btn-login fs-14 mb-3 mx-2' style={{ width: '150px' }} onClick={onOk}>{t('button.okay')}</button>
                                <button className='btn cancel-subscription py-2 f-fred ls fs-14 mb-3 mx-2' style={{ width: '150px' }} onClick={onClose}>{t('button.cancel')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )

}
