/* eslint-disable */
import React, { useState, useEffect } from 'react'
import user from '../controller/user'
import Backdrop from '../component/Backdrop'
import CourseCard from '../component/CourseCard'
import CourseFilter from './CourseFilter'
import { useHistory } from 'react-router-dom'
import helper from '../helper'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
function UserCourses() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [courseCatlist, setCourseCatList] = useState([])
    const [resData, setResData] = useState([])
    const [qry, setQry] = useState(null)
    const [enrolledCourses, setEnrolledCourse] = useState({
        data: [],
        pageload: true
    })

    const [state, setState] = useState({
        searchData: {
            open: false,
            data: []
        },
        openFilter: false,
        filterData: null
    })

    useEffect(() => {

        apiCall()
        getEnrolledCourses()

        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await user.getCourseListing(helper.getid())
            .then((res) => {
                const list = []
                if (res.data.status) {
                    res.data.result.forEach((data) => {
                        list.push(data.courseCategory)
                    })
                    setResData(res.data.result)
                    setState({ ...state, searchData: { open: false, data: res.data.result } })
                    setCourseCatList(list)
                }
                setPageload(false)
            }).catch((err) => {
                setPageload(false)
            })
    }

    const search = () => {
        if (qry) {
            let regEx = new RegExp(qry, 'si')
            let list = []
            resData.forEach((result) => {
                let course = []
                result.course.forEach((data) => {
                    if (data.title && ((data.title).match(regEx) || (result.courseCategory).match(regEx))) {
                        course.push(data)
                    }
                })
                if (course.length != 0) {
                    list.push({
                        course: course,
                        courseCategory: result.courseCategory
                    })
                }
            })
            setState({
                ...state, searchData: {
                    open: true,
                    data: list
                }
            })
        }
        else {
            setState({
                ...state, searchData: {
                    open: true,
                    data: resData
                }
            })
        }
    }

    const filters = (data) => {
        const res = resData.filter((result) => {
            if (data.level.length !== 0 && data.categories.length != 0) {
                let find = false
                result.course.some((courses) => {
                    if (data.level.includes(courses.experienceLevel)) {
                        find = true
                        return true
                    }
                })
                if (find && data.categories.includes(result.courseCategory)) {
                    return true
                }
            }
            else if (data.categories.length === 0 && data.level.length != 0) {
                let find = false
                result.course.some((courses) => {
                    if (data.level.includes(courses.experienceLevel)) {
                        find = true
                        return true
                    }
                })
                return find
            }
            else if (data.level.length === 0 && data.categories.length !== 0) {
                return data.categories.includes(result.courseCategory)
            }
            else {
                return true
            }
        })
        setState({
            ...setState, searchData: {
                open: data.categories.length === 0 && data.level.length === 0 ? false : true,
                data: res
            }
        })
    }

    const getEnrolledCourses = async () => {
        await user.getEnrolledCourses(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result.length >= 1) {
                        setEnrolledCourse({ ...enrolledCourses, data: res.data.result, pageload: false })
                    } else {
                        setEnrolledCourse({ ...enrolledCourses, data: [], pageload: false })
                    }
                } else {
                    setEnrolledCourse({ ...enrolledCourses, data: [], pageload: false })

                }
            }).catch((err) => {
                setEnrolledCourse({ ...enrolledCourses, data: [], pageload: false })

            })
    }

    return (
        <>
            {!pageload &&
                <div className="tutor-library">
                    <form onSubmit={(e) => { e.preventDefault(); search() }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <div className='p-rel h-100 d-flex align-items-center w-100'>
                                    <input type="text" maxLength={25} placeholder={t('general.courses.enterTitle')} value={qry} className="w-100  input-box ms-700 px-2 py-2 fs-14 " 
                                     onChange={(e) => {
                                        if (e.target.value) {
                                            const reg = new RegExp(/^[a-zA-Z ,.-]*$/gi)
                                            const res = reg.test(e.target.value.replace(/\n/g, " "));
                                            if (res) {
                                                setQry(e.target.value)
                                            }
                                        } else {
                                            setQry("")
                                        }
                                    }}
                                    />
                                    {qry && <FaTimes size={30} style={reduxState.language === 'ar' ? { left: '5px' } : { right: '5px' }}
                                        className={`px-2 inactive-icon-color p-abs pointer bg-white`}
                                        onClick={() => { setState({ ...state, searchData: { open: false, data: resData } }); setQry('') }} />}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 pr-2 mb-2">
                                <button type='submit' className="btn btn-login w-100  wrap" >{t('general.courses.browseLibrary')}</button>
                            </div>
                            <div className="col-lg-5 d-lg-block d-none ">
                                <div className="col-lg-5 col-6 mb-2">
                                    <button type='button' className="btn btn-tutor-profile w-100  wrap" onClick={() => setState({ ...state, openFilter: true })}>{t('general.courses.filterCourses')}</button>
                                </div>
                            </div>
                        </div>
                    </form>

                    {!state.searchData.open && enrolledCourses.data.length !== 0 && <section>
                        <h4 className={`f-fred fs-24 mb-2 mt-3 px-1 text-capitalize tx-lt-grey ${reduxState.language === 'ar' && "text-right"}`}>{t('general.courses.enrolledCourses')}</h4>
                        <div className="row gx-0 mx-0">
                            {

                                enrolledCourses.data.map((courses) => {
                                    return (
                                        <>
                                            {
                                                courses.course.map((result) =>
                                                    <div key={courses.id} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                        <CourseCard
                                                            name={result.title}
                                                            subTitle={result.subTitle}
                                                            level={result.experienceLevel}
                                                            lesson={result.totalLessons}
                                                            slides={result.totalSlide}
                                                            isRemove={false}
                                                            img={result.imgName}
                                                            isEnrolled={true}
                                                            totalSlide={result.totalSlide}
                                                            completedSlide={result.total_slide_complete}
                                                            onChange={() => { history.push(`/user/dashboard/course/${result.id}`) }}
                                                        />
                                                    </div>)
                                            }
                                        </>
                                    )
                                })
                            }

                        </div>
                    </section>
                    }


                    <section className="row gx-0 mx-0">
                        {
                            state.searchData.data.map((cat, index) => {
                                return (
                                    <>
                                        <div key={`category${index}`} className={`col-12 ${reduxState.language === 'ar' && "text-right"}`}><p className="f-fred mb-2 mt-3 fs-24 px-1 tx-lt-grey text-capitalize">{cat.courseCategory}</p> </div>
                                        {
                                            cat.course.map((courses, index1) => {

                                                return (
                                                    <div key={`course+${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                        <CourseCard
                                                            name={courses.title}
                                                            level={courses.experienceLevel}
                                                            subTitle={courses.subTitle}
                                                            lesson={courses.totalLessons}
                                                            slides={courses.totalSlide}
                                                            isRemove={false}
                                                            img={courses.imgName}
                                                            isEnrolled={false}
                                                            onChange={() => { history.push(`/user/dashboard/course/${courses.id}`) }}
                                                        />
                                                    </div>
                                                )

                                            })
                                        }
                                    </>
                                )
                            })
                        }

                        {state.searchData.data.length === 0 &&
                            <div className='col-12'>
                                <h5 className='tx-lt-grey text-center my-3 f-fred'>No Result found.</h5>
                            </div>
                        }
                    </section>

                </div>
            }

            {/* Pageload refererence */}
            {pageload && <Backdrop />}

            {/* Course filters */}
            <CourseFilter
                categories={courseCatlist}
                open={state.openFilter}
                close={() => setState({ ...state, openFilter: false })}
                cb={(data) => {
                    setState({ ...state, openFilter: false });
                    filters(data)
                }}
            />
        </>
    )
}

export default UserCourses
