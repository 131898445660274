/* eslint-disable */
import moment from 'moment'
import React, { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import agora from '../controller/agora'
import helper from '../helper/index'
import logo from '../static/images/logo.png'
let vidc = true
let stream = null;
export default function VideoAccess() {
    const history = useHistory()
    const { channelName } = useParams()
    const courseId = useRef()
    const [state, setState] = useState({
        pageload: true,
        err: null,
        data: null
    })
    let video = null
    const [controll, setControl] = useState({
        mic: true,
        vid: true
    })

    useEffect(() => {
        getMedia()
        apiCall()
        return () => {  stopStream();}
    }, [])

    async function getMedia() {
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { min: 1024, ideal: 1280, max: 1920 },
                    height: { min: 576, ideal: 720, max: 1080 }
                }, audio: false
            });
            video = document.querySelector('video');
            video.srcObject = stream;
            video.onloadedmetadata = function (e) {
                video.play();
            };
            document.getElementById('video').addEventListener('click', async function () {
                if (vidc) {
                    stopStream();
                } else {
                    vidc = true
                    stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: { min: 1024, ideal: 1280, max: 1920 },
                            height: { min: 576, ideal: 720, max: 1080 }
                        }, audio: false
                    });
                    video = document.querySelector('video');
                    video.srcObject = stream;
                    video.onloadedmetadata = function (e) {
                        video.play();
                    };
                }
            });


        } catch (err) {

        }
    }


    function stopStream() {
        vidc = false
        stream.getVideoTracks().forEach(function (track) {
            track.stop();
        });
    }


    const apiCall = async () => {
        await agora.getInfoBeforeClass(helper.getid(), channelName).then((res) => {
            if (res.status === 200) {
                setState({ ...state, data: res.data.result, pageload: false })

            } else {
                setState({ ...state, data: null, err: res.data.error, pageload: false })
            }
        }).catch((err) => {
            setState({ ...state, data: null, err: err, pageload: false })
        })
    }

    const getDuration = (st, en) => {
        var startTime = moment(`${st}`, 'HH:mm:ss');
        var endTime = moment(`${en}`, 'HH:mm:ss');
        var dif = moment.duration(endTime.diff(startTime));
        return dif.minutes()
    }

    const joinClass = async () => {
        if (!courseId.current.value) {
            helper.showMessage("Please  select course", "error")
            return
        }
        else if (!channelName) {
            helper.showMessage("Invalid channel name", "error")
            return
        }

        await agora.joinClass(courseId.current.value, helper.getid(), channelName).then((res) => {
            if (res.data.status) {
                history.push(`/dhad-video/${channelName}`)
            } else {
                setState({ ...state, data: null, err: res.data.error, pageload: false })
            }
        }).catch((err) => {
            setState({ ...state, data: null, err: err, pageload: false })

        })
    }


    return (
        <div className='video-access-container d-flex flex-column bg-white'>
            {
                !state.pageload && !state.err ?
                    <>
                        <section className='video-room-header p-3 px-4'>
                            <table className='w-100'>
                                <tr>
                                    <td className='table-break w-50'>
                                        <img height={'50px'} src={logo} alt="logo" />
                                    </td>
                                    <td className='table-break w-100  d-lg-block d-md-block d-none'>
                                        <div className='d-flex w-100 flex-column justify-content-lg-end mb-2 align-items-lg-end align-items-md-end'>
                                            <p className='ms-900'>{helper.getEmail()}</p>
                                            <p className='ms-900'>Hey {state.data && state.data.student_name}</p>
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </section>
                        <div className=' h-100 d-flex p-2'>
                            <table className='w-100'>
                                <tr>
                                    <td className='table-break w-50'>
                                        <div className='user-video-access all-center flex-column px-5' style={{ height: '400px' }}>
                                            <video className='w-100 br-1'></video>
                                            <section className='my-3 d-flex'>

                                                <i id="video" className={`fas ${vidc ? "fa-video" : "fa-video-slash"} video-icon`}
                                                    onClick={() => { setControl({ ...controll, vid: !controll.vid }) }}


                                                ></i>
                                                <i id="audio" className={`fas ${controll.mic ? "fa-microphone" : "fa-microphone-slash"} video-icon`}
                                                    onClick={() => { setControl({ ...controll, mic: !controll.mic }) }}
                                                ></i>
                                            </section>
                                        </div>

                                    </td>
                                    <td className='table-break w-50'>
                                        <div className='video-access-question user-dash px-5' style={{ height: '400px' }}>
                                            <h3 className='ms-700 '>Welcome Back</h3>

                                            <section className='ms-500 mt-4  tutor-dash ' style={{ height: 'initial' }}>
                                                <p className='ms-500 fs-14'>Tutor Name</p>
                                                <p><span className='ms-700 mb-3'>{state.data.tutor_name}</span></p>
                                                <p className='mt-3'>Class Date</p>
                                                <p className='ms-700'>{moment(state.data.date).format('MMMM DD, YYYY')} at <span>{moment(state.data.start_time, 'hh:mm').format('hh : mm A')}</span></p>
                                                <p className='mt-3'>Class Duration</p>
                                                <p className='ms-700'>{getDuration(state.data.start_time, state.data.end_time)} Min</p>

                                                <p className='mt-3'>What you would you learn in class?</p>
                                                <select ref={courseId} className='w-75 input-box py-2 fs-16 ms-700'>
                                                    {state.data.course_name.map((data,index) => <option     key={`course${index}`} value={data.id}>{data.title}</option>)}
                                                    <option  value={0}>other</option>
                                                </select>
                                            </section>

                                            <section className='mt-3'>
                                                <button className='btn btn-login py-2 px-4  mr-3' style={{ width: '150px' }} onClick={() => joinClass()}>JOIN</button>
                                                <button className='btn cancel-subscription py-2 px-4 f-fred fs-14 mx-3' style={{ width: '150px' }} onClick={() => history.goBack()}>CANCEL</button>
                                            </section>

                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </> :
                    <div className='h-100 all-center '>
                        <h4 className='my-4 text-center ms-900 text-capitalize'>{state.err}</h4>
                    </div>
            }
        </div>
    )
}
