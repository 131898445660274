/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import "./videonew.css";
import AgoraRTC from "agora-rtc-sdk-ng";
import store from "../redux/AppState";
import { useSelector } from "react-redux";
import Backdrop from "../component/Backdrop";
import tutorHelper from '../helper/tutor'
import { useStopwatch } from 'react-timer-hook';
import agoraController from '../controller/agora'
import { useHistory } from 'react-router'
import moment from "moment";
import helper from "../helper";
import tutor from "../controller/tutor";
import user from "../controller/user";
import ChatBox from './ChatBox'
import FeedBack from "./FeedBack";
import recording from '../controller/recording'
import Instruction from "./Instruction";
import LeftStrip from "./LeftStrip";
import { FaComment } from 'react-icons/fa';
import Loader from "../component/Loader";
import agora from "../controller/agora";

import ChatOnMobile from "./ChatOnMobile";
import callEndImg from '../static/images/call-end.png'
import LiveNotification from "./LiveNotification";
import { Badge } from "@mui/material";
import { Tooltip } from '@mui/material'

let rtc = {
    localAudioTrack: null,
    localVideoTrack: null,
    localScreenShareTrack: null,
    client: null
};
let screenClient = null;
let screenTrack = null;
let classStartAt = null;
let feedback_tutor_id = 0
let fullScreenEnable = false
let screenShareId = helper.genCode()
let isClassStart = false

// Recording varibles 
let recordingUserId = helper.genCode()
let resourceId = ''
let sid = ''
let appId = ''
let channelName = ''
let remoteId = ''
let remoteUser = ""
let videoToken = ''
let deviceRequirement = {
    isCompatible: false
}

//Enable Class Room
async function roomEnable() {
    const broweserCompatiblity = AgoraRTC.checkSystemRequirements()
    if (!broweserCompatiblity) {
        helper.showMessage("Oops! Your browser is not compatible for video calling", "info")
        return
    }
    deviceRequirement.isCompatible = true
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    rtc.client.on("user-published", async (user, mediaType) => {
        await rtc.client.subscribe(user, mediaType);
        if (user.uid != screenShareId) {
            if (mediaType === "video") {
                const remoteVideoTrack = user.videoTrack;
                let div = document.createElement('div')
                div.id = user.uid
                div.classList.add("remote-video", "remote", "ms-900", "fs-24", "m-2", "d-flex", "p-rel")
                let container = document.getElementById("remote-container")
                if (container.childElementCount > 1) {
                    div.style.width = "150px"
                    div.style.height = "150px"
                    div.style.position = "absolute"
                    div.style.right = "10px"
                    div.style.top = `250px`
                }
                // div.style.top = `${container.childElementCount - 1 * 170 + 80}px`
                container.append(div)
                remoteVideoTrack.play(div)
                div.firstChild.firstChild.addEventListener('click', function (e) {
                    remoteZoomInOut(e)
                })
                let playParent = document.getElementById(div.firstChild.id)
                playParent.classList.add("player_parent")
            }
            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;
                remoteAudioTrack.play();
            }
        } else if (user.uid === screenShareId) { // Screen share
            if (mediaType === "video") {
                try {
                    let checkDiv = document.getElementById(user.uid)
                    if (checkDiv) {
                        return
                    }
                } catch (err) { }

                const remoteVideoTrack = user.videoTrack;
                let div = document.createElement('div')
                div.id = user.uid
                div.classList.add("remote-screen", "remote", "ms-900", "fs-24", "m-2", "d-flex")
                let container = document.getElementById("remote-container")
                if (container.childElementCount > 1) {
                    div.style.width = "150px"
                    div.style.height = "150px"
                    div.style.position = "absolute"
                    div.style.right = "10px"
                    div.style.top = `250px`
                }
                // div.style.top = `${container.childElementCount - 1 * 170 + 80}px`
                container.append(div)
                remoteVideoTrack.play(div);
                div.firstChild.firstChild.addEventListener('click', function (e) {
                    remoteZoomInOut(e)
                })

                let playParent = document.getElementById(div.firstChild.id)
                playParent.classList.add("player_parent")
            }
        }
    });

    rtc.client.on("user-unpublished", (user, mediaType) => {
        try {

            if (user.uid == screenShareId) {
                let container = document.getElementById(screenShareId)
                container.remove()
                let div = null
                try {
                    div = document.getElementById(remoteId)
                } catch (err) {
                    try {
                        div = document.getElementById(helper.getid())
                    } catch (err) {
                        div = null
                    }
                }

                if (div) {
                    const divCount = document.getElementById('remote-container').childElementCount;
                    if (divCount === 1)
                        div.style.width = `calc(100%)`
                    else
                        div.style.width = `calc(100% - 180px)`
                    div.style.height = "100%"
                    div.style.objectFit = "cover"
                    div.style.position = "initial"
                    document.getElementById('remote-container').style.justifyContent = "start"
                }
            } else if (mediaType === "video") {
                let container = document.getElementById(user.uid)
                container.remove()
                let div = null
                try {
                    div = document.getElementById(remoteId)
                } catch (err) {
                    try {
                        div = document.getElementById(helper.getid())
                    } catch (err) {
                        div = null
                    }
                }

                if (div) {
                    const divCount = document.getElementById('remote-container').childElementCount;
                    if (divCount === 1)
                        div.style.width = `calc(100%)`
                    else
                        div.style.width = `calc(100% - 180px)`
                    div.style.height = "100%"
                    div.style.objectFit = "cover"
                    div.style.position = "initial"
                    document.getElementById('remote-container').style.justifyContent = "start"
                }
            }
            if (mediaType === "audio") { }

        } catch (err) { }
    });

    rtc.client.on("user-left", (user, mediaType) => {
        try {
            if (user.uid != screenShareId && user.uid == remoteId) {
                helper.showMessage(remoteUser + " has left the class.", "info")
            }
        } catch (err) { }
    });

    rtc.client.on("user-joined", (user, mediaType) => {
        try {
            store.setVideoRoom({ userJoined: true });
        } catch (err) { }
    });
}

//Enable Screen Sharing
async function enableScreen() {
    const broweserCompatiblity = AgoraRTC.checkSystemRequirements()
    if (!broweserCompatiblity) {
        helper.showMessage("Oops! Your browser is not compatible for screen sharing", "info")
        return
    }
    screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    screenClient.on("user-published", async (user, mediaType) => { });
    screenClient.on("user-unpublished", (user, mediaType) => {
        try {
            let checkDiv = document.getElementById(user.uid)
            if (checkDiv) {
                checkDiv.remove()
            }
        } catch (err) { helper.showMessage(err, "error") }
    });
}

//Join local user
async function joinRoom(options) {

    await rtc.client.join(
        options.appId,
        options.channel,
        options.token,
        options.uid
    );
    try { rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack(); } catch (err) { helper.showMessage(err, "error") }
    try { rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack(); } catch (err) { helper.showMessage(err, "error") }
    try { await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]); } catch (err) { helper.showMessage(err, "error") }
    try {
        // classStartAt = moment().format('HH:mm:ss')
        store.setVideoRoom(false);
        const localPlayerContainer = document.getElementById("user-video");
        localPlayerContainer.style.visibility = "initial";
        rtc.localVideoTrack.play(localPlayerContainer);
        localPlayerContainer.firstChild.firstChild.addEventListener('click', function (e) {
            remoteZoomInOut(e)
        })
        let playParent = document.getElementById(localPlayerContainer.firstChild.id)
        playParent.classList.add("player_parent")

        // Recording start from here 
        const res = helper.getRecordingDetails();
        const resp = helper.getRoleType()
        if (resp === "tutor") {
            if (res.isExist && res.channelName == channelName && res.resourceId && res.sid) {
                var startTime = moment(`${moment(res.createdAt).format('HH:mm:ss')}`, 'HH:mm:ss');
                var endTime = moment(`${moment().format('HH:mm:ss')}`, 'HH:mm:ss');
                var dif = moment.duration(endTime.diff(startTime));

                if (dif.seconds() > 60) {
                    getResourceId()
                }
                else {
                    resourceId = res.resourceId
                    sid = res.sid
                    recordingUserId = res.recordingUserId
                    channelName = res.recordingUserId
                    store.setLiveClassDetails({ isRecordingStart: true })
                }
            } else {
                getResourceId()
            }
        }

        return true
    } catch (err) { helper.showMessage(err, "error"); return false }
}

//Stop Screen Sharing
async function stopSharing() {
    try {
        try { screenTrack.close(); } catch { }
        try { await screenClient.leave(); } catch { }
        updateLayout({ layout: "float" })
    } catch (err) { }
}

async function micControl(val) {
    try {
        await rtc.localAudioTrack.setEnabled(val);
    } catch (err) { }
}

async function videoControl(val) {
    try {
        await rtc.localVideoTrack.setEnabled(val);
    } catch (err) { }
}

function fullScreen(isFull) {
    try {
        if (isFull) {
            fullScreenEnable = true
            const ob = document.getElementById("fullscreen");
            ob.requestFullscreen();
        } else {
            fullScreenEnable = false
            document.exitFullscreen();
        }
    } catch (err) {

    }

}

function remoteZoomInOut(e) {
    let div = document.getElementById(e.target.id).parentElement.parentElement
    if (div.style.width === "calc(100% - 180px)" || div.style.width === "calc(100%)") {/* Zoomout video */
        div.style.width = "200px"
        div.style.height = "200px"
        div.style.objectFit = "cover"
        document.getElementById('remote-container').style.justifyContent = "center"
    } else {/* ZoomIn video */
        const divCount = document.getElementById('remote-container').childElementCount;
        if (divCount === 1)
            div.style.width = `calc(100%)`
        else
            div.style.width = `calc(100% - 180px)`
        div.style.height = "100%"
        div.style.objectFit = "cover"
        div.style.position = "initial"
        document.getElementById('remote-container').style.justifyContent = "start"
    }
    let divNo = 0
    const remoteContainer = document.getElementById('remote-container').childNodes;
    for (let i = 0; i < remoteContainer.length; i++) {
        let remoteDiv = document.getElementById(remoteContainer[i].id)
        if (remoteContainer[i].id !== div.id) {
            remoteDiv.style.width = "150px"
            remoteDiv.style.height = "150px"
            remoteDiv.style.objectFit = "cover"
            remoteDiv.style.position = "absolute"
            remoteDiv.style.right = "10px"
            remoteDiv.style.top = `${divNo * 170 + 80}px`
            divNo = divNo + 1
        }
    }
}

/* ------------------ Recording  -------------------- */
//Step 1 : Get resource ID
const getResourceId = async () => {
    recordingUserId = helper.genCode()
    await recording.getResourceId({ channelName: channelName, uid: recordingUserId, appId: appId }).then(async (res) => {
        if (res.status === 200) {
            resourceId = res.data.resourceId
            await startRecording(res.data.resourceId)
        }
    }).catch(err => { })
}

//Step 2 : Start Recording
const startRecording = async (resourceId) => {
    await agoraController.getVideoToken(channelName, "publisher", recordingUserId, "tutor").then(async (res) => { // get auth token for recording user Id
        if (res.data.status) { // get successfully recording user token
            await recording.startRecording({ channelName: channelName, uid: recordingUserId.toString(), appId: appId, resourceId: resourceId, token: res.data.result.token }).then(async (res) => {
                if (res.status === 200) {
                    sid = res.data.sid
                    setTimeout(() => { recordingStatus() }, 2000)
                }
            }).catch(err => { })
        }
    }).catch((err) => { })
}

//Step 3 : Check Recording Status
const recordingStatus = async () => {
    await recording.checkRecordingStatus({ appId: appId, resourceId: resourceId, sid: sid }).then(async (res) => {
        if (res.status === 200) {
            store.setLiveClassDetails({ isRecordingStart: true })
        }
    }).catch(err => { })
}

/* Update recording layout */
const updateLayout = async ({ layout }) => {
    await recording.updateRecordingLayout({ channelName: channelName, uid: recordingUserId.toString(), appId: appId, resourceId: resourceId, sid: sid, layout: layout, WideRegionUserId: screenShareId.toString() }).then(async (res) => { })
}


export default function VideoRoom() {
    const storeData = useSelector((state) => state);
    const param = useParams();
    const history = useHistory()

    /* State for video control */
    const [isMicOn, setIsMicOn] = useState(false);
    const [isVideoOn, setIsVideoOn] = useState(false);
    const [isScreenShare, setIsScreenShare] = useState(false);

    /* State for Chat control */
    const [showFirstChat, SetShowFirstChat] = useState(false)
    const [showChat, SetShowChat] = useState(false)
    const [showMobileChat, setShowMobileChat] = useState(false)

    const [openInstruction, setOpenInstruction] = useState({ open: false, type: 'user' });
    const [isAttendanceMark, setIsAttendanceMark] = useState({ mark: false, isClassEnd: false })

    const [state, setState] = useState({
        pageload: true,
        err: 'Please wait, we are checking information.',
        // err: '',
        data: null
    })
    const [liveData, setLiveData] = useState({ count: 0, remoteName: "" })
    const [feedBack, setFeedBack] = useState({
        open: false,
        tutorId: 0
    })

    let reqDataTutor = null, reqDataStudent = null

    // class timer 
    const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false });

    const endClass = async () => {
        var startTime = moment(`${classStartAt}`, 'HH:mm:ss');
        var endTime = moment(`${moment().format('HH:mm:ss')}`, 'HH:mm:ss');
        var dif = moment.duration(endTime.diff(startTime));
        let classtime = [tutorHelper.makeTwoDigit(dif.hours()), tutorHelper.makeTwoDigit(dif.minutes()), tutorHelper.makeTwoDigit(dif.seconds())].join(':');
        reqDataTutor = {
            tutor_id: helper.getid(),
            channel_name: param.channelName,
            tutor_start_time: classStartAt,
            tutor_end_time: moment().format('HH:mm:ss'),
            tutor_class_time: classtime,
            record_url: `/classrecordings/${sid}_${param.channelName}_0.mp4`,
            reason: "Tutor has left the class"
        }
        reqDataStudent = {
            student_id: helper.getid(),
            channel_name: param.channelName,
            student_start_time: classStartAt,
            student_end_time: moment().format('HH:mm:ss'),
            student_class_time: classtime,
            reason: "Student  has left the class"
        }


        if (helper.getRoleType() === "tutor") {
            await tutor.tutorClassend(reqDataTutor)
                .then((res) => {
                }).catch((err) => { })
        }
        else {
            await user.userClassend(reqDataStudent)
                .then((res) => {
                    setFeedBack({ ...feedBack, open: true })
                }).catch((err) => { })
        }
    }

    useEffect(() => {
        const resp = helper.isLoggedIn()
        if (!resp.res) {
            history.push('/')
        }
        getMediaAccess()
        return async () => {
            if (isClassStart) {
                try {
                    let role = helper.getRoleType()
                    if (role === "tutor") {
                        let date = new Date()
                        try { helper.saveRecordingDetails({ channelName: channelName, resourceId: resourceId, sid: sid, createdAt: date, recordingUserId: recordingUserId }) } catch { }
                        endClass()
                    }
                    try { rtc.localAudioTrack.close(); } catch { }
                    try { rtc.localVideoTrack.close(); } catch { }
                    try { screenTrack.close(); } catch { }
                    try { await rtc.client.leave(); } catch { }
                    try { await screenClient.leave(); } catch { }
                    store.setLiveClassDetails({ isRecordingStart: false })
                } catch (err) { }
            }
        };
    }, []);

    /* On user joined */
    useEffect(() => {
        if (storeData?.videoRoom?.userJoined) {
            start() /* Start Video call start time. */
            classStartAt = moment().format('HH:mm:ss')
            SetShowFirstChat(true)
            SetShowChat(true)
        }
    }, [storeData?.videoRoom?.userJoined])

    const getMediaAccess = async () => {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(stream => {
                roomEnable();
                getToken();
                try {
                    stream.getTracks().forEach((track) => track.stop())
                } catch (error) {

                }
            })
            .catch(err => {
                setState({ ...state, data: null, err: err?.toString(), pageload: false })
            });
    }

    const getToken = async () => {
        let userRole = "subscriber"
        if (helper.getRoleType() == "tutor") {
            userRole = "publisher";
        }
        const accessUser = helper.getRoleType() == "user" ? "student" : "tutor"
        await agoraController.getVideoToken(param.channelName, userRole, helper.getid(), accessUser).then((res) => {
            if (res.data.status) {
                if (res.data.available_minutes === 0) {
                    setState({ ...state, err: "Not enough minutes to join the class", pageload: true })
                    return
                }
                feedback_tutor_id = res.data.result.tutor_id
                videoToken = res.data.result.token
                setState({ ...state, data: res.data.result, err: null, pageload: false })
                appId = helper.decodeAppId(res.data.result.app_Id)
                channelName = param.channelName
                joinClass(appId, res.data.result.token)
                isClassStart = true
                remoteId = accessUser === "student" ? res.data.result.tutor_id : res.data.result.student_id
                remoteUser = accessUser === "student" ? "Tutor" : "Student"
            } else {
                setState({ ...state, data: null, err: res.data.error, pageload: false })
            }
        }).catch((err) => {
            setState({ ...state, data: null, err: err, pageload: false })
        })

        await agoraController.getScreenShareToken(param.channelName, userRole, screenShareId, accessUser).then(async (res) => {
            if (res.data.status) {
                videoToken = res.data.result.token;
            }
        }).catch((err) => { });
    }

    async function leaveRoom() {
        try {
            try { pause() } catch { }
            let role = helper.getRoleType()

            if (role === "user") {
                endClass()
            } else if (role === "tutor") {
                history.push('/tutor/dashboard/home')
            }

            if (!isAttendanceMark.mark && role === "tutor") { /* Tutor not teach any lesson */
                await agora.markIntroCourseAsStudy(channelName)
            }

            try { rtc.localAudioTrack.close(); } catch { }
            try { rtc.localVideoTrack.close(); } catch { }
            try { screenTrack.close(); } catch { }
            try { await rtc.client.leave(); } catch { }
            try { await screenClient.leave(); } catch { }

        } catch (err) {
            helper.showMessage(err, "err")
        }
    }

    // on screen sharing 
    async function startShareScreen() {
        let token = null;
        let userRole = "subscriber"
        if (helper.getRoleType() == "tutor") {
            userRole = "publisher";
        }
        store.setScreenShare(true);
        const accessUser = helper.getRoleType() == "user" ? "student" : "tutor"
        if (videoToken) {
            token = videoToken
            try {
                enableScreen();
                try {
                    await screenClient.join(
                        appId,
                        param.channelName,
                        token,
                        screenShareId
                    )
                } catch { }

                try {
                    screenTrack = await AgoraRTC.createScreenVideoTrack({
                        // screenSourceType: 'screen' // 'screen', 'application', 'window' firefox
                        encoderConfig: "1080p_1",
                        optimizationMode: "detail"
                    });
                } catch { }
                store.setScreenShare(false);
                try { await screenClient.publish(screenTrack); } catch { }
                store.setScreenShare(false);
                updateLayout({ layout: "verticle" })
            } catch (err) {
                videoToken = ''
                setIsScreenShare(false)
                store.setScreenShare(false);
            }

        } else {
            await agoraController.getScreenShareToken(param.channelName, userRole, screenShareId, accessUser).then(async (res) => {
                if (res.data.status) {
                    token = res.data.result.token;
                    videoToken = res.data.result.token;
                    enableScreen();
                    await screenClient.join(
                        appId,
                        param.channelName,
                        token,
                        screenShareId
                    );
                    screenTrack = await AgoraRTC.createScreenVideoTrack({
                        encoderConfig: "1080p_1",
                        optimizationMode: "detail"
                    });
                    store.setScreenShare(false);
                    await screenClient.publish(screenTrack);
                    store.setScreenShare(false);
                    updateLayout({ layout: "verticle" })
                }
            }).catch((err) => { store.setScreenShare(false); setIsScreenShare(false) });
        }



    }
    //  Join class 
    const joinClass = async (appId, token) => {
        try {
            store.setVideoRoom(true);
            setIsMicOn(true);
            setIsVideoOn(true);
            await joinRoom({
                appId: appId,
                token: token,
                uid: parseInt(helper.getid()),
                channel: param.channelName
            });
            // start()
            // SetShowFirstChat(true)
        }
        catch (err) {
            setState({ ...state, data: null, err: err, pageload: false })
            helper.showMessage(err, "error")
        }
    }

    //Step 5 : Update layout on Screen Sharing
    const stopRecording = async () => {
        try {
            helper.removeRecordingDetails()
            await recording.stopRecording({ channelName: param.channelName, uid: recordingUserId.toString(), appId: appId, resourceId: resourceId, sid: sid })
        } catch (err) {

        }
    }

    return (
        <div className="class-room-container all-center">
            <div id="fullscreen" className="class-room px-1 py-2 w-100 mx-auto" style={{ maxWidth: '1800px', minWidth: '350px' }}>
                {!state.pageload && !state.err ?
                    <div className="row h-100 gx-0 mx-0">
                        <div className={`${showChat ? "col-lg-8 col-md-7 col-12" : "col-12"}  bg-white w-100 d-flex video-room`} style={{ overflow: 'hidden' }} >
                            <LeftStrip />

                            <section id="drag-control" className="w-100 p-rel ltr">
                                {/* timer container */}
                                <section className="timer p-abs py-1 px-4  all-center" style={{ zIndex: '5', top: '10px', right: '10px' }}>
                                    <div className={`${storeData.liveClassDetails.isRecordingStart && "recording-animation"} recordin-Icon  d-inline-block mx-2 rounded-circle`}> </div>
                                    <div className="br-1 ms-700 text-white fs-24">{tutorHelper.makeTwoDigit(minutes)} : {tutorHelper.makeTwoDigit(seconds)}</div>
                                </section>


                                {/* host video container */}
                                <div id="remote-container">
                                    <div id="user-video" className="remote-user"></div>
                                </div>

                                {/* video footer container */}
                                <div className="call-footer p-abs py-1 bt-0  w-100">
                                    <div className={`video-controller`}>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Mic on/Off" >
                                            <i
                                                className={`fas ${isMicOn ? "fa-microphone" : "fa-microphone-slash icon-red"
                                                    } video-icon`}
                                                onClick={() => {
                                                    micControl(!isMicOn);
                                                    setIsMicOn(!isMicOn)
                                                }}
                                            ></i>
                                        </Tooltip>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Fullscreen" >
                                            <i
                                                className={`fas fa-expand-arrows-alt d-lg-flex d-md-flex d-none video-icon`}
                                                onClick={() => fullScreen(!fullScreenEnable)}
                                            ></i>
                                        </Tooltip>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Call end" >
                                            <img className='class-end-icon video-icon' src={callEndImg} alt="end-call"
                                                onClick={() => {
                                                    leaveRoom();
                                                    if (helper.getRoleType() === "tutor") {
                                                        stopRecording();
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Video on/Off" >
                                            <i
                                                className={`fas ${isVideoOn ? "fa-video" : "fa-video-slash icon-red"
                                                    } fa-video video-icon`}
                                                onClick={() => {
                                                    videoControl(!isVideoOn);
                                                    setIsVideoOn(!isVideoOn);
                                                }}
                                            ></i>
                                        </Tooltip>


                                        {helper.getRoleType() === "tutor" &&
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: '#434943',
                                                            padding: '5px !important',
                                                            margin: '5px !important'
                                                        },
                                                    },
                                                }}
                                                title="Screen share" >
                                                <i
                                                    className={`far ${!isScreenShare ? "fa-share-square" : "fa-stop-circle"
                                                        } video-icon `}
                                                    onClick={() => {
                                                        if (isScreenShare) {
                                                            stopSharing();
                                                            setIsScreenShare(false)
                                                            store.setScreenShare(false)
                                                        } else {
                                                            setIsScreenShare(true)
                                                            startShareScreen();
                                                            store.setScreenShare(true)
                                                        }
                                                    }}
                                                ></i>
                                            </Tooltip>
                                        }
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Instructions" >
                                            <i className="fas fa-question d-lg-flex d-md-flex d-none video-icon" onClick={() => setOpenInstruction({ ...openInstruction, open: true, type: 'user' })}> </i>
                                        </Tooltip>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: '#434943',
                                                        padding: '5px !important',
                                                        margin: '5px !important'
                                                    },
                                                },
                                            }}
                                            title="Show/Hide chat" >
                                            <span className="video-icon d-lg-flex d-md-flex d-none">
                                                <Badge badgeContent={liveData.count} color="primary">
                                                    <FaComment className=" m-2 pointer" onClick={() => { SetShowChat(!showChat); setLiveData({ ...liveData, count: showChat ? liveData.count : 0 }) }} size={20} />
                                                </Badge>
                                            </span>
                                        </Tooltip>
                                        <span className="video-icon d-lg-none d-md-none d-flex">
                                            <Badge badgeContent={liveData.count} color="primary">
                                                <FaComment className="m-2 pointer" onClick={() => setShowMobileChat(true)} size={20} />
                                            </Badge>
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* chat box  */}
                        <div className={`${showChat ? "col-lg-4 col-5 d-lg-block d-md-block d-none" : "d-none"} px-3`}>
                            {showFirstChat && <ChatBox
                                isAttendanceMark={() => setIsAttendanceMark({ ...isAttendanceMark, mark: true })}
                                studentId={state.data && remoteId}
                                data={state.data}
                                onClassEnd={() => {
                                    leaveRoom();
                                    if (helper.getRoleType() === "tutor") {
                                        stopRecording();
                                    }
                                }}
                                myId={helper.getid()}
                                roomId={param.channelName}
                                chatBotFor="InstantClass"
                            />
                            }
                        </div>
                    </div>
                    :
                    <div className="my-4 h-100 all-center flex-column">
                        <h3 className="my-2 text-center ms-900">{state.err}</h3>
                        <img style={{ width: '200px' }} src="https://www.scudamores.com/assets/damsel/assets/img/load.gif" alt="gif" />
                        <button className="btn bg-sky ms-700 py-2 px-3 text-white" onClick={() => history.goBack()}>RETURN BACK</button>
                    </div>
                }
            </div >

            {/* Handle Side Effects */}
            {storeData.videoRoom.load && <Backdrop />}
            <Loader open={storeData.screenShare} />

            {/* Open Feedback form */}
            <FeedBack
                open={feedBack.open}
                tutorId={feedback_tutor_id}
                cb={() => {
                    setFeedBack(false);
                    if (helper.getRoleType() === "tutor") {
                        history.push('/tutor/dashboard/home')
                    } else {
                        history.push('/user/dashboard/home')
                    }
                }} />

            {/* Instruction */}
            <Instruction
                open={openInstruction.open}
                type={openInstruction.type}
                onClose={() => setOpenInstruction({ ...openInstruction, open: false })}
            />

            {/* Handle mobile chat  */}

            {showMobileChat && <ChatOnMobile
                isAttendanceMark={() => setIsAttendanceMark({ ...isAttendanceMark, mark: true })}
                studentId={state.data && remoteId}
                data={state.data}
                onClassEnd={() => {
                    leaveRoom();
                    if (helper.getRoleType() === "tutor") {
                        stopRecording();
                    }
                }}
                myId={helper.getid()}
                roomId={param.channelName}
                chatBotFor="InstantClass"
                onClose={() => setShowMobileChat(false)}
            />
            }

            {/* Ask for Course Study in Class */}
            {/* {(helper.getRoleType() === "tutor" && isAttendanceMark.isClassEnd && !isAttendanceMark.mark) && <AskForCourse channelName={channelName} onSubmit={() => history.push('/tutor/dashboard/home')} />} */}

            <LiveNotification
                roomId={param.channelName}
                showChat={showChat}
                myId={helper.getid()}
                onMessage={(count) => setLiveData({ ...liveData, count: count })} />
        </div >


    );
}
