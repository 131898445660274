/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Backdrop from '../component/Backdrop'
import ChatBox from '../component/ChatBox'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import { useSelector } from 'react-redux'
import avatar from '../static/images/avatar.png'
import user from '../controller/user'
import { useHistory } from 'react-router-dom'
import store from '../redux/AppState'
import ChatOnMobile from './ChatOnMobile'
import { FaTimes, FaSearch } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
function UserChat() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const [chatOnMobile, setChatOnMobile] = useState({
        open: false,
        tutor_id: -1,
        room_id: -1,
        name: ''
    })
    const history = useHistory()
    const [upcomingClassData, setUpcomingClassData] = useState('')
    const [upcomingCall, setUpcomingCall] = useState([])
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState([])
    const [countList, setCountList] = useState([])
    const [state, setState] = useState({
        searchData: []
    })
    const [query, setQuery] = useState()

    const [chat, setChat] = useState({
        open: false,
        student_id: -1,
        tutor_id: -1,
        room_id: -1,
        name: ''
    })

    const chatRoomData = {
        tutor_id: storeData.chatId,
        student_id: helper.getid(),
    }

    const search = (qry) => {
        if (qry) {
            let regEx = new RegExp(qry, 'si')
            const res = resData.filter((result) => {
                return (result.name && (result.name).match(regEx))
            })
            setState({ ...state, searchData: res })
        }
        else {
            setState({ ...state, searchData: resData })
        }
    }
    useEffect(() => {
        getTutors()
        return () => { }
        //react-hooks/exhaustive-deps
    }, [])



    const getTutors = async () => {
        await user.getChatTutorList(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, searchData: res.data.result })
                    setResData(res.data.result)
                    let list = []
                    res.data.result.forEach((data) => {
                        let count = data.count.user ? data.count.user : 0
                        list.push(count)
                    })
                    setCountList(list)
                    if (res.data.result.length > 0) {
                        if (storeData.chatId != 0) {
                            focusOnChat(res.data.result)
                        } else {
                            setChat({
                                name: res.data.result[0].name,
                                open: true,
                                tutor_id: res.data.result[0].tutor_id,
                                room_id: res.data.result[0].chat_room_id
                            })
                            upcomingClasses(res.data.result[0].tutor_id)
                        }
                    }
                    let roomExist = false
                    res.data.result.forEach((data) => {
                        if (data.student_id == helper.getid() && data.tutor_id == storeData.chatId) {
                            roomExist = true
                        }
                    })
                    if (!roomExist) {
                        if (storeData.chatId != 0) {
                            createRoom()
                        }
                    }
                }
                setPageload(false)
            }).catch((err) => {
                setPageload(false)
            })
    }


    const upcomingClasses = async (tutor_id) => {
        await user.getTutorUpcomingClasses(tutor_id, helper.getid())
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result[0] && res.data.result[0].bookingSlot.forEach((data) => {
                        if (moment(data.end_time, 'hh:mm').format('HH:mm') >= moment(new Date()).format("HH:mm") && moment(data.date).format('YYYY-MM-DD') >= moment(new Date()).format("YYYYY-MM-DD") && data.status === "Booked") {
                            list.push(data)
                        }
                    })
                    setUpcomingCall(list)
                    setUpcomingClassData(res.data.result > 0 ? res.data.result[0] : [])
                }
            })
    }

    const createRoom = async () => {
        await tutor.createChatRoom(chatRoomData)
            .then((res) => {
                if (res.data.status) {
                    getTutors()
                }
            }).catch((err) => { })
    }

    const focusOnChat = (data) => {
        if (storeData.chatId) {
            const res = data.filter((result) => {
                return result.tutor_id == storeData.chatId
            })
            if (res.length == 1) {
                setChat({
                    ...chat,
                    open: true,
                    name: res[0].name,
                    tutor_id: res[0].tutor_id,
                    room_id: res[0].chat_room_id
                });
                upcomingClasses(res.tutor_id)
            }
            store.setchatId(0)
        }
    }

    return (
        <div className={`user-chat tutor-library ${storeData.language === 'ar' && "text-right"}`}>
            <section className="row gx-0 mx-0">
                <div className="col-lg-3 col-md-5 chat-user-list-box hide-scroll">
                    {resData.length !== 0 && <div className='p-rel mb-3'>
                        <div className="d-flex w-100 align-items-center px-1 mb-2">
                            <input type="text" maxLength={16} placeholder={t('general.courses.searchTutor')} value={query}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        const reg = new RegExp(/^[a-zA-Z ,.-]*$/gi)
                                        const res = reg.test(e.target.value.replace(/\n/g, " "));
                                        if (res) {
                                            search(e.target.value);
                                            setQuery(e.target.value)
                                        }
                                    } else {
                                        search("");
                                        setQuery("")
                                    }
                                }}
                                className="w-100 search-box ms-700 px-2 py-2 fs-14" />
                            <FaSearch size={22} style={{ right: '10px' }} className='mx-2 inactive-icon-color p-abs d-lg-none d-md-none  pointer pointer mt-1' />
                            {query && <FaTimes onClick={() => { setState({ ...state, searchData: resData }); setQuery('') }} size={22} style={{ right: '40px' }} className='mx-2 inactive-icon-color p-abs d-lg-none d-md-none  pointer pointer mt-1' />}
                        </div>
                    </div>}
                    {
                        state.searchData.map((result, index) => {
                            return (
                                <section key={index} className={`chat-student-card tx-lt-grey ${result.tutor_id === chat.tutor_id && "active-tutor-card"} d-flex b-grey p-2 align-items-center mb-2`}
                                    onClick={() => {
                                        setChat({
                                            ...chat,
                                            open: true,
                                            name: result.name,
                                            tutor_id: result.tutor_id,
                                            room_id: result.chat_room_id
                                        });
                                        setChatOnMobile({
                                            ...chatOnMobile,
                                            open: true,
                                            name: result.name,
                                            tutor_id: result.tutor_id,
                                            room_id: result.chat_room_id
                                        });

                                        let list = [...countList]
                                        list[index] = 0
                                        setCountList(list)
                                        upcomingClasses(result.tutor_id)
                                    }}>
                                    <div className='p-rel mr-2'>
                                        <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={result.profilePicture ? result.profilePicture : avatar} alt="avatar" />
                                        <div className={`${result.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                                    </div>
                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                        <div className="mr-3">
                                            <p className="f-fred ">{result.name ? result.name : "Anonymous"}</p>
                                            <p className="ms-700 lh-18 fs-12">You have
                                                {countList[index] == 0 ?
                                                    <span className="ms-700 tx-grey"> no new messages</span>
                                                    : <span className="ms-700 tx-green"> {countList[index]} unread messages</span>
                                                }
                                            </p>
                                        </div>
                                        {countList[index] != 0 &&
                                            <div style={{ width: '30px', height: '30px' }} className='bg-yellow rounded-circle ms-700 fs-14 all-center'><span>{countList[index]}</span></div>}
                                    </div>
                                </section>
                            )
                        })
                    }
                </div>

                <div className="col-lg-5 col-md-7 mb-2 d-lg-block d-md-block d-none">
                    {chat.open && <ChatBox userName={chat.name} myId={helper.getid()} roomId={chat.room_id} remoteUserId={chat.tutor_id} />}
                    {resData.length === 0 &&
                        <div>
                            <p className='ms-700 my-2 text-center'>{t('messages.chatEmpty')}</p>
                            <button className='btn btn-chat py-2 ms-700 d-block mx-auto' onClick={() => history.push('/user/dashboard/tutor')} style={{ width: '150px' }}>{t('button.startChat')}</button>
                        </div>
                    }
                </div>
                <div className="col-lg-4 col-md-7 mt-2" >
                    <h4 className="f-fred  tx-lt-grey">{t('general.calendar.upcomingClasses')}</h4>
                    {
                        upcomingClassData && upcomingCall.map((result, index) => {
                            return (
                                <div key={index} className="b-grey px-2 py-2 f-robo my-2">
                                    <div className="row gx-0 ">
                                        <div className="col-lg-7 col-7">
                                            <p className="fs-12 lh-15 ms-500">{t('tutorDash.calendar.scheduleOn')}</p>
                                            <p className="ms-900 lh-15 fs-14">{moment(result.date).format('MMM, DD YYYY')}, {moment(result.start_time, 'hh:mm').format("hh : mm A")}</p>
                                        </div>
                                        <div className="col-lg-5 col-5 d-flex justify-content-end align-items-center">
                                            {helper.isUserCanJoinClass({ classDate: result.date, startTime: result.start_time }) &&
                                                <span className="px-1 py-1 fs-10 reshedule-btn f-fred pointer text-upper" onClick={() => {
                                                    if (result.status === "Booked") {
                                                        history.push(`/dhad-video/${result.channel_name}`)
                                                    }
                                                }}> {result.status === "Booked" && t('general.joinClass')}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex gx-0 mt-2 ">
                                        <div className="mr-2">
                                            <img style={{ height: '40px', width: '40px' }} className="rounded-circle" src={upcomingClassData.profilePicture ? upcomingClassData.profilePicture : avatar} alt="avatar" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <p className="f-fred lh-15 fs-14">{upcomingClassData.name}</p>
                                            <p className="fs-12 lh-15">From {upcomingClassData?.from || upcomingClassData?.country}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }



                    {
                        upcomingCall.length === 0 &&
                        <section>
                            <img className="br-1" style={{ maxHeight: '300px', maxWidth: '100%' }} src="https://cdn.pixabay.com/photo/2015/10/31/11/58/call-center-1015274_960_720.jpg" alt="calls" />
                            <button className='btn bg-yellow d-block mx-auto my-2 schedule-btn ms-700 px-2 py-2 fs-14' onClick={() => history.push('/user/dashboard/tutor')}> <i className='fas fa-plus text-upper'></i> {t('userDash.header.scheduleClass')}</button>

                        </section>
                    }

                    {
                        chatOnMobile.open && <ChatOnMobile cb={() => setChatOnMobile({ ...chatOnMobile, open: false })} userName={chatOnMobile.name} myId={helper.getid()} roomId={chatOnMobile.room_id} remoteUserId={chat.tutor_id} />
                    }

                </div>
            </section>
            {pageload && <Backdrop />}
        </div>
    )
}

export default UserChat
