import React, { useState, useEffect, useRef } from 'react'
import SupportTicketPopup from './SupportTicketPopup'
import helper from '../helper/index';
import tutor from '../controller/tutor';
import Backdrop from '../component/Backdrop';
import TicketDetails from './TicketDetails';
import { useHistory } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa'
import moment from 'moment';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function OpenTickets() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const [openPop, setOpenPop] = useState(false)
    const [showDetail, setShowDetail] = useState({ open: false, data: '' })
    const [pageLoad, setPageload] = useState(true)
    const [resData, setResData] = useState([])
    const [searchData, setSearchData] = useState([])
    const query = useRef()
    const history = useHistory()

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const searchQuery = (qry) => {
        if (qry) {
            let regEx = new RegExp(qry, 'si')
            const res = resData.filter((data) => {
                return (data.id.toString()).match(regEx) || (data.category.toString()).match(regEx) ||  (data.sub_category.toString()).match(regEx)
            })
            setSearchData(res)
        }
        else {
            setSearchData(resData)
        }
    }

    const apiCall = async () => {
        await tutor.getTicket(helper.getid())
            .then(async (res) => {
                if (res.data.status) {
                    /* Sort all tickets */
                    let list  = res.data.result.sort((a,b)=>a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0)
                    setSearchData(list)
                    setResData(list)
                }
                setPageload(false)
            })


    }

    return (

        <div className={`open-tickets ${reduxState.language === 'ar' && "text-right"}`}>
            {!showDetail.open ?
                <>
                    <div className="row mb-2">
                        <div className="col-lg-12 d-flex align-items-center">
                            <button className="btn back-btn py-1 fs-14 f-fred ls px-3 mr-2" onClick={() => history.goBack()}>{t('general.back')}</button>
                            <div className='mx-2'>
                                <p className="lh-21 f-fred fs-18">{t('general.support.supportText')} <span className="tx-green">{t('general.support.ticket.ticket')}</span></p>
                                <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)}  {t('general.support.supportText')}</p>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={(e) => { e.preventDefault(); searchQuery(query.current.value) }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 d-flex w-100 align-items-center col-12 px-1 mb-2">
                                <input pattern='[a-zA-Z0-9 .-]*' title='Please enter characters only' maxLength={16} ref={query} type="search" placeholder={t('general.support.supportText') + " " + t('general.support.ticket.ticket')}  className="w-100 search-box ms-700 px-2 py-2 fs-14" />
                                <FaSearch size={22} style={{ right: '10px' }} className='mx-2 inactive-icon-color p-abs d-lg-none d-md-none  pointer pointer mt-1' />
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2 d-lg-block d-md-block d-none">
                                <button type="submit" className="btn btn-login w-100  wrap ">{t('general.search')}</button>
                            </div>
                            <div className="col-lg-4 d-lg-block d-none"></div>
                            <div className="col-lg-3 col-8 mb-2 d-flex  justify-content-lg-end">
                                <button type="button" className="btn btn-tutor-profile  wrap " onClick={() => setOpenPop(true)}>{t('general.support.ticket.newTicket')}</button>
                            </div>
                        </div>
                    </form>

                    {
                        !pageLoad && searchData.map((ticket) => {
                            return (
                                <section key={ticket.id} className={`notify-card b-grey tx-lt-grey p-2 py-3 my-2 row gx-0 mx-0 fs-14 ms-500`} onClick={() => setShowDetail({ ...showDetail, open: true, data: ticket })}>
                                    <div className="col-lg-1 col-md-2 col-6 all-center mb-1">
                                        {ticket.status === "Open" ?
                                            <button style={{ height: '39px' }} className={`btn btn-new-msg w-100 fs-16 py-1 ms-700`}>{ticket.count.user ? ticket.count.user + " " + t('general.message'): " " +t('general.open')}</button>
                                            : <button style={{ height: '39px' }} className={`btn btn-resolved w-100 fs-16 py-1 ms-700`}>{t('general.resolved')}</button>
                                        }
                                    </div>
                                    <div className="col-lg-1 col-md-4 col-6 px-2 mb-1">
                                        <p className="f-fred">{ticket.id}</p>
                                        <p className="ms-500  tx-lt-grey lh-15">{t('general.support.ticket.ticketId')}</p>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-12 mb-1">
                                        <p className="f-fred text-capitalize">{ticket.category}</p>
                                        <p className="ms-500  tx-lt-grey lh-15">{t('general.category')}</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 mb-1">
                                        <p className="f-fred text-capitalize">{ticket.sub_category}</p>
                                        <p className="ms-500  tx-lt-grey lh-15">{t('general.subCategory')}</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-6 mb-1 pr-2 d-flex flex-column align-items-lg-end">
                                        <p className="f-fred ltr">{moment(ticket.created_at).format('DD MMM, YYYY')}</p>
                                        <p className="ms-500  tx-lt-grey lh-15">{t('general.createdOn')}</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-6 mb-1  d-flex flex-column align-items-lg-end pr-2">
                                        <p className="f-fred ltr">{moment(ticket.created_at).format('hh:mm a')}</p>
                                        <p className="ms-500 tx-lt-grey lh-15">{t('general.time')}</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 d-lg-flex d-md-flex d-none  px-1 justify-content-center align-items-center">
                                        <button className="btn btn-detail w-100 py-1 ms-900 text-white fs-14" onClick={() => setShowDetail({ ...showDetail, open: true, data: ticket })}>{t('general.details')}</button>
                                    </div>
                                </section>
                            )
                        })
                    }

                </> :
                <TicketDetails
                    close={() => setShowDetail({ ...showDetail, open: false })}
                    data={showDetail.data}
                    onUpdate={() => apiCall()}
                />}
            {pageLoad && <Backdrop />}
            {openPop && <SupportTicketPopup close={() => { setOpenPop(false); apiCall() }} />}
        </div>
    )
}
