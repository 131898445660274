/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import user from '../controller/user'
import SvgDownArrow from '../Icons/DownArrow'
import helper from '../helper'
import { Tooltip, Menu } from '@mui/material'
export default function ReferralClasses({ onMouseOver, isShow }) {
    const [show, setShow] = useState(false)
    const history = useHistory()
    const [state, setState] = useState({
        data: [],
        load: true,
        error: '',
        ref: null
    })

    useEffect(() => {
        if (!isShow) {
            setShow(false)
        } else {
            getReferralClasses()
            setShow(show)
        }
    }, [isShow])


    const getReferralClasses = async () => {
        await user.getReferralClasses(helper.getid()).then((res) => {
            if (res.data.status) {
                setState({ ...state, data: res.data.result, load: false })

            } else {
                setState({ ...state, data: [], load: false })
            }
        })
    }
    return (
        <>{
            !state.load && state.data.length > 0 &&
            <div >
                <Tooltip title="Free classes">
                    <div className='pointer' >
                        <button onClick={(e) => { onMouseOver(); setShow(true); setState({ ...state, ref: e.currentTarget }) }} className='w-100 ms-700 left-minutes-btn fs-14 px-2 py-1'> Free clasess<SvgDownArrow className="ml-2" /></button>
                    </div>
                </Tooltip>
                <Menu
                    anchorEl={state.ref}
                    open={show}
                    onClose={(e) => { setState({ ...state, ref: e.currentTarget }); setShow(false) }}
                >
                    <section className='free-classes p-2' onMouseLeave={() => { setShow(false) }}>
                        <div className='bg-white my-2 p-3 '>
                            <h4 className='ms-700'>Free lessons</h4>

                            {
                                state.data.map((data) => {
                                    return (
                                        <div className=' b-grey mt-2 p-2 '>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='tx-lt-grey ms-700'>Start {data.referral_minutes} minutes lesson</p>
                                                <button className='btn bg-sky  px-2 text-white ms-700' onClick={() => history.push(`/user/dashboard/tutor-by-referral/${data.id}`)}>Start</button>
                                            </div>
                                            <p className='ms-500 tx-lt-grey mt-2 fs-14'>Expired on {moment(data.expiry_date).format('MMM DD, YYYY')}</p>
                                        </div>
                                    )
                                })
                            }

                        </div>


                    </section>
                </Menu>
                {/* {show &&
                    <section className='p-abs left-minute-popup p-2 mt-2' onMouseLeave={() => { setShow(false) }}>
                        <div className='bg-white my-2 p-3 '>
                            <h4 className='ms-700'>Free lessons</h4>

                            {
                                state.data.map((data) => {
                                    return (
                                        <div className=' b-grey mt-2 p-2 '>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='tx-lt-grey ms-700'>Start {data.referral_minutes} minutes lesson</p>
                                                <button className='btn bg-sky  px-2 text-white ms-700' onClick={() => history.push(`/user/dashboard/tutor-by-referral/${data.id}`)}>Start</button>
                                            </div>
                                            <p className='ms-500 tx-lt-grey mt-2 fs-14'>Expired on {moment(data.expiry_date).format('MMM DD, YYYY')}</p>
                                        </div>
                                    )
                                })
                            }

                        </div>


                    </section>
                } */}

            </div>

        }


        </>
    )
}
