import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import '../static/css/testimonial.css'

export default function FaqAccordion({ data, category }) {
    const reduxState = useSelector(state => state)
    const [show, setShow] = useState(false)
    const { t } = useTranslation();
    const ans = useRef()
    const toggle = () => {
        const div = ans.current
        div.classList.toggle("web-user-testimonial-ans-active");
        setShow(!show)
    }


    return (
        <div className="fs-14 b-grey p-2 mb-2">
            <div className="row">
                <div className="col-lg-10 col-md-10 col-9 mb-2">
                    <p className="f-fred text-capitalize">{reduxState.language === 'ar' ? data.arabic_question : data.question}</p>
                    <p className="ms-500 tx-lt-black fs-12">{category}</p>
                </div>
                <div className="col-lg-2 col-md-2 col-3 mb-2 d-flex justify-content-end align-items-center">
                    <button className="btn btn-detail px-2 py-1 fs-14 text-white ms-900 w-100" onClick={toggle}>{show ? t('general.hide') : t('general.details')}</button>
                </div>
                <div ref={ans} className="col-12 web-user-testimonial-ans">
                    <div className="text-justify ms-300  tx-lt-grey" dangerouslySetInnerHTML={{ __html: reduxState.language === 'ar' ? data.arabic_answer : data.answer }}>
                    </div>
                </div>
            </div>
        </div>
    )
}
