/* eslint-disable */
import React, { useState, useEffect } from 'react'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import Testimonial from '../component/Testimonial'
import pops from '../component/PopUps'
import store from '../redux/AppState'
import Menubar from './Menubar'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { fileUploadToS3 } from '../helper/utils'

export default function MyProfile() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const [openPop, setOpenPop] = useState({ name: '', open: false })
    const [basicInfo, setBasicInfo] = useState()
    const [workExp, setworkExp] = useState([])
    const [education, setEducation] = useState([])
    const [language, setLanguage] = useState([])
    const [pageload, setPageload] = useState(true)
    const [certificate, setCertificate] = useState([])
    const [bankInfo, setBankInfo] = useState()
    const [introVideo, setIntroVideo] = useState()
    const [testimonial, setTestimonial] = useState([])
    const [pageNo, setPageNo] = useState({ page: 1, hide: false })


    useEffect(() => {
        Promise.race([
            getBasicInfo(),
            getWorkExperience(),
            getEducation(),
            getLanguage(),
            getBankInfo(),
            getCertificate(),
            getTestimonials(pageNo.page)])
            .then(() => {
                setPageload(false)
            })
        return () => { }
    }, [])


    const getBasicInfo = async () => {
        await tutor.getBasicInfo(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setBasicInfo(res.data.result)
                    store.setProfilePic(res.data.result && res.data.result.profilePicture && res.data.result.profilePicture)
                    store.setFlag(res.data.result && res.data.result.flag_uri)
                    store.setName(res.data.result && res.data.result.name)
                }
            })
    }

    const getTestimonials = async (page) => {
        let list = testimonial
        await tutor.tutorTestimonials(helper.getid(), page, 5)
            .then((res) => {
                if (res.data.status) {
                    list = list.concat(res.data.result)
                    setTestimonial(list)
                    if (res.data.result.length < 5) {
                        setPageNo({ ...pageNo, hide: true })
                    }
                }
            })
    }

    const getWorkExperience = async () => {
        await tutor.getworkExperience(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setworkExp(res.data.result)

                }

            })
    }

    const getEducation = async () => {
        await tutor.getEducation(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setEducation(res.data.result)

                }

            })
    }

    const getLanguage = async () => {
        await tutor.getLanguage(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setLanguage(res.data.result)

                }

            })
    }

    const getCertificate = async () => {
        await tutor.getCertificate(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setCertificate(res.data.result)

                }
            })
    }

    const getBankInfo = async () => {
        await tutor.getBankInfo(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setBankInfo(res.data.result)

                }
            }).catch((err) => {
            })
    }
    const delWork = async (id) => {
        setPageload(true)
        await tutor.deleteWorkExperience({ tutorId: helper.getid(), id: id })
            .then((res) => {
                if (res.data.status) {
                    getWorkExperience()
                    setPageload(false)

                }

            })
    }

    const delEducation = async (id) => {
        setPageload(true)
        await tutor.deleteEducation({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    getEducation()
                    setPageload(false)

                }

            })
    }
    const delLanguage = async (id) => {
        setPageload(true)
        await tutor.delLanguage({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    getLanguage()
                    setPageload(false)

                }

            })
    }

    const delCertificate = async (id) => {
        setPageload(true)
        await tutor.delCertificate({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    getCertificate()
                    setPageload(false)

                }

            })
    }

    const uploadIntroVideo = async () => {
        const form = new FormData()
        form.append('tutorId', helper.getid())
        if (!introVideo) {
            helper.showMessage(t('messages.selectIntroVideo'), "warning")
            return
        }
        if (introVideo && ((introVideo.size / 1024) / 1024) > 15) {
            helper.showMessage(t('messages.videoSize'), "warning")
            return
        }
        setPageload(true)
        const url = await fileUploadToS3("dhad_tutor_intro_video", introVideo)
        form.append('fileURL', url)

        await tutor.addIntroVideo(form)
            .then((res) => {
                if (res.data.status) {
                    setIntroVideo('')
                }
                setPageload(false)
            }).catch((err) => { setPageload(false) })
    }

    return (
        <div className={`tutor-profile tutor-dash ${storeData.language === 'ar' && "text-right"}`}>


            {!pageload ?
                <div className="row gx-0 mx-0">
                    <div className="col-lg-5  col-12">
                        <section className='d-flex flex-column align-items-center justify-content-center'>
                            {basicInfo?.profileVideo && !introVideo && <video className="br-1 bg-smoke" width="100%" height="230px" src={basicInfo.profileVideo} controls ></video>}
                            {introVideo && <video className="br-1 bg-smoke" width="100%" height="230px" src={URL.createObjectURL(introVideo)} controls ></video>}
                            {!introVideo && <label htmlFor="introvideo" className="w-75 btn all-center btn-tutor-profile my-2">{t('button.changeIntroVideo')}</label>}
                            {introVideo &&
                                <div className='all-center'>
                                    <label htmlFor="introvideo" className="w-50 btn all-center mx-2 btn-tutor-profile my-2">{t('button.changeIntroVideo')}</label>
                                    <button className="w-50 btn  btn-tutor-profile my-2 all-center" onClick={uploadIntroVideo}>{t('button.uploadIntroVideo')}</button>
                                </div>
                            }

                            <input id="introvideo" type="file" className="d-none" accept="video/*" onChange={(e) => {
                                if ((e.target.files[0].size / 1024) / 1024 > 15) {
                                    helper.showMessage(t('messages.videoSize'), "warning")
                                } else {
                                    setIntroVideo(e.target.files[0])
                                }
                            }} />
                        </section>

                        <section className="my-address p-2 py-3 tx-lt-grey br-1 my-3">
                            <div className="row gx-0 mx-0 f-fred mb-2">
                                <div className="col-lg-5 col-md-6 col-8 d-flex align-items-center">
                                    <h4>{t('userDash.profile.proDetail')}</h4>
                                </div>
                                <div className="col-lg-5 col-md-6 col-4 px-2 all-center">
                                    <button className="btn edit-info ms-700 fs-12 ml-auto d-block px-3 wrap" onClick={() => setOpenPop({ ...openPop, open: true, name: "profile" })}>{t('tutorSidebar.profile.editProfile')}</button>
                                </div>
                                <div className="col-lg-2 col-md-2 col-2 all-center flex-column align-items-end">
                                    <img style={{ height: '50px', width: '50px' }} className="rounded-circle" src={basicInfo && basicInfo.profilePicture} alt="pic" />
                                    <div className="fs-12 mt-1">
                                        {/* <i className="fas fa-star tx-yellow mr-1"></i> */}
                                        {/* <span>4.5</span> */}
                                    </div>
                                </div>
                            </div>

                            <div className="address-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('tutorSidebar.profile.fullName')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900 ml-auto text-capitalize">{basicInfo && basicInfo.name}</p>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('tutorSidebar.profile.email')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{helper.getEmail()}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('tutorSidebar.profile.dob')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{basicInfo && helper.getDOB(basicInfo.dateOfBirth)}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('tutorSidebar.profile.mobileNo')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{basicInfo && basicInfo.phoneNo}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('tutorSidebar.profile.country')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto text-capitalize">{basicInfo && basicInfo.from}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="my-address p-2 tx-lt-grey py-3 br-1 my-2">
                            <div className="row gx-0 mx-0 f-fred mb-2">
                                <div className="col-5 d-flex align-items-center">
                                    <h4>{t('userDash.profile.bankDet')}</h4>
                                </div>
                                <div className="col-7 px-1">
                                    <button className="btn edit-info fs-12 ms-700 ml-auto d-block px-3" onClick={() => setOpenPop({ ...openPop, open: true, name: "bank" })}>{t('tutorSidebar.bank.editBank')}</button>
                                </div>

                            </div>

                            <div className="address-body ">
                                <div className="row">
                                    <div className="col-4">
                                        <p className="ms-500">{t('tutorSidebar.bank.name')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{bankInfo && bankInfo.bankName}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-4">
                                        <p className="ms-500">{t('tutorSidebar.bank.account')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{bankInfo && bankInfo.accountNumber}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-4">
                                        <p className="ms-500">{t('tutorSidebar.bank.bic')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{bankInfo && bankInfo.bicNumber}</p>
                                    </div>

                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-4">
                                        <p className="ms-500">{t('tutorSidebar.bank.iban')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{bankInfo && bankInfo.iban}</p>
                                    </div>

                                </div>
                            </div>
                        </section>

                        {testimonial.length !== 0 &&
                            <section className="tutor-testimonial tx-lt-grey">
                                <h4 className="mt-3 mb-2  f-fred">{t('userDash.profile.test')}</h4>
                                {
                                    testimonial.map((result, index) => {
                                        return (
                                            <div key={`testimonial${index}`} className='mb-3'>
                                                <Testimonial
                                                    userName={result.name}
                                                    country={result.country}
                                                    date={result.createdOn}
                                                    rate={result.ratings}
                                                    review={result.review}
                                                    pic={result.profilePicture}
                                                />
                                            </div>
                                        )
                                    })
                                }

                                {!pageNo.hide && <button onClick={() => { setPageNo({ ...pageNo, page: pageNo.page + 1 }); getTestimonials(pageNo.page + 1) }} className='btn btn-login fs-14 f-fred my-3' style={{ height: '35px' }}>{t('button.loadMore')}</button>}
                            </section>
                        }


                    </div>
                    <div className="col-lg-7 cpx-4 tx-lt-grey">
                        <h4 className="f-fred mt-3 mb-2">{t('tutorOnBoard.basicInfo.about.heading')}</h4>
                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-9 col-md-8 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred ">{t('tutorOnBoard.basicInfo.generalInfo.intro')}</p>
                            </div>
                            <div className="col-lg-3 col-md-4 col-4 all-center  justify-content-end">
                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, open: true, name: "intro" })}>{t('tutorSidebar.intro.editIntro')}</button>
                            </div>
                            <div className="col-12 mt-2">
                                <p className="tx-lt-black ms-300 fs-16 text-justify">{basicInfo && basicInfo.introduction}</p>
                            </div>
                        </section>

                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-9  col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred ">{t('tutorOnBoard.basicInfo.about.style')}</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, open: true, name: "style" })}>{t('tutorSidebar.teaching.editStyle')}</button>
                            </div>
                            <div className="col-12 mt-2">
                                <p className="tx-lt-black ms-300 fs-16 text-justify">{basicInfo && basicInfo.teachingStyle}</p>
                            </div>
                        </section>


                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-9 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('tutorOnBoard.basicInfo.about.aboutyou')}</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info ms-700" onClick={() => setOpenPop({ ...openPop, open: true, name: "about" })}>{t('tutorSidebar.teaching.editAbout')}</button>
                            </div>
                            <div className="col-12 mt-2">
                                <p className="tx-lt-black ms-300 fs-16 text-justify">{basicInfo && basicInfo.aboutMe}</p>
                            </div>
                        </section>

                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-9 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('tutorOnBoard.basicInfo.about.lng')}</p>
                                <p className="ms-500 fs-12">Your accents & languages</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, open: true, name: "lang" })}>{t('tutorSidebar.lng.addLng')}</button>
                            </div>
                            <div className="col-12 mt-2 tx-lt-black mb-2">
                                {language.map((lng, index) =>
                                    <span key={index} className="px-2 py-1 b-grey ms-700 mx-1 mb-2 d-inline-block text-capitalize fs-16 all-center">{lng.language}( {lng.dialect}-{lng.fluency} ) <i className="far fa-times-circle pointer ml-1" onClick={() => delLanguage(lng.id)}></i></span>
                                )}

                            </div>
                        </section>
                        <h4 className="f-fred mt-3 mb-2">{t('tutorOnBoard.basicInfo.exp.heading')}</h4>


                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-9 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred tx-lt-black">{t('tutorOnBoard.basicInfo.exp.workExp')}</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info flex-wrap" onClick={() => { setOpenPop({ ...openPop, open: true, name: 'work' }) }}>{t('tutorSidebar.work.addWorkExp')}</button>
                            </div>
                            <div className="col-12 mt-2 tx-lt-black">
                                {
                                    workExp.map((work, index) => {
                                        return (

                                            <>
                                                <p className="ms-900 fs-12">{work.title} <i className="far fa-times-circle float-right fs-18 pointer mx-1" onClick={() => delWork(work.id)} ></i></p>
                                                <p className="lh-18 fs-12 ms-500">{work.from} To {work.to}</p>
                                                <p className="my-2 ms-300 fs-14">{work.description}</p>
                                            </>
                                        )
                                    })}

                            </div>
                        </section>


                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey f-robo px-3 my-2">
                            <div className="col-lg-9 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('tutorOnBoard.basicInfo.exp.edu')}</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info" onClick={() => { setOpenPop({ ...openPop, open: true, name: 'education' }) }}>{t('tutorSidebar.educ.addQualification')}</button>
                            </div>
                            <div className="col-12 mt-2 tx-lt-black ">
                                {education && education.map((work, index) => {
                                    return (
                                        <>
                                            <p className="ms-900 fs-12">{work.degree} ({work.from} <b>To</b> {work.to} ) <i className="far fa-times-circle float-right fs-18 pointer mx-1" onClick={() => delEducation(work.id)}></i></p>
                                            <p className="lh-18 ms-500 mb-3">{work.university}</p>
                                        </>

                                    )
                                })}
                            </div>
                        </section>

                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey f-robo px-3 my-2">
                            <div className="col-lg-9 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('tutorOnBoard.basicInfo.exp.cert')}</p>

                            </div>
                            <div className="col-lg-3 col-4 all-center  justify-content-end">
                                <button className="btn edit-info" onClick={() => { setOpenPop({ ...openPop, open: true, name: 'certificate' }) }}>{t('tutorSidebar.cert.addCert')}</button>
                            </div>
                            <div className="col-12  tx-lt-black mt-3">
                                {certificate && certificate.map((cert, index) => {
                                    return (
                                        <div key={index} className="mb-2">
                                            <p className="ms-900 fs-12 pointer" >{cert.title}  <i className="far fa-times-circle float-right fs-18 pointer mx-1" onClick={() => delCertificate(cert.id)}></i></p>
                                            <p className="ms-500"><a rel="noopener noreferrer" href={cert.file_name} target="_blank"> {cert.issue_authority} {cert.certificate_date}</a></p>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                        {
                            openPop.open && openPop.name === "work" ?
                                <pops.WorkExperiencePop
                                    cb={() => getWorkExperience()}
                                    close={() => setOpenPop({ name: '', open: false })} /> :
                                openPop.open && openPop.name === "education" ?
                                    <pops.EducationPop
                                        cb={() => getEducation()}
                                        close={() => setOpenPop({ name: '', open: false })} /> :
                                    openPop.open && openPop.name === "certificate" ?
                                        <pops.CertificatePop
                                            cb={() => getCertificate()}
                                            close={() => setOpenPop({ name: '', open: false })} /> :

                                        openPop.open && openPop.name === "intro" ?
                                            <pops.AddIntroPOP
                                                data={basicInfo}
                                                cb={() => getBasicInfo()}
                                                close={() => setOpenPop({ name: '', open: false })} /> :
                                            openPop.open && openPop.name === "lang" ?
                                                <pops.AddLanguagePOP
                                                    cb={() => getLanguage()}
                                                    close={() => setOpenPop({ name: '', open: false })} /> :
                                                openPop.open && openPop.name === "style" ?
                                                    <pops.AddTeachingPop
                                                        data={basicInfo}
                                                        cb={() => getBasicInfo()}
                                                        close={() => setOpenPop({ name: '', open: false })} /> :
                                                    openPop.open && openPop.name === "about" ?
                                                        <pops.AddAboutPOP
                                                            data={basicInfo}
                                                            cb={() => getBasicInfo()}
                                                            close={() => setOpenPop({ name: '', open: false })} /> :
                                                        openPop.open && openPop.name === "bank" ?
                                                            <pops.AddBankPop
                                                                data={bankInfo}
                                                                cb={(data) => setBankInfo(data)}
                                                                close={() => setOpenPop({ name: '', open: false })} /> :
                                                            openPop.open && openPop.name === "profile" ?
                                                                <pops.EditProfilePop
                                                                    data={basicInfo}
                                                                    cb={() => getBasicInfo()}
                                                                    close={() => setOpenPop({ name: '', open: false })} /> :


                                                                ''}
                    </div>
                </div > : <Backdrop />
            }
            {storeData.showMenu && <Menubar tab={11} cb={() => store.setShowMenu(false)} />}

        </div >
    )
}
