import Zoom from 'react-reveal/Zoom';
export default function OpenCertificate ({ close ,certificate}){
    return (
        <>
            <div className='popup-modal all-center' onClick={close} style={{ backgroundColor: 'rgba(114, 114, 114, 0.9)' }}>
                <Zoom center>
                    <section className='all-center p-rel'>
                        <img className='br-1 m-3' src={certificate} style={{ maxWidth: '100%', width: '100%' }} alt="certificate" />
                    </section>
                </Zoom>

            </div>
        </>)
}