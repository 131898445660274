import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import avatar from '../static/images/avatar.png'
import moment from 'moment';
import ChatPop from './ChatPop';
export default function PayoutDetailCard({ data }) {
    const { t } = useTranslation();

    /* Handle  state */
    const [state, setState] = useState({
        chatLogOpen: false,
        chatRoomId: null,
        name: '',
        pic: ''
    })

    /* Handle chat popup */
    const openChat = () => {
        setState({
            ...state,
            chatLogOpen: true,
            name: data.student_name,
            pic: data.student_prof_pic,
            chatRoomId: data.channel_name
        })
    }

    return (
        <section className={`notify-card b-grey p-2 tx-lt-grey my-2 row gx-0 mx-0 fs-14 ms-500`}>
            <div className="col-lg-3 wrap col-md-4 col-6 d-flex align-items-center mb-2">
                <img width="40px" height="40px" className="rounded-circle mr-2" src={data.student_prof_pic || avatar} alt="avatar" />
                <div className="mr-3">
                    <p className="f-fred ">{data.student_name}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.student')}</p>
                </div>
            </div>
            <div className="col-lg-1 d-flex align-items-center mb-2">
                <div className="mr-3">
                    <p className="f-fred ">{data.country}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.country')}</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-8 col-12 mb-2 d-flex align-items-center">
                <div className="mr-3">
                    <p className="f-fred">{data.course_name}</p>
                    <p className="ms-500">{t('tutorDash.payout.course')}</p>
                </div>
                <div className="mr-3">
                    <p className="f-fred">{data.call_duration}</p>
                    <p className="ms-500">{t('tutorDash.payout.callDur')}</p>
                </div>
                <div className="mr-2">
                    <p className="f-fred">{parseFloat(data.amount).toFixed(2)}</p>
                    <p className="ms-500">{t('tutorDash.payout.amount')}</p>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-9 mb-2  d-flex justify-content-lg-end  align-items-center pr-2">
                <div className="mr-3">
                    <p className="f-fred ">{moment(data.date).format('DD MMM YYYY')}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.schedule')}</p>
                </div>
                <div className="mr-2">
                    <p className="f-fred ">{moment(data.start_time, 'HH:mm:ss').format('mm:ss a')}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.time')}</p>
                </div>
            </div>
            <div className="col-lg-1 col-md-4 col-3 px-1 d-flex align-items-center">
                <button className="btn btn-detail w-100 py-1 f-fred fs-14" onClick={openChat} >{t('tutorDash.myStudent.chatLog')}</button>
            </div>

            {/* Chat Box */}

            {state.chatLogOpen &&
                <ChatPop
                    roomId={state.chatRoomId}
                    name={state.name}
                    pic={state.pic}
                    onClose={() => { setState({ ...state, chatLogOpen: false }) }}
                />
            }       
         </section>
    )
}
