import React from 'react'
import helper from '../helper/index'
import ReactStars from "react-rating-stars-component";
import avatar from '../static/images/avatar.png'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function TutorCard({ index, data, fav, addtoFavorite, removeFromFavorite, addProfileView,onClick}) {
  
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    return (
        <div className={`tutor-card b-grey mb-2 pointer p-2`}>
            <div onClick={() => { addProfileView(data.tutorId);onClick()}}>
                <div className='d-flex p-rel'>
                    <div className={`all-center ${reduxState.language === 'ar' ? "ml-3"  :"mr-3"}`}>
                        <div className='rounded-circle b-grey p-rel p-1'>
                            <img width="70px" height={'70px'} className='rounded-circle d-block mx-auto' src={data.profilePicture ? data.profilePicture : avatar} alt="avatar" />
                            <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0`} style={{ right: '8px' }}></div>
                        </div>
                    </div>
                    <div style={{overflow : 'hidden'}} className={`${reduxState.language === 'ar' && "text-right"}`}>
                        <p className='ms-900 fs-18 mt-2' >{data.name}</p>
                        <div className="d-flex align-items-center py-0 my-0 mr-1">
                            <ReactStars
                                edit={false}
                                value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                count={5}
                                size={18}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                classNames="my-0 py-0"
                                activeColor="#ffcb00"
                            />
                            <span className="tx-black ms-700 ml-2 fs-14">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img width={30} height={20} src={data.flag_uri} alt="flag" />
                            <span className={`${reduxState.language === 'ar' ? "mr-2" : "ml-2"} ms-500`}>{data.from}</span>
                        </div>
                        <span>{helper.getDateDifference(data.createdOn) < 30 && <p className={`${reduxState.language === 'ar' ? "new-ar-flag" : 'new-flag'} px-3 py-1 ms-500`}>{t('general.new')}</p>}</span>

                    </div>
                </div>
                <div className='mt-3' style={{ height: '80px', overflow: 'hidden' }}>
                    <p className='ms-500 my-2 h-100 overflow-text'>{data.introduction}
                    </p>
                </div>
            </div>
            <div className={`fs-24  ${reduxState.language === 'ar' ? "float-left" : "float-right"} d-inline-block`} style={{ height: '40px' }}>
                <div className=''>
                    {
                        fav.includes(index) ? <i className="fas tx-red hover-shadow fa-heart " onClick={() => removeFromFavorite(data.tutorId, index)}></i>
                            :
                            <i className="far fa-heart hover-shadow" onClick={() => addtoFavorite(data.tutorId, index)}></i>
                    }
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}
