/* eslint-disable */
import '../static/css/chatpop.css'
import Slide from 'react-reveal/Slide';
import React, { useEffect } from 'react'
import fb from '../config/firebaseAuth';
import helper from '../helper/index';
import { ref, onChildAdded, onValue } from "firebase/database";
import moment from 'moment';
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";
const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let unsubscribe = ''

const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-pop-body")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}
const addRemoteDiv = (message) => {
    try {
        const remoteDiv = document.getElementById("chat-pop-body")
        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}



export default function ChatPop({ roomId, onClose, name, pic }) {
    const { t } = useTranslation();
   
    useEffect(() => {
        const userDiv = document.getElementById("chat-pop-body")
        userDiv.innerHTML = ""
        rootRoomid = roomId
        enable()
        return () => {
            try {
                unsubscribe()
            }
            catch { }
        }
    }, [roomId])

    const enable = () => {
        let msgCount = 0;
        unsubscribe = onChildAdded(ref(db, `classChat/${rootRoomid}`), (data, index) => {
            const val = data.val()
            if (parseInt(val.createdBy) === parseInt(helper.getid())) {
                adduserDiv(val.message, val.createdAt)
            }
            else {
                addRemoteDiv(val.message)
            }
        })
    
        onValue(ref(db, `classChat/${rootRoomid}`), (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                msgCount++;
            });
            if (msgCount === 0) {
                addRemoteDiv(t('messages.noMessage'))
            }
        }, {
            onlyOnce: true
        });
    
    }

    return (<>
        <section className='w-100 h-100 chat-box chat-backdrop popup-modal all-center'>
            <Slide top>
                <div className='bg-white mb-3 br-1'>
                    <div className='bg-yellow d-flex justify-content-between p-2 py-2 ms-700 text-white' style={{ borderRadius: '10px 10px 0 0' }}>
                        <div className='d-flex align-items-center'>
                            <img width="40px" height={'40px'} className='rounded-circle mx-2' src={pic ? pic : avatar} alt="avatar" />
                            <p className='fs-18'>{name ? name : 'Unkwonun'}</p>
                        </div>
                        <i className='fas fa-times tx-white all-center fs-20 p-2 pointer' onClick={() => onClose()}></i>
                    </div>
                    <div id="chat-pop-body"
                        className="chat-room-body py-2 chat-body px-1"
                        style={{ height: '350px', width: '350px', borderRadius: '0 0 10px 10px', overflowY: 'auto', backgroundPosition: '130px', backgroundRepeat: 'none', backgroundImage: `url(https://mifcvsxwec.s3.amazonaws.com/The_effect_of_virtual_learning_on_parents_with_school-age_children-11-19-2020-15-16-24-218185.png)` }} >
                    </div>
                </div>
            </Slide>
        </section>
    </>);
}
