import React, { useState, useEffect } from 'react'
import ApexChart from './ApexChart'
import helper from '../helper/index'
import tutor from '../controller/tutor'
import Backdrop from '../component/Backdrop'
import moment from 'moment'
import { useHistory } from 'react-router'
import avatar from '../static/images/avatar.png'
import { useSelector } from 'react-redux'
import breakpoints from '../helper/breakpoints'
import Carousel from "react-multi-carousel";
import banner from "../static/images/banner.png";
import { useTranslation } from "react-i18next";

export default function TutorHome() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [anaData, setAnaData] = useState({ st: '', end: '' })
    const [resData, setResData] = useState([])
    const [pageload, setPageload] = useState(true)
    const [upcomingClassData, setUpcomingClassData] = useState([])
    const [earning, setEarnings] = useState({ months: [], amount: [], load: true })
  
    useEffect(() => {
        getAnalytics(moment().subtract(11, 'month'), new Date())
        upcomingClasses()
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const upcomingClasses = async () => {
        const currDate = new Date()
        let date = moment(currDate).format("YYYY-MM-DD")
        await tutor.getBookedStudentDetailsSlot(helper.getid(), date)
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result.forEach((data) => {
                        if (moment(data.end_time, 'hh:mm').format('HH:mm') >= moment().format("HH:mm")) {
                            list.push(data)
                        }
                    })
                    setUpcomingClassData(list)
                }
            })
    }

    const getAnalytics = async (startDate, endDate) => {
        let st = moment(startDate).format('YYYY-MM-DD')
        let en = moment(endDate).format('YYYY-MM-DD')
        setAnaData({ ...setAnaData, st: st, end: en })
        await tutor.getTutorAnalytics(helper.getid(), st, en)
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                    setPageload(false)
                    let months = [], amount = [], prevMonth = [], prevAmount = [], flag = 0
                    res.data.result.earningList.forEach((key) => {
                        if (moment(st).format('MMM') === key.month || flag === 1) {
                            months.push(key.month + " " + moment(st).format('YYYY'))
                            amount.push(parseFloat(key.amount).toFixed(2))
                            flag = 1
                        } else if (moment(st).format('MMM') !== key.month && flag === 0) {
                            prevMonth.push(key.month + " " + moment(en).format('YYYY'))
                            prevAmount.push(parseFloat(key.amount).toFixed(2))
                        }
                    })
                    let newMonth = months.concat(prevMonth)
                    let newAmount = amount.concat(prevAmount)
                    setEarnings({ ...earning, months: newMonth, amount: newAmount, load: false })
                }
            }).catch((err) => {
            })
    }

   

    return (
        <>
            {!pageload &&
                <div className={`tutor-home ${storeData.language === 'ar' && "text-right"}`} style={{ maxWidth: '100%' }}>
                    <section className={`upcoming-class d-lg-block d-none ${upcomingClassData.length === 0 && "d-none"}`} style={{ width: '900px', maxWidth: '100%', overflowX: 'auto' }}>
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            className="my-0 py-0 w-100"
                            arrows={true}
                            responsive={breakpoints.breakpointOne}>
                            {
                                upcomingClassData.map((data, index) => {
                                    return (
                                        <div key={`up+${index}`} className=" px-1 mb-2">
                                            <section className="alert-class row gx-0 mx-0 text-white">
                                                <div className="col-lg-1 col-md-2 col-2 all-center mb-1">
                                                    <img width="40px" height="40px" className="rounded-circle mx-2" src={data.profilePicture ? data.profilePicture : avatar} alt="pic" />
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-9 px-1 mb-1 d-flex flex-column justify-content-center">
                                                    <p className="fs-14 ms-700 lh-15">{data.name ? data.name : "Anonymous"}</p>
                                                    <p className="fs-12 lh-15 ms-500 tx-lt-black">Upcoming call</p>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-6 px-1 d-flex flex-column justify-content-center">
                                                    <p className="fs-14 ms-700 lh-21">Schedule At</p>
                                                    <p className="ms-700 lh-21">{moment(data.date).format('DD MMM YYYY')}</p>
                                                    <p className='ms-700'>{moment(data.start_time, 'hh:mm').format('hh:mm A')}-{moment(data.end_time, 'hh:mm').format('hh:mm A')}</p>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-6 all-center">
                                                    {helper.isUserCanJoinClass({ classDate: data.date, startTime: data.start_time }) &&
                                                        <button className="btn btn-login fs-12 w-100 wrap"
                                                            onClick={() => {
                                                                history.push(`/dhad-video/${data.channel_name}`)
                                                            }}
                                                        >Join</button>}
                                                </div>

                                            </section>

                                        </div>
                                    )
                                })
                            }

                        </Carousel>


                    </section>

                    <section className="banner-box  row gx-0 mx-0 tx-lt-black">
                        <div className="col-lg-7 col-md-7 col-12 py-4 px-4 d-flex flex-column justify-content-center">
                            <h5 className="f-fred fs-32">{t('tutorDash.banner1.title')}</h5>
                            <p className="mb-3 mt-2 fs-18 lh-21 tx-lt-grey ms-500">
                                {t('tutorDash.banner1.body')}
                            </p>
                            <div>
                                <button className="btn btn-login  mx-2 mb-2" onClick={() => history.push('/tutor/dashboard/library')}>{t('tutorDash.banner1.button1')}</button>
                                <button className="btn btn-tutor-profile mx-2 mb-2" onClick={() => history.push('/tutor/dashboard/calendar')}>{t('tutorDash.banner1.button2')}</button>
                            </div>
                        </div>
                        <div className="col-lg-5 d-lg-block h-100 d-md-block d-none col-md-5 col-12">
                            <img className="w-100 h-100" style={{ borderRadius: '250px 20px 20px 0px', maxHeight: '100%' }} src={banner} alt="banner" />
                        </div>
                    </section>
                    <section className="banner-bottom mx-auto mb-3"></section>
                    <section className="row gx-0 mx-0 analytic tx-lt-black">
                        <div className="col-lg-4 col-md-5 col-12 mb-2">
                            <div className="analytic-data px-3 py-2">
                                <p className="fs-18 ms-900">{t('tutorDash.banner2.heading1')}</p>
                                <p className="fs-14 ms-700 tx-lt-black">{t('tutorDash.banner2.sub-title1')}</p>
                                <div className="row gx-0 mx-0 mt-2">
                                    <div className="col-6 px-1 mb-2">
                                        <p className="tx-lt-black-op-5 ms-900">{t('tutorDash.banner2.stats1')}</p>
                                        <p className="ms-500 fs-40">{resData.totalProfileViews}</p>
                                    </div>
                                    <div className="col-6 px-1 mb-2">
                                        <p className="tx-lt-black-op-5 ms-900">{t('tutorDash.banner2.stats2')}</p>
                                        <p className="ms-500 fs-40">{parseFloat(resData.totalEarnings).toFixed(1)}</p>
                                    </div>

                                    <div className="col-6 px-1 mb-2">
                                        <p className="tx-lt-black-op-5 ms-900">{t('tutorDash.banner2.stats3')}</p>
                                        <p className="ms-500 fs-40">{parseFloat(resData.monthlyIncrease).toFixed(1)}</p>
                                    </div>
                                    <div className="col-6 px-1 mb-2">
                                        <p className="tx-lt-black-op-5 ms-900">{t('tutorDash.banner2.stats4')}</p>
                                        <p className="ms-500 fs-40">{resData.totalComments}</p>
                                    </div>
                                    <div className="col-6 px-1 mb-2">
                                        <p className="tx-lt-black-op-5 ms-900">{t('tutorDash.banner2.stats5')}</p>
                                        <p className="ms-500 fs-40">{resData.totalRateStudent}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-12 px-1">
                            <div className="analytic-data px-3 py-2 h-100">
                                <section className='d-flex justify-content-between'>
                                    <div>
                                        <p className="ms-900 fs-18 ">{t('tutorDash.banner3.heading2')}</p>
                                        <p className="fs-14 ms-700 tx-lt-black">From {anaData.st} to {anaData.end}</p>
                                    </div>
                                    {/* <div className='tx-lt-black ms-700 fs-14 tutor-dash' style={{ height: 'initial' }}>
                                        <DateRangePicker
                                            className="w-100 h-100 p-2"
                                            showClearDates={false}
                                            style={{ height: '100%' }}
                                            navPrev={null}
                                            initialStartDate={null}
                                            disabled={false}
                                            startDate={moment(anaData.st)}
                                            isOutsideRange={() => false}
                                            isDayBlocked={() => false}
                                            enableOutsideDays={true}
                                            numberOfMonths={1}
                                            startDateId="s_id"
                                            endDate={moment(anaData.end)}
                                            endDateId="e_id"
                                            onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                            focusedInput={focusedInput}
                                            onFocusChange={e => setFocusedInput(e)}
                                            displayFormat="MMM DD, YYYY"
                                        />
                                    </div> */}
                                </section>

                                {!earning.load && <ApexChart year={`${moment(anaData.st).format('MMM YYYY')} - ${moment(anaData.end).format('MMM YYYY')}`} month={earning.months} amount={earning.amount} />}
                            </div>

                        </div>
                    </section>
                </div>}
            {pageload && <Backdrop />}
        </>
    )
}
