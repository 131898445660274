import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Backdrop from '../component/Backdrop'
import tutor from '../controller/tutor'
export default function About() {

    const [data, setData] = useState('')
    const [pageload, setPageload] = useState(true)

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await tutor.getlegalPages()
            .then((res) => {
                if (res.data.status) {

                    setPageload(false)
                    setData(res.data.result)
                }
            })
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>The biggest Arabic learning platform</title>
                <meta name="description" content='Get connected with SayDhad, an Arabic learning platform with a broad range of tutors who
helped many people learn the nuances of Arabic the Language in a short period of time
worldwide.' />
            </Helmet>
            <div className="ms-1000">

                <div className="row ms-500">
                    <div className="col-lg-10 col-12 mx-auto  py-2">
                        {!pageload ?
                            <div dangerouslySetInnerHTML={{ __html: data.about_dhad }}>
                            </div> :
                            <Backdrop />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
