/* eslint-disable */
import React, { useState, useEffect } from 'react'
import HowToVideosCard from '../component/HowToVideosCard'
import ArticlesDetails from './ArticlesDetails'
import tutor from '../controller/tutor'
import helper from '../helper'
import { useHistory } from 'react-router-dom'
import Backdrop from '../component/Backdrop'
import {  FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function ArticleListing() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [qry, setQry] = useState('')
    const [catlist, setcatList] = useState([])
    const [pageload, setPageload] = useState(true)
    const [openArticle, setOpenArtcle] = useState({ open: false, id: '', category: '' })
    const [searchData, setSearchData] = useState({ open: false, data: [] })
    const [state, setState] = useState({
        data: []
    })

    useEffect(() => {
        getArticles()
        return () => { }
    }, [])

    const search = async (isSearch) => {
        setPageload(true)
        await tutor.searchHowToArticles({ title: !isSearch ? "" : qry }).then((res) => {
            if (res.data.status) {
                setSearchData({ ...setSearchData, open: true, data: res.data.result })
            }
            setPageload(false)
        })
    }

    const getArticles = async () => {
        await tutor.getArticles()
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, data: res.data.result })
                    let list = []
                    for (let i = 0; i < res.data.result.length; i++) {
                        if (!list.includes(res.data.result[i].category)) {
                            list.push(res.data.result[i].category)
                        }
                    }
                    setcatList(list)
                    setPageload(false)
                }
            })
    }

    return (
        <>
            {
                !openArticle.open && <div className={`article-listing ${reduxState.language === 'ar' && "text-right"}`}>
                    <div className="row mb-2">
                        <div className="col-lg-12 d-flex align-items-center">
                            <button className="btn back-btn py-1 fs-14 f-fred ls px-3 mr-2" onClick={() => history.goBack()}>{t('general.back')}</button>
                            <div className='mx-2'>
                                <p className="lh-18 fs-18 f-fred">{t('general.support.howTo')} <span className="tx-green">{t('general.support.articles')}</span></p>
                                <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)}  {t('general.support.supportText')}</p>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={(e) => { e.preventDefault(); search(true) }}>
                        <div className="row gx-0 mx-0 ">
                            <div className="col-lg-3 col-md-4 d-flex w-100 align-items-center col-12 px-1 mb-2">
                                <input pattern='[a-zA-Z .-]*' title='Please enter characters only' type="text" maxLength={16} placeholder={t('general.courses.enterTitle')} value={qry} className="w-100  search-box ms-700 px-2 py-2 fs-14 " onChange={(e) => setQry(e.target.value)} />
                                {qry && <FaTimes size={30} style={reduxState.language === 'ar' ? { left: '5px' } : { right: '5px' }}
                                    className={`px-2 inactive-icon-color p-abs pointer bg-white`}
                                    onClick={() => { setQry(''); search(false); }} />}
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2 d-lg-block d-md-block d-none">
                                <button type="submit" className="btn btn-login w-100 fs-12 wrap">{t('general.search')}</button>
                            </div>
                        </div>
                    </form>

                    {!searchData.open && !openArticle.open &&
                        <section className="row gx-0 mx-0">
                            {
                                catlist.map((cat, index) => {
                                    return (
                                        <>
                                            <div key={`article${index}`} className="col-12"><p className="f-fred mt-3 mb-2 fs-24 px-1 text-capitalize">{cat}</p> </div>
                                            {
                                                state.data.map((courses, index1) => {
                                                    if (courses.category === cat) {
                                                        return (
                                                            <div key={`article+${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                                <HowToVideosCard
                                                                    img={courses.featured_image_link}
                                                                    title={courses.title}
                                                                    onChange={() => setOpenArtcle({ ...openArticle, open: true, id: courses.id, category: courses.category })}
                                                                />
                                                            </div>
                                                        )
                                                    }else return <></>

                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                        </section>
                    }

                    <section className="row gx-0 mx-0 mb-2">
                        <div className="col-12 my-3">
                            {!openArticle.open && searchData.open &&
                                <p className="ms-900 fs-18">
                                    Search results
                                </p>
                            }
                        </div>
                        {
                            !openArticle.open &&
                            searchData.open && searchData.data.map((searchResult, index) => {
                                return (
                                    <div key={`course+${index}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                        <HowToVideosCard
                                            img={searchResult.featured_image_link}
                                            title={searchResult.title}
                                            onChange={() => setOpenArtcle({ ...openArticle, open: true, id: searchResult.id, category: searchResult.category })}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="col-12">
                            {!openArticle.open && searchData.open && searchData.data.length === 0 && <p className='my-2 text-center'>{t('messages.noRecordFound')}</p>}
                        </div>
                    </section>
                </div >
            }

            {
                openArticle.open &&
                <ArticlesDetails
                    data={state.data}
                    cb={() => setOpenArtcle({ ...openArticle, open: false })}
                    id={openArticle.id}
                    category={openArticle.category}
                />
            }
            {pageload && <Backdrop />}
        </>
    )
}
