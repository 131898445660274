/* eslint-disable */
import React, { useState, useEffect } from 'react'
import logo from '../static/images/Logo.svg'
import userParrot from '../static/images/parrot.svg'
import { useHistory } from 'react-router'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import toast from 'react-hot-toast'
import { useTranslation } from "react-i18next";
import { getCountryList } from '../controller/common'
import { useSelector } from 'react-redux'

export default function LittleMore() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [selfAccount, setSelfAccount] = useState(true)
    const [goalType, setGoalType] = useState(1)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('initial')
    const [isSocialAuth, SetIsSocialAuth] = useState(false)
    const [state, setState] = useState({
        name: '',
        hearAbout: 1,
        showMore: false,
        country: '',
        countryCode : "",
        timeZone: '',
        countryList: [],
        timeZoneList: []
    })

    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        try {
            let decEmail = helper.decrypt(url.get("tk1").toString().replace(/ /g, "+"))
            let decPass;
            try {
                decPass = helper.decrypt(url.get("tk2").toString().replace(/ /g, "+"))
            }
            catch (err) { }
            let isSocial = url.get("auth")
            if (!decEmail) {
                history.push('/signup')
            } else if (isSocial && (isSocial == "google" || isSocial == "facebook")) { SetIsSocialAuth(true) }
            else if (!decPass) {
                history.push('/signup')
            }
            setEmail(decEmail)
            setPassword(decPass)
            getCountry('')
        }
        catch (err) {
            history.push('/user/signup')
        }

        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCountry = async (code) => {
        if (!code) {
            await getCountryList(code).then((res) => {
                if (res.data.status) {
                    setState({ ...state, countryList: res.data.result })
                }
            })
        } else {
            state.countryList.forEach((data) => {
                if (data.country_code === code) {
                    code = data.country_code
                    setState({ ...state, timeZoneList: data.timezone, country: data.country_name,countryCode : data.country_code })
                }
            })
        }
    }

    const submit = async () => {

        const url = new URLSearchParams(window.location.search)
        let data = {
            email: email,
            password: password,
            selfAccount: selfAccount,
            kidAccount: !selfAccount,
            goalType: goalType,
            country: state.country,
            levelType: 2,
            hearAboutDhad: state.hearAbout,
            name: state.name,
            referral_code: url.get('referral') ? url.get('referral') : "",
            timeZone: state.timeZone.split("#")[0],
            time_zone_city : state.timeZone.split("#")[1]
        }

        if (!data.name) {
            toast.error("Please enter your name")
            return
        }
        setOpen(true)
        if (!isSocialAuth) {
            await auth.userRegister(data)
                .then((res) => {
                    setOpen(false)
                    if (res.data.status) {
                        helper.saveCredential(
                            res.data.result.token, "user",
                            res.data.result.id,
                            "approved",
                            res.data.result.email
                        )
                        const encrEmail = helper.encrypt(email)
                        history.push(`/user/verify-otp?tk1=${encrEmail}`)
                    } else {
                        if (res.data.error === "Please verify the account") {
                            const encrEmail = helper.encrypt(email)
                            history.push(`/user/verify-otp?tk1=${encrEmail}`)
                        }
                        else {
                            helper.showMessage(res.data.error, "error")
                        }
                    }
                })
        } else if (isSocialAuth) {
            const getTemporaryData = JSON.parse(helper.decrypt(localStorage.getItem('HDSUFSD')))
            data = {
                selfAccount: selfAccount,
                kidAccount: !selfAccount,
                goalType: goalType,
                country: state.country,
                levelType: 2,
                hearAboutDhad: state.hearAbout,
                name: state.name,
                referral_code: url.get('referral') ? url.get('referral') : null,
                id: getTemporaryData.id,
                timeZone: state.timeZone.split("#")[0],
                time_zone_city : state.timeZone.split("#")[1]
            }

            await auth.userSocialRegistration(data, getTemporaryData.token)
                .then((res) => {
                    if (res.data.status) {
                        helper.saveCredential(
                            getTemporaryData.token,
                            "user", getTemporaryData.id,
                            "approved",
                            getTemporaryData.email)
                        localStorage.removeItem('HDSUFSD')
                        history.push(`/user/dashboard/home`)
                    }
                    setOpen(false)
                    toast.success(res.data.error)
                    setError(res.data.error)
                }).catch((err) => {
                    setOpen(false)
                })

        }
    }

    const handleNext = () => {
        if (!state.country) {
            toast.error("Please select country")
            return
        } else if (!state.timeZone) {
            toast.error("Please select timezone")
            return
        }
        setState({ ...state, showMore: true })
    }
    return (
        <div className="user-little-more tx-lt-black hide-scroll">
            <div className="row gx-0 mx-0">
                <div className="col-lg-12 col-md-8 col-12 px-3 mx-auto all-center hide-scroll flex-column">
                    <div className="all-center mb-3 mt-5">
                        <img width="70%" src={logo} alt="logo" />
                    </div>
                    <div className={`f-fred little-more ${reduxState.language === 'ar' ? "text-right" : "text-left"}`}>
                        <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                            <h4 className="f-fred my-2 mb-4 text-center">{t('userOnBoard.littleMore.heading2')} <span className="tx-green">{t('userOnBoard.littleMore.you')}</span></h4>
                            {!state.showMore ?
                                <>
                                    <div className="fs-14 tx-lt-grey">
                                        <p className="mb-2 f-fred ">{t('userOnBoard.littleMore.step1')}</p>
                                        <div className="row mb-4">
                                            <div className={`col-6 ${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} d-flex justify-content-between`}>
                                                <input type="radio" id="kid" name="account-type" className="mr-2" checked={selfAccount ? false : true} onChange={() => setSelfAccount(false)} />
                                                <label htmlFor="kid" className="fs-12 pointer"> <span className='mx-2'>{t('userOnBoard.littleMore.accountFor.Kids')}</span></label>
                                            </div>
                                            <div className={`col-6 ${reduxState.language === 'ar' ? "radio-ar-item" : "radio-item"} d-flex justify-content-between`}>
                                                <input type="radio" id="myself" name="account-type" className="mr-2 br" checked={selfAccount ? true : false} onChange={() => setSelfAccount(true)} />
                                                <label htmlFor="myself" className="fs-12 pointer"> <span className='mx-2'>{t('userOnBoard.littleMore.accountFor.myself')}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fs-14 tx-lt-grey'>
                                        <p className="mb-2">{t('userOnBoard.littleMore.step2')}</p>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="professional" name="goal" className="mb-3 mr-2" checked={goalType === 1 && true} onChange={() => setGoalType(1)} />
                                            <label htmlFor="professional" className="pointer fs-12"><span className='mx-2'>{t('userOnBoard.littleMore.goal.pd')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="acadmic" name="goal" className="mb-3 mr-2" checked={goalType === 2 && true} onChange={() => setGoalType(2)} />
                                            <label htmlFor="acadmic" className="pointer fs-12"><span className='mx-2'>{t('userOnBoard.littleMore.goal.accad')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="travel" name="goal" className="mb-3 mr-2" checked={goalType === 3 && true} onChange={() => setGoalType(3)} />
                                            <label htmlFor="travel" className="pointer fs-12" ><span className='mx-2'>{t('userOnBoard.littleMore.goal.travel')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-3`}>
                                            <input type="radio" id="personal" name="goal" className="mb-3 mr-2" checked={goalType === 4 && true} onChange={() => setGoalType(4)} />
                                            <label htmlFor="personal" className="pointer fs-12"><span className='mx-2'>{t('userOnBoard.littleMore.goal.pg')}</span></label>
                                        </div>
                                    </div>
                                    <div className='tutor-dash h-100 fs-14 tx-lt-grey'>
                                        <p className="mb-2">{t('userOnBoard.littleMore.step3')}</p>
                                        <select onChange={(e) => { getCountry(e.target.value) }}  className='py-2 px-2 input-box ms-700' style={{ width: '300px' }}>
                                            <option value="">{t('tutorSidebar.generalInfo.selectCountry') + " *"}</option>
                                            {state.countryList.map((data) => <option selected={state.countryCode === data.country_code} value={data.country_code}>{data.country_name}</option>)}
                                        </select>
                                    </div>

                                    <div className='tutor-dash h-100 fs-14 tx-lt-grey'>
                                        <p className="mb-2">{t('userOnBoard.littleMore.step5')}</p>
                                        <select onChange={(e) => setState({ ...state, timeZone: e.target.value })} className='py-2 px-2 input-box ms-700' style={{ width: '300px' }}>
                                            <option value="">{t('tutorSidebar.generalInfo.selectTimeZone') + " *"}</option>
                                            {state.timeZoneList.map((timezone) => <option selected={timezone.timeZone + "#" + timezone.city === state.timeZone ? true : false} value={timezone.timeZone + "#" + timezone.city}>{timezone.city + " (" + timezone.timeZone + " UTC)"}</option>)}
                                        </select>
                                    </div>

                                    <p className="my-0 mt-2 ms-700 fs-14 tx-red text-center">{error !== "initial" && error}</p>
                                    <button type="button" className="btn btn-login box-height  mt-3 d-block  mx-auto" style={{ width: '200px' }} onClick={handleNext}>{t('userOnBoard.next')}</button>
                                </>
                                :
                                <>
                                    <div className='input-box mb-3'>
                                        <input pattern='[a-zA-Z ,.-]*' title='Name only contains a-z and special symbol (,.-).' maxLength={30} type="text" onChange={(e) => { setState({ ...state, name: e.target.value }) }} placeholder={t('userOnBoard.littleMore.enterName')} id="" />
                                    </div>
                                    <div className='fs-14 tx-lt-grey'>
                                        <p className="mb-2">{t('userOnBoard.littleMore.step4')}</p>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="professional" name="goal" className="mb-3 mr-2" checked={state.hearAbout === 1 && true} onChange={(e) => setState({ ...state, hearAbout: 1 })} />
                                            <label htmlFor="professional" className="pointer fs-12"><span className='mx-2'>{t('userOnBoard.littleMore.hear.fm')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="acadmic" name="goal" className="mb-3 mr-2" checked={state.hearAbout === 2 && true} onChange={(e) => setState({ ...state, hearAbout: 2 })} />
                                            <label htmlFor="acadmic" className="pointer fs-12" ><span className='mx-2'>{t('userOnBoard.littleMore.hear.media')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-2`}>
                                            <input type="radio" id="travel" name="goal" className="mb-3 mr-2" checked={state.hearAbout === 3 && true} onChange={(e) => setState({ ...state, hearAbout: 3 })} />
                                            <label htmlFor="travel" className="pointer fs-12" ><span className='mx-2'>{t('userOnBoard.littleMore.hear.tv')}</span></label>
                                        </div>
                                        <div className={`${reduxState.language === 'ar' ? "radio-ar-item d-flex justify-content-between" : "radio-item d-block"} mb-3`}>
                                            <input type="radio" id="personal" name="goal" className="mb-3 mr-2" checked={state.hearAbout === 4 && true} onChange={(e) => setState({ ...state, hearAbout: 4 })} />
                                            <label htmlFor="personal" className="pointer fs-12" ><span className='mx-2'>{t('userOnBoard.littleMore.hear.gs')}</span></label>
                                        </div>
                                    </div>

                                    <p className="my-0 mt-2 ms-700 fs-14 tx-red text-center">{error !== "initial" && error}</p>
                                    <div className='d-flex'>
                                        <button type="button" className="btn btn-login box-height  mt-3 mr-3 ml-2 d-block" style={{ width: '200px' }} onClick={() => setState({ ...state, showMore: false })}>{t('userOnBoard.back')}</button>
                                        <button type="submit" className="btn btn-login box-height  mt-3 d-block " style={{ width: '200px' }} >{t('userOnBoard.proceed')}</button>
                                    </div>
                                </>
                            }




                        </form>
                    </div>
                    <div className="bottom-parrot d-lg-block d-md-block d-none">
                        <img src={userParrot} alt="parrot" />
                    </div>
                </div>
            </div>
            {open && <Backdrop />}
        </div >
    )
}
