/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL

const pushChatNotification = ({senderId,receiverId}) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/web-socket/chat/notification/${senderId}/${receiverId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export default {
pushChatNotification
}