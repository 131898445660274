import React, { useState, useEffect, useRef } from 'react'
import parot from '../static/images/parrot-1.png'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import { useTranslation } from "react-i18next";
const NetworkSpeed = require('network-speed');
const testNetworkSpeed = new NetworkSpeed();

async function getNetworkDownloadSpeed() {
    try {
        const baseUrl = 'https://eu.httpbin.org/stream-bytes/500000';
        const fileSizeInBytes = 500000;
        const speed = await testNetworkSpeed.checkDownloadSpeed(baseUrl, fileSizeInBytes);
        return speed.mbps
    }
    catch (err) {
        return 5
    }
}

async function getNetworkUploadSpeed() {
    try {

        const fileSizeInBytes = 200000
        const speed = await testNetworkSpeed.checkUploadSpeed("https://eu.httpbin.org/stream-bytes/500000", fileSizeInBytes);
        return speed.mbps
    }
    catch (err) {
        return 2.5
    }
}

let mediaRecorder, stream;
export default function ConnectionTest({ prev,onSubmit }) {
    const { t } = useTranslation();
    const userVideo = useRef()
    const playVideo = useRef()
    const [pageload, setPageload] = useState(false)
    const [speed, setwifiSpeed] = useState({ upload: '0', download: "0" });
    useEffect(() => {
        connect()
        return () => {
            try {
                stopBothVideoAndAudio()
            } catch (err) { }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const connect = async () => {
        try {
            getPermission()
            const dwn = await getNetworkDownloadSpeed();
            const up = await getNetworkUploadSpeed();
            Promise.all([dwn, up]).then(() => {
                setwifiSpeed({ upload: up, download: dwn })
            })
        }
        catch (err) {
        }
    }

    const submitProfile = async () => {
        if (speed.upload < 1 || speed.download < 1) {
            helper.showMessage("Bad Internet Connection")
            return
        }
        setPageload(true)
        await tutor.submitProfile({ id: helper.getid() })
            .then((res) => {
                if (res.data.status) {
                    onSubmit()
                    helper.profileSubmitted()
                }
                onSubmit()
                setPageload(false)
            })

    }

    const getPermission = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (mediaStream) {
            stream = mediaStream
            mediaRecorder = new MediaRecorder(mediaStream);
            userVideo.current.srcObject = mediaStream;
            mediaRecorder.onstart = function (e) {
                this.chunks = [];
            };
            mediaRecorder.ondataavailable = function (e) {
                this.chunks.push(e.data);
            };
            mediaRecorder.onstop = function (e) {
                var blob = new Blob(this.chunks, { 'type': 'audio/ogg; codecs=opus' });
                playVideo.current.srcObject = blob;
            };
        });
    }

    function stopBothVideoAndAudio() {
        try {
            stream.getAudioTracks()[0].stop();
            stream.getVideoTracks()[0].stop();

        } catch (err) {
        }
    }

    return (
        <>
            <div className="row gx-0 mx-0">
                <div className="col-lg-11 mx-auto pt-3">
                    <div className='p-sticky tp-0 py-3 w-100 bg-white' style={{ zIndex: 1 }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-6 col-md-5 col-12 mb-2 f-fred">
                                <h4 className='tx-lt-black'>{t('tutorOnBoard.con.heading')} <span className="tx-green">{t('tutorOnBoard.con.test')}</span></h4>
                                <p className="sub-heading">{t('tutorOnBoard.con.subTitle')}</p>
                            </div>
                            <div className="col-lg-6 col-md-7 col-12 mb-2 d-flex justify-content-lg-end align-items-center ">
                                <button className="btn btn-tutor-profile mr-2 wrap" onClick={() => prev()}>{t('tutorOnBoard.con.back')}</button>
                                {helper.getProfileStatus() === "Pending" && <button className="btn btn-login h-100 wrap" onClick={() => submitProfile()}>{t('general.submitApplication')}</button>}
                            </div>
                        </div>
                    </div>

                    <div className="alert fs-14 ms-500  tx-lt-grey py-2 my-3 px-2 d-flex align-items-center">
                        <img width="50px" src={parot} alt="connection" />
                        <span> {t('tutorOnBoard.basicInfo.para1')} <span className="ms-900">{t('tutorOnBoard.basicInfo.para2')}  </span> </span>
                    </div>


                    <section className="row gx-0 mx-0 f-fred basic-info">
                        <div className="col-lg-9 col-md-10  col-12 px-1 mx-auto all-center flex-column">
                            {helper.getProfileStatus() === "Uploaded" &&
                                <div style={{ backgroundColor: "initial" }} className="alert alert-success ms-700 fs-18 my-2 p-2 tx-green" role="alert">
                                    <i className="far fa-check-circle mr-2"></i>
                                    Your profile is successfully submitted. It is under review for verification.
                                </div>
                            }
                            {(speed.upload < 1 || speed.download < 1) ?
                                <div style={{ backgroundColor: "initial" }} className="alert alert-danger ms-700 fs-14 my-2 p-2 tx-red" role="alert">
                                    <i className="far fa-times-circle mr-2"></i>
                                    Connection test failed. Download and upload speed should be greater 1 MB.
                                </div> :
                                <div style={{ backgroundColor: "initial" }} className="alert alert-success ms-500 fs-16 my-2 p-2 tx-green" role="alert">
                                    <i className="far fa-check-circle mr-2"></i>
                                    Connection test passes.
                                </div>
                            }
                            <div>
                                <div className="mb-2 w-100" style={{ borderRadius: '15px' }}>
                                    <video ref={userVideo} className="w-100" style={{ borderRadius: '15px', minWidth: '350px', maxHeight: '300px' }} controls />
                                </div>
                                <div className="d-flex justify-content-center">
                                </div>

                                <div className="row gx-0 mt-3 mx-0 f-fred w-100">
                                    <div className="col-lg-6 col-6 all-center flex-column">
                                        <p className='ms-900 fs-20 tx-lt-grey'>{t('tutorOnBoard.con.dldSpeed')}</p>
                                        <p className="my-2"><span className="tx-green fs-40 ms-500 ">{speed.download}</span></p>
                                        <p className='ms-900 fs-18 tx-lt-grey'>mbps</p>
                                    </div>
                                    <div className="col-lg-6 col-6 all-center flex-column">
                                        <p className='ms-900 fs-20 tx-lt-grey'>{t('tutorOnBoard.con.upSpeed')}</p>
                                        <p className="my-2"><span className="tx-green fs-40 ms-500">{speed.upload}</span></p>
                                        <p className='ms-900 fs-18 tx-lt-grey'>mbps</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {pageload && <Backdrop />}
                    </section>
                </div>
            </div>

        </>
    )
}

