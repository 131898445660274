/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL

const addAvailibilty = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/tutor-availability`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getAvailibilty = (id, date) => {
    try {
        const token = helper.getToken()
        let query = date ? `&date=${date}` : ""
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/tutor-availability?tutor_id=${id}${query}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const removeAvailibilty = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `delete`,
            url: `${BASE_URL}/tutor/remove-slot-by-availability-id`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const markAsStudy = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/student-learn-course-syllabus`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getAvailableTutorByDateTime = ({ date, startTime, endTime }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/tutors/tutor-list-by-date?date=${date}&start_time=${startTime}&end_time=${endTime}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const addDeviceToken = async (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/device-token`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

export default {
    addAvailibilty,
    getAvailibilty,
    markAsStudy,
    getAvailableTutorByDateTime,
    addDeviceToken,
    removeAvailibilty
}