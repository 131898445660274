import React from 'react'
import moment from 'moment'
import ReactStars from "react-rating-stars-component";
import avatar from '../static/images/avatar.png'
export default function Testimonial({ userName, rate, review, country, date, pic }) {
    return (
        <div className="d-flex mb-3">
            <div className="mr-2">
                <img style={{ height: '30px', width: '30px' }} className="rounded-circle my-2" src={pic ? pic : avatar} alt="testimonial" />
            </div>
            <div className="cfs-14 mx-2">
                <p><span className="f-fred lh-15">{userName ? userName : "Unknown"}</span> <span>{country ? country : "---"}</span> </p>
                <p className="lh-15 ms-500 fs-12" style={{color : '#989898'}}>{moment(date).format('MMM DD,YYYY hh:mm A')}</p>
                <ReactStars
                    edit={false}
                    value={rate ? parseFloat(rate).toFixed(1) : 0}
                    count={5}
                    size={15}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    classNames="my-0 py-0"
                    activeColor="#ffcb00"
                />
                <p className="text-justify fs-14 lh-15 mt-2 tx-lt-grey ms-300">
                    {review}
                </p>
            </div>
        </div>
    )
}
