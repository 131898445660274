/* eslint-disable */
import React, { useState } from 'react'
import arrow1 from '../static/images/slidearrow1.svg'
import arrow2 from '../static/images/slidearrow2.svg'
import logo from '../static/images/logo.png'
import Slide from 'react-reveal/Slide';
export default function TutorSlideShow({ data, cb, startIndex, name, lesson }) {
    const [gridView, setGridView] = useState(true)
    const [start, setStart] = useState(false)
    const [isFull, setIsFull] = useState(false)
    const [state, setState] = useState({
        currentIndex: startIndex,
        noOfSlides: data.length
    })

    function fullScreen(isFull) {
        try {
            if (!isFull) {
                setIsFull(true)
                const ob = document.getElementById("syllab-fullscreen");
                ob.requestFullscreen();
            } else {
                setIsFull(false)
                document.exitFullscreen();
            }
        } catch (err) {

        }

    }

    window.addEventListener('keyup', (e) => {
        var key = e.which;
        if (key == 13 || key == 39) {
            setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex })

            return false;
        } else if (key == 37) {
            setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex })
            return false;
        }
    })
    return (
        <>

            <div className="popup-modal all-center">
                <div id="syllab-fullscreen" className="popup-modal-body  p-rel md px-2 hide-scroll">
                    <div className='py-2 bg-white p-sticky tp-0 ' >
                        <p className='text-right f-fred pointer mr-4 tx-red' onClick={cb}>CLOSE</p>
                    </div>
                    {!start ? <div className="row" style={{ height: '450px' }}>
                        <div className="col-lg-5 col-md-4 all-center">
                            <img style={{ maxWidth: '100%' }} className="br-2" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYaPsCZiq6EmcTAC6Pi9n-myuGdcLsREcYRA&usqp=CAU" alt="slide-banner" />
                        </div>
                        <div className="col-lg-7 col-md-8 col-12 f-fred d-flex flex-column justify-content-center align-items-center ">
                            <img style={{ width: '200px' }} src={logo} alt="logo" />
                            <div className="mt-3">
                                <h5 className="text-capitalize">{name}</h5>
                                <p>Lesson {lesson}</p>
                                <button className="btn btn-tutor-profile fs-12 my-2" style={{ width: '120px' }} onClick={() => setStart(true)}>START</button>

                            </div>
                        </div>
                    </div> : <>

                        <section className='my-3'>
                            <div className='all-center' style={{ minHeight: '450px' }}>
                                <img style={{ maxWidth: '100%' }} className='h-100 br-1' src={data[state.currentIndex].fileName} alt="file" />
                            </div>
                        </section>
                        {
                            !gridView ?
                                <Slide bottom>
                                    <div className='p-sticky bt-0 lt-0 w-100'>
                                        <section className='row gx-0 mx-0 pt-2 bg-white w-100'>
                                            <section className='col-lg-8 col-md-7 col-12 mb-2'>
                                                <p className='ms-700 tx-lt-grey mx-2 overflow-elipse'>{state.currentIndex + 1}. {data[state.currentIndex].title} </p>
                                            </section>
                                            <section className='col-lg-4 col-md-7 col-12 mb-2'>
                                                <div className='all-center'>
                                                    <img src={arrow1} className="mx-2 pointer hover-shadow" alt="arrow" onClick={() => { setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex }) }} />
                                                    <span className='f-fred fs-20 tx-lt-black mx-1'>Slide {state.currentIndex + 1} of {data.length}</span>
                                                    <img src={arrow2} className="mx-2 pointer hover-shadow" onClick={() => { setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex }) }} alt="arrow" />

                                                    <section className='p-rel c-dropdown  d-inline-block'>
                                                        <input type="checkbox" className='c-drop-icon d-none' id="drop-icon" />
                                                        <label htmlFor="drop-icon"> <i className="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow"></i></label>
                                                        <div className='p-abs c-dropdown-body bg-sky  fs-16  ms-700 rt-0 bg-white' style={{
                                                            width: '190px', bottom: '25px',
                                                            right: '20px', top: 'initial'
                                                        }}>
                                                            <ul className='pointer' style={{ listStyle: 'none' }}>

                                                                <li onClick={() => fullScreen(isFull)}><label htmlFor="drop-icon">{isFull ? "EXIT FULLSCREEN" : " FULLSCREEN"}</label></li>
                                                                <li onClick={() => setGridView(!gridView)}><label htmlFor="drop-icon">{!gridView ? "OPEN GRID" : "CLOSE GRID"}</label></li>
                                                            </ul>
                                                        </div>
                                                    </section>
                                                </div>
                                            </section>

                                        </section>
                                    </div>
                                </Slide>
                                :
                                <Slide bottom>
                                    <div className='p-sticky bt-0 lt-0 w-100'>
                                        <section className='d-flex w-100 align-items-center hide-scroll' style={{ overflowX: 'auto', backgroundColor: 'rgba(128, 128, 128, 0.418)' }}>
                                            {data.map((file, index) =>
                                                <div   key={`ts${index}`} className={`${state.currentIndex === index ? "border-sky" : "border-yellow"} br-1 bg-white mx-2 d-inline-block my-2 pointer`}
                                                    onClick={() => { setState({ ...state, currentIndex: index }) }}
                                                >
                                                    <img style={{ width: '150px', height: '100px' }} className='br-1' src={file.fileName} alt="file" />
                                                    <div style={{ width: '150px' }}>
                                                        <p className='fs-14 ms-700 my-2 text-center overflow-elipse px-1'>{file.title}</p>

                                                    </div>
                                                </div>)}
                                            <i className='fas p-abs tp-0 rt-0  fa-times d-inline-block fs-20 hover-shadow pointer' onClick={() => setGridView(!gridView)}></i>
                                        </section>
                                    </div>
                                </Slide>
                        }


                    </>
                    }
                </div>
            </div>

        </>
    )
}
