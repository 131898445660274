/* eslint-disable */
import axios from "axios"
import { constant } from '../config/Contant'
import helper from "../helper"

const BASE_URL = constant.BASE_URL

const getResourceId = async ({ channelName, uid }) => {
    try {
        const apiToken = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/agora/resource-id?channelName=${channelName}&uid=${uid}`,
            data:{channelName, uid},
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        return {
            status: res?.data?.result?.status,
            data: res?.data?.result
        }
    }
    catch (err) {
        return err
    }
}

const startRecording = async ({ resourceId, channelName, token, uid }) => {
    try {
        const apiToken = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/agora/start-recording`,
            data:{resourceId, channelName, token, uid },
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        return {
            status: res?.data?.result?.status,
            data: res?.data?.result
        }
    }
    catch (err) {
        return err
    }
}

const checkRecordingStatus = async ({ sid, resourceId }) => {
    try {
        const apiToken = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/agora/recording-status`,
            data:{sid, resourceId},
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        return {
            status: res?.data?.result?.status,
            data: res?.data?.result
        }
    }
    catch (err) {
        return err
    }
}

const updateRecordingLayout = async ({ channelName, uid, resourceId, sid, WideRegionUserId, layout }) => {
    try {
        const apiToken = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/agora/recording-layout`,
            data:{channelName, uid, resourceId, sid, WideRegionUserId, layout },
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        return {
            status: res?.data?.result?.status,
            data: res?.data?.result
        }
    }
    catch (err) {
        return err
    }
}

const stopRecording = async ({ channelName, uid, resourceId, sid }) => {
    try {
        const apiToken = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/agora/stop-recording`,
            data:{channelName, uid, resourceId, sid },
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        return {
            status: res?.data?.result?.status,
            data: res?.data?.result
        }
    }
    catch (err) {
        return err
    }
}

export default {
    getResourceId,
    startRecording,
    checkRecordingStatus,
    updateRecordingLayout,
    stopRecording
}