import React, { useState, useRef } from 'react'
import logo from '../static/images/Logo.svg'
import userParrot from '../static/images/parrot-1.png'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
export default function UserFogotPassword() {
    const { t } = useTranslation();
    const history = useHistory()
    const email = useRef()
    const [open, setOpen] = useState(false)

    const submit = async () => {
        const data = {
            email: email.current.value ? email.current.value.toLowerCase() : email.current.value
        }
        const response = helper.validate("email", data.email)
        if (!response.isValid) {
            helper.showMessage(response.msg, "error")
            return
        }
        setOpen(true)
        await auth.userForgotPassword(data)
            .then((res) => {
                if (res.data.status) {
                    email.current.value = ''
                    helper.showMessage(res.data.result, "success")

                } else {
                    helper.showMessage(res.data.error.message, "success")
                }
                setOpen(false)
            })
    }


    return (
        <React.Fragment>
            <div className="p-fix tp-0 pl-4 tx-lt-black">
                <button className="btn btn-tutor-profile fs-12 mt-3 mb-2" style={{ width: '150px' }} onClick={() => history.goBack()}>{t('userOnBoard.goback')}</button>
            </div>
            <div className="user-little-more tutor-login-home all-center flex-column">
                <div className="row gx-0 mx-0 " style={{ width: '300px' }}>
                    <div className="col-lg-12 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                        <div className="all-center mb-3">
                            <img width="100%" src={logo} alt="logo" />
                        </div>
                        <div className="f-fred mt-4" style={{ minWidth: '300px' }}>
                            <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                                <h4 className="f-fred my-2">{t('auth.forgot.forgot')} <span className="tx-green">{t('auth.forgot.password')} </span></h4>
                                <div className="input-box mb-2 mt-3 mb-3">
                                    <input maxLength={40} ref={email} type="text" placeholder={t('auth.forgot.email')} className="px-2 box-height py-1 w-100 ms-700 " />
                                </div>
                                <button type="submit" className="btn btn-login mt-2 d-block box-height  mx-auto w-100"  >{t('auth.proceed')} </button>

                            </form>
                        </div>
                        <div className="bottom-lparrot mt-5">
                            <div className="p-rel">
                                <div className="ms-500 p-2 br-1" style={{ backgroundColor: '#F7F7F7' }}>
                                    <p>{t('auth.forgot.note')}</p>
                                </div>
                                <img src={userParrot} alt="parrot" className="p-abs rt-0" style={{ top: '80px', width: '60px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {open && <Backdrop />}
            </div>
        </React.Fragment>
    )
}
