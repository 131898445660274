import React, { useEffect, useRef } from 'react'
import fb from '../config/firebaseAuth';
import helper from '../helper/index';
import { ref, set, push, onChildAdded, increment } from "firebase/database";
import moment from 'moment';
import Slide from 'react-reveal/Slide';
import { FaTelegramPlane, FaArrowLeft } from 'react-icons/fa';
const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let unsubscribe = ""
const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-body-mobile")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}
const addRemoteDiv = (message,time) => {
    try {
        const remoteDiv = document.getElementById("chat-body-mobile")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            remoteDiv.append(chatDayBox)
            condition = 1;
        }
        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")

        const timeDiv = document.createElement('div')
        timeDiv.classList.add("chat-time","pl-3")
        timeDiv.style.clear = "both"
        timeDiv.innerHTML = moment(time).format('hh : mm a')


        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(timeDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}
const enable = () => {
    unsubscribe =    onChildAdded(ref(db, `supportChat/${rootRoomid}/messages`), (data) => {
        const val = data.val()
        if (parseInt(val.createdBy) === parseInt(helper.getid())) {
            adduserDiv(val.message, val.createdAt)
        }
        else {
            addRemoteDiv(val.message,val.createdAt)
        }
        // off(ref(db, `supportChat/${rootRoomid}/messages`)) 
    })

}


export default function SupportChatOnMobile({ myId, roomId, userName, cb }) {
    let msg = useRef()
    useEffect(() => {
        const userDiv = document.getElementById("chat-body-mobile")
        userDiv.innerHTML = ""
        rootRoomid = roomId
        condition = 0
        enable()
        return () => {
            try {
                unsubscribe()
            }
            catch { }
        }
    }, [roomId])


    const pushMessages = () => {
        const date = new Date()
        if (msg.current.value) {
            push(ref(db, `supportChat/${roomId}/messages`), {
                message: msg.current.value,
                createdBy: myId,
                createdAt: moment(date).format('YYYY-MM-DD hh:mm:ss A')
            });
            set(ref(db, `supportChat/${roomId}/count`), {
                user: 0,
                admin: increment(1)
            })
            msg.current.value = ""
        }
    }


    return (
        <div className="chat-box p-fix tp-0 ltr lt-0 h-100 mx-auto px-0 w-100 d-lg-none d-md-none" style={{ zIndex: '4' }}>
            <Slide left>
                <div className='d-flex justify-content-between flex-column' style={{ height: '100vh' }}>
                    <div className="chat-header  py-3 px-2 br-1">

                        <div className="row gx-0 mx-0">
                            <div className="col-1">
                                <FaArrowLeft size={24} onClick={cb} className='inactive-icon-color' />
                            </div>

                            <div className="col-10">
                                <p className="ms-900 fs-18 tx-lt-black">Chatting with {userName ? userName : "Anonymous"}</p>

                            </div>
                        </div>

                    </div>

                    <div id="chat-body-mobile" className="chat-room-body px-2  chat-body h-100">

                    </div>
                    <section className='pb-2 chat-footer-container'>
                        < form onSubmit={(e) => { e.preventDefault(); pushMessages() }}>
                            <div className="chat-footer  py-2 px-2 d-flex mx-2 align-items-center">
                                <input type="text" ref={msg} placeholder="Type a message here ..." className="chat-input-box w-100 h-100 ms-700" />
                                <button type="submit" className="btn chat-send pointer br-1 all-center"><FaTelegramPlane className='fs-30 text-white' /></button>
                            </div>
                        </form >
                        <section className='w-100' style={{ height: '60px' }}>

                        </section>
                    </section>
                </div>
            </Slide>
        </div>
    )
}
