/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AttendanceCard from '../component/AttendanceCard';
import 'react-dates/lib/css/_datepicker.css';
import ChatPop from '../component/ChatPop';
import tutor from '../controller/tutor'
import helper from '../helper';
import moment from 'moment'
import Backdrop from '../component/Backdrop';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function Attendance() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const [state, setState] = useState({
        chatLogOpen: false,
        chatRoomId: null,
        name: '',
        pic: ''
    })
    const [load, setLoad] = useState(true)

    const [apiData, setApiData] = useState({
        amount: 0,
        attendanceList: [],
        onlineCalls: [],
        totalTime: 0
    })

    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment());
 
    const onRange = (st, en) => {
        if (st) {
            setStartDate(moment(st));
        }
        if (en) {
            setEndDate(moment(en))
        }
        if (st && en) {
            apiCall(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        apiCall('', endDate)
    }, [])

    const apiCall = async (startDate, endDate) => {
        const date = new Date()
        setLoad(true)
        const nw = moment(new Date(date.setDate(date.getDate() - 30))).format('YYYY-MM-DD')
        const req = {
            tid: helper.getid(),
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : nw,
            endDate: moment(endDate).format('YYYY-MM-DD')
        }

        await tutor.getAttendaceRecord(req).then((res) => {
            if (res.data.status) {
                let list = []

                list = res.data.result.attendanceList.sort((a, b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0)
                setApiData({ ...apiData, ...res.data.result, attendanceList: list })
            }
            setLoad(false)
        })
    }



    return (<>
        <div className={`attendance tx-lt-grey ${reduxState.language === 'ar' && "text-right"}`}>
            <section className="row gx-0 mx-0">
                <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.attendance.onlineCalls')}</p>
                            <p className="sub-heading">From {moment(startDate).format('MMM DD, YYYY')}-{moment(endDate).format('MMM DD, YYYY')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green ltr">{apiData.onlineCalls}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.attendance.totalTime')}</p>
                            <p className="sub-heading">{t('tutorDash.attendance.onlineCalls')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green ltr">{apiData.totalTime}</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                    <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.attendance.totalAmt')}</p>
                            <p className="sub-heading">{t('tutorDash.attendance.fee')}</p>
                        </div>
                        <p className="ms-500 fs-40 tx-green ltr">{apiData.amount}</p>
                    </div>
                </div>
            </section>
            <section className='ms-700 my-3 fs-14 p-2 ltr d-flex'>
                        <input 
                            type="text" name="startDate"
                            className="px-2 mx-2 input-box f-fred"
                            onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = moment(startDate).format('MM/DD/YYYY'); }}
                            onBlur={(e) => {
                                e.target.type = "text";
                                e.target.defaultValue = moment(startDate).format('MM/DD/YYYY');
                            }}
                            max={moment(endDate).format('YYYY-MM-DD')}
                            placeholder="Start date"
                            style={{ width: '150px' }}
                            onChange={(e) => { onRange(e.target.value, endDate) }}
                            defaultValue={moment(startDate).format('YYYY-MM-DD')}
                        />
                        <input
                            max={moment().format('YYYY-MM-DD')}
                            type="text"
                            name="startDate"
                            className="px-2 mx-2  input-box f-fred"
                            onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = moment(endDate).format('MM/DD/YYYY'); }}
                            onBlur={(e) => {
                                e.target.type = "text";
                                e.target.defaultValue = moment(endDate).format('MM/DD/YYYY');
                            }} placeholder="End date"
                            style={{ width: '150px' }}
                            onChange={(e) => { onRange(startDate, e.target.value) }}
                            defaultValue={moment(endDate).format('YYYY-MM-DD')}
                        />
                    </section>
            {/* <section className='p-rel ms-700 my-3 fs-14 p-2 ltr'>
                <DateRangePicker
                    className="w-100 h-100 p-2"
                    maxDate={moment()}
                    showClearDates={false}
                    style={{ height: '100%' }}
                    navPrev={null}
                    initialStartDate={null}
                    disabled={false}
                    startDate={startDate}
                    isOutsideRange={() => false}
                    isDayBlocked={() => false}
                    enableOutsideDays={false}
                    numberOfMonths={2}
                    startDateId="s_id"
                    endDate={endDate}
                    endDateId="e_id"
                    onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                    focusedInput={focusedInput}
                    onFocusChange={e => setFocusedInput(e)}
                    displayFormat="DD MMM YYYY"
                />

            </section> */}

            {
                apiData.attendanceList.map((result) =>
                    <AttendanceCard
                        key={result.id}
                        name={result.name}
                        course={result.title ? result.title : "NA"}
                        country={result.country}
                        callDuration={result.duration}
                        date={moment(result.date).format('MMM DD, YYYY')}
                        time={moment(result.start_time, 'hh:mm').format('hh : mm A')}
                        img={result.profilePicture}
                        onClick={() => setState({ ...state, chatLogOpen: true, name: result.name, pic: result.profilePicture, chatRoomId: result.channel_name })}
                    />
                )
            }


        </div>


        {state.chatLogOpen &&
            <ChatPop
                roomId={state.chatRoomId}
                name={state.name}
                pic={state.pic}
                onClose={() => { setState({ ...state, chatLogOpen: false }) }}
            />
        }
        {load && <Backdrop />}
        {/* Not Record Found */}
        {!load && apiData.attendanceList.length === 0 && <p className='my-2 ms-700 text-center'>No Record Found</p>}

    </>);
}
