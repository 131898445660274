import React, { useEffect } from 'react'
import helper from '../helper'
import Disclaimer from '../legalpages/Disclaimer'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
export default function WebDisclaimer() {
  const reduxState = useSelector(state => state)
  const { t } = useTranslation()
  useEffect(() => {
    helper.scroll()
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>Disclaimers – SayDhad </title>
        <meta name="description" content='SayDhad is an online Arabic education platform that strives for accuracy and excellence. All the material and lesson plans are provided for educational purposes. We adhere to update, modify and discontinue our services at any time without prior notice. Visit our page & learn more about our website.' />
        <link rel="canonical" href=" https://saydhad.com/web/disclaimer/" />
      </Helmet>
      <div>
        <section className='row banner-section '>
          <div className="col-lg-11 col-12 mx-auto">
            <WebHeader />
            <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
              <div className="row ms-500">
                <div className="col-lg-10 col-12 mx-auto  py-2">
                  <h2 className='mb-3 tx-lt-grey f-fred'>{t('legalPages.disclaimer.heading')}</h2>
                </div>
              </div>
              <Disclaimer />
            </section>
            <WebFooter />
          </div>
        </section>

      </div>
    </React.Fragment>
  )
}
