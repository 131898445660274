/* eslint-disable */
import React, { useState } from 'react'
import certificate from '../static/images/courser-certificate.png'
import user from '../controller/user'
import helper from '../helper';
import OpenCertificate from '../component/OpenCertificate';
export default function CourseCertificate({ title, courseId, isDownload }) {
    const [state, setState] = useState({
        open: false
    })
    const getCert = async () => {
        await user.getCertificate({ studentId: helper.getid(), courseId: courseId }).then((res) => {
            if (res.status === 200) {
                
                var file =  URL.createObjectURL(res.data)
                var a = document.createElement("a");
                a.href = file;
                a.download = `${title}`;
                document.body.appendChild(a);
                a.click();
            }
           
        })
    }

    return (
        <div className="row gx-0 mx-0">
            <div className="col-lg-3 col-md-4 b-grey br-1 w-100 p-2">
                <p><img width={'100%'} src={certificate} className="pointer" onClick={() => setState({ ...state, open: true })} alt="certificate image" /></p>
                <hr />
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='f-fred tx-lt-grey my-2 text-capitalize'>{title}</p>
                    {isDownload && <i className="fas fa-cloud-download-alt hover-shadow fs-20 ml-3 pointer" onClick={() => getCert()}></i>}
                </div>
            </div>
            {state.open && <OpenCertificate certificate={certificate} close={() => setState({ ...state, open: false })} />}
        </div>
    )
}




