/* eslint-disable */
import React, { useState, useEffect } from 'react'
import tutor from '../controller/tutor'
import SyllabusCard from '../component/SyllabusCard'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import ReadMore from '../component/ReadMore'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function CourseDetails() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const { id } = useParams()
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState()

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await tutor.getCourseById(id, helper.getid())
            .then((res) => {
                if (res.data.result) {
                    setResData(res.data.result[0].course[0])
                }

                setPageload(false)
            })
    }

    const addLibrary = async () => {
        setPageload(true)
        await tutor.addCourseIntoLibrary({ tutorId: helper.getid(), courseId: id })
            .then((res) => {
                if (res.data.result) {
                    let data = resData
                    data.enrolled_courses = true
                    setResData(data)
                    helper.showMessage(res.data.result, "success")

                } else {
                    helper.showMessage(res.data.error, "success")
                }
                setPageload(false)
            })
    }

    const removeLibrary = async () => {
        setPageload(true)
        await tutor.removeCourseFromLibrary({ tutorId: helper.getid(), courseId: id })
            .then((res) => {
                setPageload(false)
                if (res.data.result) {
                    history.goBack()
                }
            })
    }

    return (
        <>

            {
                !pageload ?
                    <div className={`tutor-course-details tx-lt-grey ms-500 ${reduxState.language === 'ar' && "text-right"}`}>
                        <section className='d-flex align-items-center justify-content-between'>
                            <button className="btn back-btn py-1 fs-14 ls f-fred px-3 mr-3" onClick={() => history.goBack()}>{t('general.back')}</button>
                            {!resData.enrolled_courses && <button className="btn edit-info fs-14 px-3 br-2 wrap" style={{height:'40px', minWidth : "150px"}} onClick={addLibrary}>Add to library</button>}
                            {resData.enrolled_courses && <button className="btn px-1 py-1 fs-14 reshedule-btn px-2 ms-700 br-2 wrap" style={{height:'40px', minWidth : "150px"}} onClick={removeLibrary}>{t('tutorDash.library.removeFromLibrary')}</button>}
                        </section>
                        <section className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4  col-12 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.library.course')}</p>
                                        <p className="sub-heading">{t('tutorDash.library.status')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.library.totalEnrolled')}</p>
                                        <p className="sub-heading">{t('tutorDash.library.students')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totEnrolledStudent}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.library.total')}</p>
                                        <p className="sub-heading">{t('tutorDash.library.lessons')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totalLessons}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.library.total')}</p>
                                        <p className="sub-heading">{t('tutorDash.library.slides')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totalSlide}</p>
                                </div>
                            </div>
                        </section>
                        <p className="f-fred text-capitalize mt-2  fs-24">{resData.title}</p>
                        <p className="ms-500 fs-14 text-capitalize">{resData.subTitle}</p>
                        <div className="border-dot my-2 mb-3"></div>
                        <section className="row gx-0 mx-0 fs-14  ms-500 text-capitalize">
                            <div className="col-lg-2 col-md-4 col-6 mb-1">
                                <p className="ms-900  mb-1">{t('tutorDash.library.expLevel')} </p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.experienceLevel}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-1">
                                <p className="ms-900 mb-1">{t('tutorDash.library.courseLength')}</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.totalLessons} {t('tutorDash.library.lessons')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-1">
                                <p className="ms-900  mb-1">{t('tutorDash.library.slides')}</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.totalSlide} {t('tutorDash.library.slides')}</span>
                            </div>
                        </section>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>{t('tutorDash.library.overview')}</p>


                        <section className="row gx-0 mx-0">
                            <div className="col-lg-4 mb-2 px-1">
                                <p className="ms-900 fs-14">{t('tutorDash.library.whatLearn')}</p>
                                <ReadMore
                                    text={resData.description}
                                    initialNoOfWords={30}
                                />

                            </div>
                            <div className="col-lg-4 mb-2 px-1">
                                <p className="ms-900 fs-14">{t('tutorDash.library.whyCourse')}</p>
                                <ReadMore
                                    text={resData.descriptionCourse}
                                    initialNoOfWords={30}
                                />
                            </div>

                        </section>
                        <div className="border-dot mt-2 mb-3"></div>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>{t('tutorDash.library.Syllabus')}</p>

                        <section className="row gx-0 mx-0">
                            {
                                resData.syllabusList.map((syllabus, index) => {
                                    return (
                                        <div className="col-lg-6 col-md-6 col-12 pr-2 my-2">
                                            <SyllabusCard
                                                key={`syllabus${index}`}
                                                name={syllabus.name}
                                                slides={syllabus.noOfSlides}
                                                lessonNo={index + 1}
                                                file={syllabus.slideDetails}
                                                isEnrolled={resData.enrolled_courses}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </section>


                    </div>
                    : <Backdrop />}
        </>
    )
}
