/* eslint-disable */
import React, { useRef, useState } from 'react'
import moment from 'moment'
import avatar from '../static/images/avatar.png'
import helper from '../helper/index'
import ReactStars from "react-rating-stars-component";
import user from '../controller/user';
import toast from 'react-hot-toast';
import AlertPopUp from '../component/AlertPopUp';
import Backdrop from '../component/Backdrop';
import { useTranslation } from "react-i18next";
export default function CancelClass({ onClose, data, cb }) {
    const { t } = useTranslation();
    const reason = useRef()
    const [pageload, setPageload] = useState(false)
    const [state, setState] = useState({
        type: 'ok',
        open: false,
        msg: ''
    })

    const cancel = async () => {
        const req = {
            "student_id": helper.getid(),
            "channel_name": data.channel_name,
            "reason": reason.current.value
        }
        if (!req.reason) {
            toast("Please give reason for class cancellation")
            return
        }
        setPageload(true)
        await user.cancelClass(req).then((res) => {
            if (res.data.status) {
                setState({ ...state, open: true, msg: 'your class has been cancelled successfully.', type: 'ok' })
            } else {
                setState({ ...state, open: true, msg: res.data.error, type: 'fail' })
            }
            setPageload(false)
        }).catch((err) => {
            setPageload(false)
            setState({ ...state, open: true, msg: err, type: 'fail' })
        })
    }
    return (
        <div>
            <div className={` tutor-big-card pointer b-grey bg-white mb-2 px-2`}>
                <section className='d-flex'>
                    <div className="all-center my-2">
                        <div className='p-rel mr-4'>
                            <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="avatar" />
                            <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-between'>
                        <div className="my-2 pl-2">
                            <p className="text-capitalize fs-16 f-fred">{data.name}</p>
                                  <div className="d-flex align-items-center">
                                <div className="d-inline-block py-0 my-0 mr-1">
                                    <ReactStars
                                        edit={false}
                                        value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                        count={5}
                                        size={15}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        classNames="my-0 py-0"
                                        activeColor="#ffcb00"
                                    />
                                </div>
                                <span className="tx-black ms-700 fs-14">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>
                            </div>
                        </div>

                        <div className="d-flex align-items-end justify-content-end p-rel">
                            {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 fs-18 ms-500">New</p>}
                            <span className="p-2">

                                <img width="25px" height="25px" className="rounded-circle" src={data.flag_uri}
                                    alt="flag" />

                            </span>
                        </div>
                    </div>
                </section>
                <section className='mt-2 fs-14 py-2'>
                    <p className='ms-700'>{t('tutorDash.calendar.scheduleOn')}</p>
                    <p className='ms-900'>{moment(data.date).format('MMMM DD, YYYY')} at {moment(data.start_time, 'hh:mm').format('hh : mm A')}</p>
                </section>
            </div>

            <section className='tutor-dash h-100'>
                <label className='ms-500 my-2 fs-14'>Reason</label>
                <textarea ref={reason} style={{ minHeight: '150px' }} className='input-box w-100 p-2 ms-700 tx-lt-black' cols="30" rows="5" placeholder='Why want to cancel your class?'></textarea>
            </section>

            <section className='all-center'>
                <button className='btn btn-tutor-profile px-3 py-1 my-2 fs-14' style={{ height: '39px', width: '120px' }} onClick={onClose}>CANCEL</button>
                <button className='btn btn-login px-3 py-1 my-2 mx-3 ' style={{ height: '39px', width: '120px' }} onClick={cancel}>SUBMIT</button>

            </section>
            <div className=" my-3 alert">
                <p className='ms-500 fs-14 m-2 text-center'> <i className="fas fa-exclamation-triangle mx-2 tx-red"></i> If you cancel class after 12 hours of booking, You will not get you minutes back.</p>
            </div>

            <AlertPopUp
                type={state.type}
                msg={state.msg}
                open={state.open}
                onClose={() => { setState({ ...state, open: false }); cb(); }}
            />
            {pageload && <Backdrop />}
        </div>
    )
}
